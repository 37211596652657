import React, { useState, useEffect } from 'react'
import Joi, { process } from 'joi-browser'
import Form from '../../common/form'
import auth from '../../services/authServices'
import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom'
import googleImage from '../../assets/images/google-imgss.png'
import linkedin from '../../assets/images/linked-icon.png'
import { useSelector } from 'react-redux'
import logo from '../../assets/images/singuplogo.png'
import { ImLinkedin } from 'react-icons/im'
import { FcGoogle } from 'react-icons/fc'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setUser } from '../../actions/userActions.jsx'
import { GoogleLogin } from '@react-oauth/google'

function SigninForm () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialData = { email: '', password: '' }
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const userEndpoint = '/auth/user_auth_signin'
  const location = useLocation()
  const user = useSelector(state => state.userReducer)
  const [clicked, setClicked] = useState(false)
  const handleClick = () => {
    setClicked(true)
  }

  const doSubmit = async () => {
    try {
      await auth.login(userEndpoint, data, 'email', 'password')
      let redirectUrl = process.env.REACT_APP_HOMEPAGE
      let path = location?.state
        ? `${redirectUrl}${location?.state?.history}`
        : `${redirectUrl}/user_dashboard`

      window.location.href = path
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const newErrors = { ...errors }
        newErrors.username = ex.response.data
        setErrors(newErrors)
      }
    }
  }

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    password: Joi.string().required().min(5).label('Password')
  }

  const { renderInput, renderButton, handleSubmit } = Form({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit
  })

  useEffect(() => {
    const buttonElement = document.querySelector('.sinfrnw .btn')
    if (buttonElement) {
      buttonElement.classList.add('btn-primary')
      buttonElement.classList.add('btre')
    }
  }, [])

  // const loginWithGoogle = () => {
  //   window.open(`/auth/google`, "_self");

  // };

  const handleGoogleLogin = async credentialResponse => {
    try {
      // Decode the JWT token from the credentialResponse
      const decodedToken = jwtDecode(credentialResponse.credential)
      console.log('Decoded Google Token:', decodedToken)

      // Call the API to create or fetch the user
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/userroles/createGoogleUser`,
        {
          name: decodedToken.name,
          email: decodedToken.email,
          picture: decodedToken.picture
        }
      )

      // Check if the API call was successful
      if (response.status === 200) {
        // Dispatch the user data to Redux
        dispatch(setUser(response.data.user))
        sessionStorage.setItem('user', JSON.stringify(response.data.user))
        // Redirect user to dashboard or desired page
        navigate('/user_dashboard')
      } else {
        throw new Error('Failed to create or fetch user')
      }
    } catch (error) {
      console.error('Error during Google login process:', error)
      toast.error('An error occurred during Google login.')
    }
  }
  const handleGoogleError = () => {
    console.log('Google Login Failed')
  }

  const loginWithGoogle = () => {
    // Open Google authentication callback URL
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/google/callback`,
      '_self'
    )

    // Set a flag in local session store
    sessionStorage.setItem('googleAuthInProgress', 'true')
  }

  const loginWithLinkedIn = () => {
    window.open(`/auth/linkedin`, '_self')
  }

  if (user && user.department === 'User')
    return <Navigate to={`/user_dashboard`} replace />
  if (user && user.department === 'SuperAdmin')
    return <Navigate to={`/user_dashboard`} replace />

  return (
    <>
      <form
        className='sinfrnw'
        onSubmit={handleSubmit}
        style={{
          borderRadius: '16px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          padding: '20px'
        }}
      >
        <div>
          <Link
            to='/'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={logo} style={{ width: '30%' }} />
          </Link>
        </div>
        {renderInput('email', 'Email', 'text', 'example@example.com')}

        {renderInput('password', 'Password', 'password', 'Enter your password')}
        <Link className='sign-frw' to='/forgotpassword'>
          Forgot your password?
        </Link>

        {renderButton('login')}
        <div style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
          <span style={{ margin: '0 10px', whiteSpace: 'nowrap' }}>or</span>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
        </div>
        <div style={{ width: '100%' }}>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={handleGoogleError}
          />
        </div>
        {/* <button
          class='ggl-sing'
          style={{ margin: '10px 0' }}
          onClick={() => {
            loginWithGoogle()
          }}
        >
          <span style={{ paddingRight: '10px' }}>
            {' '}
            <FcGoogle size={25} />
          </span>
          Sign in with Google
        </button> */}
        <button class='ggl-sing'>
          <span style={{ color: '#0A66C2', paddingRight: '10px' }}>
            {' '}
            <ImLinkedin size={20} />
          </span>
          Singup with LinkedIn
        </button>
        <h6 style={{ margin: '15px 0' }}>
          Don't have a LeadXchange account ?{' '}
          <Link to='/signup' style={{ fontWeight: '600' }}>
            Signup
          </Link>
        </h6>

        <span class='prvtrm'>
          <Link to='/termscondition'>Terms & Condition</Link>
          &nbsp;&amp;&nbsp;
          <Link to='/privacy'>Privacy Policy</Link>
        </span>
      </form>
    </>
  )
}

export default SigninForm
