// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=US-ASCII,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 10 8\" width=\"10\" height=\"8\"><polygon points=\"0,0 5,8 10,0\" stroke=\"%23999\" fill=\"none\" stroke-width=\"1\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select {
  appearance: none;
  background-color: white;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px; /* Slightly smaller arrow size */
  padding-right: 30px; /* Adjust padding for the arrow */
}
`, "",{"version":3,"sources":["webpack://./src/forms/CampaignSetupForm/CampaignSetupForm.css"],"names":[],"mappings":"AAAA;EAGE,gBAAgB;EAChB,uBAAuB;EACvB,yDAAiO;EACjO,4BAA4B;EAC5B,sCAAsC;EACtC,qBAAqB,EAAE,gCAAgC;EACvD,mBAAmB,EAAE,iCAAiC;AACxD","sourcesContent":[".custom-select {\r\n  -webkit-appearance: none;\r\n  -moz-appearance: none;\r\n  appearance: none;\r\n  background-color: white;\r\n  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 10 8\" width=\"10\" height=\"8\"><polygon points=\"0,0 5,8 10,0\" stroke=\"%23999\" fill=\"none\" stroke-width=\"1\"/></svg>');\r\n  background-repeat: no-repeat;\r\n  background-position: right 10px center;\r\n  background-size: 10px; /* Slightly smaller arrow size */\r\n  padding-right: 30px; /* Adjust padding for the arrow */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
