import React, { useState, useEffect, useLayoutEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { Routes, Route, useLocation } from 'react-router-dom'

// Style CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'font-awesome/css/font-awesome.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../assets/css/style.css'
import '../assets/css/main.css'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'

// Authentication
import auth from '../services/authServices'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../actions/'

// Public Pages

import {
  Home,
  Signin,
  Signup,
  Error,
  News,
  Admin,
  ProfileSummary,
  ProfileSummaryUpdate,
  ChangePassword,
  AboutUs,
  TermsCondition,
  Privacy,
  Blog,
  ForgotPassword,
  ResetPassword,
  ContactUs,
  LeadRejectionPolicy,
  WhoAreWe
} from './../pages/'

// Admin Pages
import {
  Panel as AdminPanel,
  AxtiveLeed,
  Users,
  Subscription,
  Cms,
  Members,
  Testimonial,
  Faq,
  Objective,
  Awards,
  Blogs,
  Contact,
  Roles,
  Intent,
  Campaigns,
  UserDetails,
  CampaignsDetails
} from '../dashboard/AdminDashboard/pages'

import {
  MActiveLeeds,
  Panel as UserPanel,
  MIntent,
  MyCampaigns
} from '../dashboard/UserDashboard/pages'

// Dashboard Pages
import { AdminDashboard, UserDashboard } from '../dashboard'

// Public Component
import { Footer, Navbar } from './../components/'
import Logout from '../common/logout'
import AdminPrivateRoute from './PrivateRoute/AdminPrivateRoute'
import {
  ObjectiveForm,
  AwardsForm,
  BlogsForm,
  CmsForm,
  ContactForm,
  FaqForms,
  MembersForm,
  SubscriptionForm,
  TestimonialForm,
  RolesForm,
  MIntentForm,
  MIntentActiveForm,
  CampaignsForm,
  MyCampigndata,
  UpdateCampign,
  UpdateListing,
  CampaignSetupForm,
  MCQsForm,
  UpdateCampaignSetupForm,
  UserRolesForm,
  UpdateUserRoleForm,
  PaymentForm
} from '../forms'

import BlogDetails from '../pages/BlogDetails/BlogDetails'
import Leadpolicy from '../pages/Leadpolicy/Leadpolicy'
import UserPrivateRoutes from './PrivateRoute/UserPrivateRoutes'
import AbmServices from './../pages/AbmServices/AbmServices'
import ContentMarketing from './../pages/ContentMarketing/ContentMarketing'
import DatabaseAnalytics from './../pages/DatabaseAnalytics/DatabaseAnalytics'
import VerifyEmail from '../pages/VerifyEmail/VerifyEmail'
import UserCampign from '../dashboard/AdminDashboard/pages/UserCampign/UserCampign'
import UserCampignDetails from '../dashboard/AdminDashboard/pages/UserCampignDetails/UserCampignDetails'
import DemandProgram from '../pages/DemandProgram/DemandProgram'
import SalesEnablement from '../pages/SalesEnablement/SalesEnablement'
import ListingForm from '../forms/ListingForm/ListingForm'
import Listing from '../dashboard/AdminDashboard/pages/Listing/Listing'
import Mylisting from '../dashboard/UserDashboard/pages/Mylisting/Mylisting'
import Csv from '../common/Csv'
import AdminCsv from '../common/AdminCsv'
import UserRoles from '../dashboard/AdminDashboard/pages/UserRoles/UserRoles'
import UserCampign2 from '../dashboard/AdminDashboard/pages/UserCampign2/UserCampign2'
import SuccessfulPayment from '../pages/SuccessfulPayment/SuccessfulPayment'
import CSVUpload from '../pages/CSVUpload/CSVUpload'
import Billing from '../pages/BillingModule/Billing'

function RootNavigation () {
  const dispatch = useDispatch()
  const user = useSelector(state => state.userReducer)
  const [showNav, setShowNav] = useState(true)
  const [showFooter, setShowFooter] = useState(true)
  const [isLoading, setIsLoading] = useState(true) // Add a loading state
  const location = useLocation()

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = await auth.getCurrentUser() // Assuming auth.getCurrentUser() is an asynchronous function that returns the current user
      dispatch(setUser(currentUser))
      console.log(currentUser, '130')
      setIsLoading(false) // Set loading to false once user data is fetched
    }
    fetchData()
  }, [dispatch])

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  if (isLoading) return null

  return (
    <>
      <ToastContainer />
      {showNav && <Navbar />}
      <Routes>
        {/* Authenication Pages */}
        <Route
          path='/admin_login'
          element={<Admin funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route
          path='/signin'
          element={<Signin funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route
          path='/signup'
          element={<Signup funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route path='/logout' element={<Logout />} />

        {/* Public Routes Started */}
        <Route
          path='/'
          element={<Home funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route
          path='/news'
          element={<News funcNav={setShowNav} funcFooter={setShowFooter} />}
        />

        <Route
          path='/profilesummary'
          element={
            <ProfileSummary funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/profilesummaryUpdate'
          element={
            <ProfileSummaryUpdate
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path='/changePassword'
          element={
            <ChangePassword funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/aboutus'
          element={<AboutUs funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route
          path='/paymentSuccessful'
          element={
            <SuccessfulPayment
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path='/termscondition'
          element={
            <TermsCondition funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/privacy'
          element={<Privacy funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route
          path='/blog'
          element={<Blog funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route
          path='/blogdetails'
          element={
            <BlogDetails funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/leadpolicy'
          element={
            <Leadpolicy funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/contactus'
          element={
            <ContactUs funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/verify-email'
          element={
            <VerifyEmail funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />

        <Route
          path='/forgotpassword'
          element={
            <ForgotPassword funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/abm-services'
          element={
            <AbmServices funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/content-marketing'
          element={
            <ContentMarketing funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/database-and-analytics'
          element={
            <DatabaseAnalytics
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path='/demand-program'
          element={
            <DemandProgram funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/salesEnablement'
          element={
            <SalesEnablement funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />

        <Route
          path='/reset-password'
          element={
            <ResetPassword
              sword
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path='/leadrejection'
          element={
            <LeadRejectionPolicy
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path='/who-are-we'
          element={<WhoAreWe funcNav={setShowNav} funcFooter={setShowFooter} />}
        />
        <Route
          path='/campaignsetupform'
          element={
            <CampaignSetupForm
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path='/paymentForm'
          element={
            <PaymentForm funcNav={setShowNav} funcFooter={setShowFooter} />
          }
        />
        <Route
          path='/updatecampaignsetupform'
          element={
            <UpdateCampaignSetupForm
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        />
        <Route
          path='/mcqform'
          element={<MCQsForm funcNav={setShowNav} funcFooter={setShowFooter} />}
        />

        {/* Public Routes Ended */}

        {/* Admin Routes Started*/}
        <Route
          path='/admin_panel'
          element={
            <AdminPrivateRoute
              user={user}
              department={user?.department}
              Component={AdminDashboard} // Pass the reference to the component, not the JSX element
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        >
          {' '}
          <Route index element={<AdminPanel />} />
          <Route path='users' element={<Users />} />
          <Route path='userdetails/:_id' element={<UserDetails />} />
          <Route path='subscription' element={<Subscription />} />
          <Route path='subscription/:_id' element={<SubscriptionForm />} />
          <Route path='cms' element={<Cms />} />
          <Route path='Campaigns' element={<Campaigns />} />
          <Route path='campaignsDetails' element={<CampaignsDetails />} />
          <Route path='UserRoles' element={<UserRoles />} />
          <Route path='userRoles/new' element={<UserRolesForm />} />
          <Route path='userRoles/:_id' element={<UpdateUserRoleForm />} />
          <Route path='userCampaign/:_id' element={<MyCampigndata />} />
          <Route path='Campaigns/:_id' element={<UserCampign />} />
          <Route
            path='campaignsDetails/:_id'
            element={<UserCampignDetails />}
          />
          <Route path='userCampaign2' element={<UserCampign2 />} />
          <Route path='Campaigns/:_id/new' element={<CampaignsForm />} />
          <Route path='Campaigns/:_id/:_id' element={<UpdateCampign />} />
          <Route path='listing/:_id' element={<Listing />} />
          <Route path='listing/:_id/new' element={<ListingForm />} />
          <Route path='listing/:_id/:_id' element={<UpdateListing />} />
          <Route path='cms/:_id' element={<CmsForm />} />
          <Route path='members' element={<Members />} />
          <Route path='members/:_id' element={<MembersForm />} />
          <Route path='testimonial' element={<Testimonial />} />
          <Route path='testimonial/:_id' element={<TestimonialForm />} />
          <Route path='awards' element={<Awards />} />
          <Route path='awards/:_id' element={<AwardsForm />} />
          <Route path='objectives' element={<Objective />} />
          <Route path='objectives/:_id' element={<ObjectiveForm />} />
          <Route path='intent' element={<Intent />} />
          <Route path='intent/:_id' element={<MIntentForm />} />
          <Route path='intent/active/:_id' element={<AxtiveLeed />} />
          <Route
            path='intent/active/:_id/:_id'
            element={<MIntentActiveForm />}
          />
          <Route path='roles' element={<Roles />} />
          <Route path='roles/:_id' element={<RolesForm />} />
          <Route path='faq' element={<Faq />} />
          <Route path='faq/:_id' element={<FaqForms />} />
          <Route path='blogs' element={<Blogs />} />
          <Route path='blogs/:_id' element={<BlogsForm />} />
          <Route path='contact' element={<Contact />} />
          <Route path='contact/:_id' element={<ContactForm />} />
          <Route path='adminCsv/:_id' element={<AdminCsv />} />
          <Route path='csvUpload' element={<CSVUpload />} />
          <Route path='*' element={<Error />} />
        </Route>

        {/* User Routes Started */}
        <Route
          path='/user_dashboard'
          element={
            <UserPrivateRoutes
              user={user}
              department={user?.department}
              Component={UserDashboard} // Pass the reference to the component, not the JSX element
              funcNav={setShowNav}
              funcFooter={setShowFooter}
            />
          }
        >
          <Route index element={<UserPanel />} />
          <Route path='activeLeads' element={<MActiveLeeds />} />
          <Route path='billing' element={<Billing />} />
          <Route path='myCampaigns' element={<MyCampaigns />} />
          <Route path='myCampaigns/:_id' element={<MyCampigndata />} />
          <Route path='csv/:_id' element={<Csv />} />
          <Route path='mylisting/:_id' element={<Mylisting />} />
          <Route path='intent' element={<MIntent />} />
          <Route path='intent/:_id' element={<MIntentForm />} />
          <Route path='intent/active/:_id' element={<MActiveLeeds />} />
          <Route path='*' element={<Error />} />
        </Route>

        {/* User Routes Ended */}

        {/* Error Pages */}
        <Route path='*' element={<Error />} />
      </Routes>

      {showFooter && <Footer />}
    </>
  )
}

export default RootNavigation
