import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { MdErrorOutline } from 'react-icons/md'
import upload from '../../assets/images/upload-icon.jpg'
import { loadStripe } from '@stripe/stripe-js'
import LeadForm from '../../components/Forms/LeadForm'

// import { Elements } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   'pk_test_51PcjSaKqIA5zCeSxVZnBhXEMk8XoOdXH97Y55JAHFrq2aJZLAxhlZ0gASJAZnMAbIpx7QPpWC6SwzDkPE09xd0kg00FTOdn9h8'
// )

// const options = {
//   // passing the client secret obtained from the server
//   clientSecret:
//     '{{sk_test_51PcjSaKqIA5zCeSxe4dQ1uUq6DNdjtA3pCLq6aqQoJ9EURfqrtyE7JGgga5RTiPvTax1xP3jUmln7peJQ1EI1fdq00svcITThB}}'
// }

const CampaignSetupForm = ({ funcNav, funcFooter }) => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [enumTactics, setEnumTactics] = useState([])

  const enumValues = {
    reportFrequency: [
      'Daily',
      'Twice a week',
      'Weekly',
      'Fortnightly',
      'Monthly',
      'Others'
    ],
    industries: [
      'Cross Industry',
      'BFSI',
      'Manufacturing',
      'Retail',
      'Entertainment',
      'Health & Pharma',
      'ITES',
      'Education',
      'Media and news',
      'Construction',
      'Hospitality',
      'Other'
    ],
    funnel: ['Tofu', 'Mofu', 'Bofu', 'All'],
    objectives: [
      'Brand Awareness',
      'Generate Sales',
      'Lead Capture and Nurturing',
      'Increase Website Traffic'
    ],
    tactics: {
      default: [
        'Content Syndication',
        'Email marketing',
        'Newsletter',
        'Webinar',
        '1-on-1',
        'Digital Chat series',
        'Digital Board room',
        'Inner Board room',
        'Social Media'
      ],
      auto: ['Auto']
    }
  }
  const [campaignData, setCampaignData] = useState({
    funnel: [],
    objective: '',
    abm: true,
    jobTitle: {
      title: '',
      otherTitle: ''
    },
    tal: true,
    location: '',
    industries: [], // Change industries to an array for multiple selections
    organizationSize: '',
    tactics: {
      type: 'Auto',
      suggest: true
    },
    assetsFile: null,
    talListFile: null,
    additionalFilterEnabled: false,
    filters: [],
    filterimage: null,
    purchaseOrderFile: null,
    additionalFilters: '',
    campaignTimeline: '',
    reportFrequency: '',
    leadCategory: '',
    quantity: 1,
    region: '',
    tal2: '',
    talCheck: false,
    totalAmount: 0, // Ensure this is initialized
    subtotal: 1000
  })

  useEffect(() => {
    // Check if user data exists in sessionStorage
    const userDataString = sessionStorage.getItem('user')

    if (!userDataString) {
      // Navigate user to sign-in page if no user data is found
      navigate('/signin')
      return // Exit early if no user data is found
    }
  }, [navigate])

  const handleChange = e => {
    const { name, value, type, checked, files } = e.target

    if (type === 'checkbox') {
      if (name === 'funnel' || name === 'objective' || name === 'industries') {
        const updatedValues = checked
          ? [...campaignData[name], value]
          : campaignData[name].filter(item => item !== value)
        setCampaignData({ ...campaignData, [name]: updatedValues })
      } else {
        setCampaignData({ ...campaignData, [name]: checked })
      }
    } else if (name.startsWith('jobTitle')) {
      // Handle jobTitle changes
      const updatedJobTitle = {
        ...campaignData.jobTitle,
        [name.split('.')[1]]: value
      }
      setCampaignData({ ...campaignData, jobTitle: updatedJobTitle })
    } else if (name.startsWith('tactics')) {
      // Handle tactics changes
      const updatedTactics = {
        ...campaignData.tactics,
        [name.split('.')[1]]: value
      }
      setCampaignData({ ...campaignData, tactics: updatedTactics })
      // Update tactics enum based on selection of "Auto"
      if (value === 'Auto') {
        setEnumTactics(enumValues.tactics.auto)
      } else {
        setEnumTactics(enumValues.tactics.default)
      }
    } else if (type === 'file') {
      // Handle file input changes
      console.log('Files:', files)
      setCampaignData({ ...campaignData, [name]: files[0] })
    } else {
      setCampaignData({ ...campaignData, [name]: value })
    }
  }

  const handleIndustriesChange = e => {
    const { value, checked } = e.target
    const updatedIndustries = [...campaignData.industries]

    if (checked && !updatedIndustries.includes(value)) {
      updatedIndustries.push(value) // Add the selected industry
    } else if (!checked && updatedIndustries.includes(value)) {
      const index = updatedIndustries.indexOf(value)
      updatedIndustries.splice(index, 1) // Remove the deselected industry
    }

    setCampaignData({ ...campaignData, industries: updatedIndustries })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const requiredFields = [
        'funnel',
        'objective',
        'location',
        'organizationSize'
      ]

      // Validate required fields
      const isValid = requiredFields.every(field => {
        const value = campaignData[field]
        if (field === 'funnel' || field === 'industries') {
          return Array.isArray(value) && value.length > 0
        } else {
          return typeof value === 'string' && value.trim() !== ''
        }
      })

      if (!isValid) {
        throw new Error('Please fill out all required fields.')
      }

      // Validate reportFrequency
      if (!enumValues.reportFrequency.includes(campaignData.reportFrequency)) {
        throw new Error('Invalid report frequency selected.')
      }

      // Validate industries
      if (
        !campaignData.industries.every(industry =>
          enumValues.industries.includes(industry)
        )
      ) {
        throw new Error('Invalid industry selected.')
      }

      const userDataString = sessionStorage.getItem('user')
      if (!userDataString) {
        throw new Error('User data not found in Local Storage')
      }
      const userData = JSON.parse(userDataString)
      const userId = userData._id // Get the user ID

      const formData = new FormData()
      formData.append('user', userId) // User ID

      Object.entries(campaignData).forEach(([key, value]) => {
        if (key === 'jobTitle') {
          formData.append('jobTitle[title]', value.title)
          formData.append('jobTitle[otherTitle]', value.otherTitle)
        } else if (key === 'tactics') {
          formData.append('tactics[type]', value.type)
          formData.append('tactics[suggest]', value.suggest.toString())
        } else if (value !== null && value !== 'null' && value !== '') {
          // Append non-empty values
          // For industries, ensure it's appended as an array
          if (key === 'industries' || key === 'funnel') {
            value.forEach(item => formData.append(key, item))
          } else {
            formData.append(key, value)
          }
        }
      })

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/campaignSetup/createCampaignSetup`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      setErrorMessage('')
      window.location.href = '/profilesummary'

      // Redirect or show success message here
    } catch (error) {
      console.error('Failed to create campaign:', error.message)
      setErrorMessage(error.message)
      // Handle error, show error message to user, etc.
    }
  }

  const renderFileInput = (name, label) => (
    <div className='form-group'>
      {campaignData[name] && (
        <img
          src={URL.createObjectURL(campaignData[name])}
          alt='Preview'
          className='mt-2 mb-2'
          style={{
            width: '400px', // Set the desired width
            height: '200px', // Set the desired height
            objectFit: 'fit',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
          onClick={e => {
            e.preventDefault() // Prevent default action when clicking on the image
            // Add any specific handling code here if needed
          }}
        />
      )}
    </div>
  )

  // Function to handle changes in additional filter enable/disable
  const handleAdditionalFilterChange = e => {
    const { name, value } = e.target
    setCampaignData({ ...campaignData, [name]: value === 'true' })
  }
  // Function to handle checkbox
  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    setCampaignData(prevState => ({
      ...prevState,
      [name]: checked
      // totalAmount: calculateTotalAmount(prevState, checked) // Update total amount based on checkbox
    }))
  }

  // const calculateTotalAmount = (data, isExtra) => {
  //   const subtotal = campaignData.subtotal // Replace with your logic or dynamic value
  //   const tax = 5 // Replace with your logic or dynamic value
  //   let total = subtotal + tax

  //   if (isExtra) {
  //     total *= 1.25 // Add 25% if checkbox is checked
  //   }
  //   total = total * campaignData.quantity

  //   return total.toFixed(2) // Ensure the value is formatted correctly
  // }

  // Function to handle changes in filters array
  const handleFiltersChange = (index, value) => {
    const updatedFilters = [...campaignData.filters]
    updatedFilters[index] = value
    setCampaignData({ ...campaignData, filters: updatedFilters })
  }

  // Function to add a new filter input
  const addFilterInput = () => {
    setCampaignData({
      ...campaignData,
      filters: [...campaignData.filters, '']
    })
  }

  // Function to remove a filter input
  const removeFilterInput = index => {
    const updatedFilters = [...campaignData.filters]
    updatedFilters.splice(index, 1)
    setCampaignData({ ...campaignData, filters: updatedFilters })
  }

  const renderError = field => {
    if (errorMessage && errorMessage.includes(field)) {
      return (
        <div className='alert alert-danger mt-2' role='alert'>
          {errorMessage}
        </div>
      )
    }
  }

  const handleSubmit1 = async e => {
    try {
      e.preventDefault()

      const stripe = await loadStripe(
        'pk_test_51PcjSaKqIA5zCeSxVZnBhXEMk8XoOdXH97Y55JAHFrq2aJZLAxhlZ0gASJAZnMAbIpx7QPpWC6SwzDkPE09xd0kg00FTOdn9h8'
      )
      const response = await axios.post('/payment', {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer sk_test_51PcjSaKqIA5zCeSxe4dQ1uUq6DNdjtA3pCLq6aqQoJ9EURfqrtyE7JGgga5RTiPvTax1xP3jUmln7peJQ1EI1fdq00svcITThB`
        }
      })

      const session = await response.data
      console.log(session)

      const result = stripe.redirectToCheckout({ sessionId: session.id })
      if (result.error) {
        console.log(result.error)
      }
      alert('Submit')
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <>
      {/* <Elements stripe={stripePromise} options={options}> */}
      <div className='container mt-5' style={{ maxWidth: '80%' }}>
        <h4 style={{ marginBottom: '8px' }}>Campaign Setup Form</h4>
        <div
          style={{
            marginBottom: '18px',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <div style={{ color: 'red' }}>
            <MdErrorOutline size={20} />
          </div>
          Please fill out all required fields.
        </div>
        {/* Display error message if there is one */}
        {errorMessage && (
          <div className='alert alert-danger' role='alert'>
            {errorMessage}
          </div>
        )}
        <LeadForm handleSubmit={handleSubmit1} />
        {/* <form encType='multipart/form-data' onSubmit={handleSubmit1}> */}
        {/* GET FREE LEADS Section */}
        {/* <div className='row mb-4'> */}
        {/* <div className='col-md-12'>
              <h4>GET FREE LEADS</h4>
            </div> */}
        {/* New Dropdown 1 */}
        {/* <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Lead Category:</label>
              <select
                className='form-control'
                name='leadCategory'
                value={campaignData.leadCategory}
                onChange={handleChange}
              >
                <option value=''>Select Lead Category</option>
                <option value='Form Fills'>Form Fills</option>
                <option value='BANT'>BANT</option>
                <option value='Appointment Setting'>Appointment Setting</option>
                <option value='Triangular call'>Triangular call</option>
              </select>
            </div> */}
        {/* New Dropdown 2 */}
        {/* <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Quantity:</label>
              <select
                className='form-control'
                name='quantity'
                value={campaignData.quantity}
                onChange={handleChange}
              >
                <option value=''>Select Quantity</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>

              </select>
            </div> */}
        {/* New Dropdown 3 */}
        {/* <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Region:</label>
              <select
                className='form-control'
                name='region'
                value={campaignData.region}
                onChange={handleChange}
              >
                <option value=''>Select Region</option>
                <option value='Singapore'>Singapore</option>
                <option value='India'>India</option>
                <option value='Malaysia/ Indonesia / Philippines/ Vietnam / Thailand'>
                  Malaysia/ Indonesia / Philippines/ Vietnam / Thailand
                </option>
                <option value='USA'>USA</option>
                <option value='EMEA'>EMEA</option>
                <option value='ANZ'>ANZ</option>
                <option value='Japan'>Japan</option>
                <option value='South'>South Korea</option>
              </select>
            </div> */}
        {/* New Dropdown 4 */}
        {/* <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>TAL</label>
              <select
                className='form-control'
                name='tal2'
                value={campaignData.tal2}
                onChange={handleChange}
              >
                <option value='yes'>Yes</option>
                <option value='no'>No</option>

              </select>
            </div> */}
        {/* New Checkbox */}
        {/* <div className='col-md-6 mb-3'>
              <input
                type='checkbox'
                name='talCheck'
                checked={campaignData.talCheck}
                onChange={handleCheckboxChange}
              />
              <label style={{ paddingLeft: '5px' }}>
                In case of Yes the system should calculate 25% extra
              </label>
            </div> */}
        {/* <div className='col-md-6 p-3 d-flex justify-content-end'>
              <div
                className='p-3'
                style={{
                  backgroundColor: '#F8F8F8',
                  width: '40%',
                  borderRadius: 15
                }}
              >
                <div
                  className='d-flex justify-content-between'
                  style={{ marginBottom: '15px' }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: '#545454'
                    }}
                  >
                    Subtotal:
                  </span>
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: 'grey'
                    }}
                  >
                    {campaignData.region === 'India' ? '₹ ' : '$ '}{' '}
                    {campaignData.quantity} x {campaignData.subtotal}
                  </span>
                </div>
                <div
                  className='d-flex justify-content-between'
                  style={{ marginBottom: '15px' }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: '#545454'
                    }}
                  >
                    Tax:
                  </span>
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: 'grey'
                    }}
                  >
                    {campaignData.region === 'India' ? '₹ ' : '$ '}
                    {campaignData.tax}9
                  </span>
                </div>
                <div className='d-flex justify-content-between'>
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: '#545454'
                    }}
                  >
                    Total Amount:
                  </span>
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      color: '#0A58CA'
                    }}
                  >
                    {campaignData.region === 'India' ? '₹ ' : '$ '}
                    {campaignData.totalAmount}
                  </span>
                </div>
              </div>
            </div> */}
        {/* </div> */}

        {/* <div className='d-flex justify-content-between'>
            <div style={{ flex: '1' }}></div>
            <button
              type='submit'
              style={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '10px',
                color: '#fff',
                fontWeight: '600',
                border: 'none'
              }}
            >
              Pay now
            </button>
          </div> */}
        {/* </form> */}
      </div>
      {/* </Elements> */}
      <div className='container mt-5  ' style={{ maxWidth: '80%' }}>
        <h4 style={{ marginBottom: '8px' }}>Campaign Setup Form</h4>
        <div
          style={{
            marginBottom: '18px',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <div style={{ color: 'red' }}>
            <MdErrorOutline size={20}></MdErrorOutline>
          </div>{' '}
          Please fill out all required fields.
        </div>
        {/* Display error message if there is one */}
        {errorMessage && (
          <div className='alert alert-danger' role='alert'>
            {errorMessage}
          </div>
        )}
        <form encType='multipart/form-data' onSubmit={handleSubmit}>
          {/* Existing Rows */}
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '2px', display: 'block' }}>
                Funnel: <span style={{ color: 'red' }}>*</span>
              </label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {enumValues.funnel.map(funnel => (
                  <div
                    key={funnel}
                    className='form-check'
                    style={{
                      display: 'inline-flex', // Use inline-flex for aligning items in a row
                      alignItems: 'center', // Align items vertically in center
                      marginRight: '10px',
                      marginBottom: '10px',
                      padding: '10px 30px',
                      borderRadius: '10px',
                      border: `2px solid ${
                        campaignData.funnel.includes(funnel)
                          ? '#55B56D'
                          : '#E4E4E4'
                      }`
                    }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='funnel'
                      value={funnel}
                      checked={campaignData.funnel.includes(funnel)}
                      onChange={handleChange}
                      style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        backgroundColor: campaignData.funnel.includes(funnel)
                          ? '#55B56D'
                          : '',
                        borderColor: campaignData.funnel.includes(funnel)
                          ? '#55B56D'
                          : '',
                        cursor: 'pointer'
                      }}
                    />
                    <label className='form-check-label' style={{ margin: '0' }}>
                      {funnel}
                    </label>
                  </div>
                ))}
              </div>
              {renderError('funnel')}
            </div>

            {/* Objective */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>
                Objective:<span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className='form-control'
                name='objective'
                value={campaignData.objective}
                onChange={handleChange}
              >
                <option value=''>Select Objective</option>
                {enumValues.objectives.map(objective => (
                  <option key={objective} value={objective}>
                    {objective}
                  </option>
                ))}
              </select>
              {renderError('objective')}
            </div>
          </div>

          <div className='row'>
            {/* Report Frequency */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Report Frequency:</label>
              <select
                className='form-control'
                name='reportFrequency'
                value={campaignData.reportFrequency}
                onChange={handleChange}
              >
                <option value=''>Select Report Frequency</option>
                {enumValues.reportFrequency.map(frequency => (
                  <option key={frequency} value={frequency}>
                    {frequency}
                  </option>
                ))}
              </select>
            </div>
            {/* TAL */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>TAL (Wish List):</label>
              <select
                className='form-control'
                name='tal'
                value={campaignData.tal}
                onChange={handleChange}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>

          {/* Additional Rows */}
          <div className='row'>
            {/* Location */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Location:</label>
              <input
                type='text'
                className='form-control'
                name='location'
                value={campaignData.location}
                onChange={handleChange}
              />
              {renderError('location')}
            </div>

            {/* Organization Size */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>
                Organization Size:<span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className='form-control'
                name='organizationSize'
                value={campaignData.organizationSize}
                onChange={handleChange}
              >
                <option value=''>Select Organization Size</option>
                {['Startup', 'Mid Size', 'Large Enterprise'].map(size => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
              {renderError('organizationSize')}
            </div>
          </div>

          <div className='row'>
            {/* ABM */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>ABM:</label>
              <select
                className='form-control'
                name='abm'
                value={campaignData.abm}
                onChange={handleChange}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            {/* Campaign Timeline */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Campaign Timeline:</label>
              <input
                type='text'
                className='form-control'
                name='campaignTimeline'
                value={campaignData.campaignTimeline}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='row'>
            {/* Suggest Tactics */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Suggest Tactics:</label>
              <select
                className='form-control'
                name='tactics.suggest'
                value={campaignData.tactics.suggest.toString()}
                onChange={handleChange}
              >
                <option value={true}>Auto</option>
                <option value={false}>I would like to Suggest</option>
              </select>
            </div>
            {campaignData.tactics.suggest === 'false' && (
              <div className='col-md-6 mb-3'>
                <label style={{ paddingBottom: '5px' }}>Tactics Type:</label>
                <select
                  className='form-control'
                  name='tactics.type'
                  value={campaignData.tactics.type}
                  onChange={handleChange}
                >
                  <option value=''>Select Tactics Type</option>
                  {enumValues.tactics.default.map(tactic => (
                    <option key={tactic} value={tactic}>
                      {tactic}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className='row'>
            {/* Job Title */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>
                Job Title:<span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className='form-control'
                name='jobTitle.title'
                value={campaignData.jobTitle.title}
                onChange={handleChange}
              >
                <option value=''>Select Job Title</option>
                {['ITDM', 'BDM', 'Both', 'Other'].map(title => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
            {/* Other Job Title */}
            <div className='col-md-6 mb-3'>
              {campaignData.jobTitle.title === 'Other' && (
                <div>
                  <label style={{ paddingBottom: '5px' }}>
                    Other Job Title:
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='jobTitle.otherTitle'
                    value={campaignData.jobTitle.otherTitle}
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Upload Assets File */}
          <div className='row'>
            {/* Assets File */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>Assets File:</label>
              <div
                className='file-input-wrapper'
                style={{
                  border: '2px dashed #0A58CA',
                  padding: '10px',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div>
                  <img
                    src={upload}
                    alt='Upload'
                    style={{ width: '25px', height: '25px' }}
                  />
                </div>
                <input
                  type='file'
                  className='form-control-file'
                  name='assetsFile'
                  onChange={handleChange}
                  accept='image/*'
                  style={{ display: 'none' }} // Hide the actual file input
                />
                <button
                  className='btn btn-success ml-auto'
                  style={{ marginLeft: 'auto' }}
                  onClick={e => {
                    e.preventDefault() // Prevent default action
                    document.querySelector('input[name="assetsFile"]').click()
                  }}
                >
                  Browse
                </button>
              </div>
              {renderFileInput('assetsFile', 'Assets File')}
            </div>

            {/* TAL List File */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>TAL List File:</label>
              <div
                className='file-input-wrapper'
                style={{
                  border: '2px dashed #0A58CA',
                  padding: '10px',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div>
                  <img
                    src={upload}
                    alt='Upload'
                    style={{ width: '25px', height: '25px' }}
                  />
                </div>
                <input
                  type='file'
                  className='form-control-file'
                  name='talListFile'
                  onChange={handleChange}
                  accept='image/*'
                  style={{ display: 'none' }} // Hide the actual file input
                />
                <button
                  className='btn btn-success ml-auto'
                  style={{ marginLeft: 'auto' }}
                  onClick={e => {
                    e.preventDefault() // Prevent default action
                    document.querySelector('input[name="talListFile"]').click()
                  }}
                >
                  Browse
                </button>
              </div>
              {renderFileInput('talListFile', 'TAL List File')}
            </div>
          </div>

          <div className='row'>
            {/* Upload Purchase Order File */}
            <div className='col-md-6 mb-3'>
              <label style={{ paddingBottom: '5px' }}>
                Purchase Order File:
              </label>
              <div
                className='file-input-wrapper'
                style={{
                  border: '2px dashed #0A58CA',
                  padding: '10px',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <input
                  type='file'
                  className='form-control-file'
                  name='purchaseOrderFile'
                  onChange={handleChange}
                  accept='image/*'
                  style={{ display: 'none' }} // Hide the actual file input
                />
                <div>
                  <img
                    src={upload}
                    alt='Upload'
                    style={{ width: '25px', height: '25px' }}
                  />
                </div>
                <button
                  className='btn btn-success ml-auto'
                  style={{ marginLeft: 'auto' }}
                  onClick={e => {
                    e.preventDefault() // Prevent default action
                    document
                      .querySelector('input[name="purchaseOrderFile"]')
                      .click()
                  }}
                >
                  Browse
                </button>
              </div>
              {renderFileInput('purchaseOrderFile', 'Purchase Order File')}
            </div>

            {/* Filter Image */}
            {/* Filter Image */}
            <div className='col-md-6'>
              <div className='form-group'>
                <label style={{ paddingBottom: '5px' }}>Filter Image:</label>
                <div
                  className='file-input-wrapper'
                  style={{
                    border: '2px dashed #0A58CA',
                    padding: '10px',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div>
                    <img
                      src={upload}
                      alt='Upload'
                      style={{ width: '25px', height: '25px' }}
                    />
                  </div>
                  <input
                    type='file'
                    className='form-control-file'
                    name='filterimage'
                    onChange={handleChange}
                    accept='image/*'
                    style={{ display: 'none' }} // Hide the actual file input
                  />
                  <button
                    className='btn btn-success ml-auto'
                    style={{ marginLeft: 'auto' }}
                    onClick={e => {
                      e.preventDefault() // Prevent default action
                      document
                        .querySelector('input[name="filterimage"]')
                        .click()
                    }}
                  >
                    Browse
                  </button>
                </div>
                {renderFileInput('filterimage', 'Filter Image')}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label style={{ paddingBottom: '5px' }}>
                  Campaign Additional filter
                </label>
                <select
                  className='form-control'
                  name='additionalFilterEnabled'
                  value={campaignData.additionalFilterEnabled}
                  onChange={handleAdditionalFilterChange}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                {campaignData.additionalFilterEnabled && (
                  <div className='mb-3'>
                    <label style={{ paddingBottom: '5px' }}>Filters:</label>
                    {campaignData.filters.map((filter, index) => (
                      <div key={index} className='input-group mb-3'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Enter filter'
                          value={filter}
                          onChange={e =>
                            handleFiltersChange(index, e.target.value)
                          }
                        />
                        <div className='input-group-append'>
                          <button
                            className='btn btn-outline-danger'
                            type='button'
                            onClick={() => removeFilterInput(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                    <button
                      className='btn btn-primary'
                      type='button'
                      onClick={addFilterInput}
                    >
                      Add Filter
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className='col-md-6'>
              <div
                className='col-md-12 mb-3 d-flex flex-wrap align-items-center'
                style={{ padding: '10px' }}
              >
                <label
                  style={{
                    marginRight: '10px',
                    width: '100%'
                  }}
                >
                  Industries:
                </label>
                {enumValues.industries.map(industry => (
                  <div
                    key={industry}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '20px',
                      marginBottom: '10px',
                      padding: '10px 20px',
                      borderRadius: '10px',
                      border: `2px solid ${
                        campaignData.industries.includes(industry)
                          ? '#55B56D'
                          : '#E4E4E4'
                      }`
                    }}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='industries'
                      value={industry}
                      checked={campaignData.industries.includes(industry)}
                      onChange={handleIndustriesChange}
                      style={{
                        marginRight: '5px',

                        borderRadius: '50%',
                        backgroundColor: campaignData.industries.includes(
                          industry
                        )
                          ? '#55B56D'
                          : '',

                        borderColor: campaignData.industries.includes(industry)
                          ? '#55B56D'
                          : ''
                      }}
                    />
                    <label className='form-check-label' style={{ margin: '0' }}>
                      {industry}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between'>
            <div style={{ flex: '1' }}></div>
            <button
              type='submit'
              style={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '10px',
                color: '#fff',
                fontWeight: '600',
                border: 'none'
              }}
            >
              Save & Continue
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default CampaignSetupForm
