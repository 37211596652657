import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { Link, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../actions";
import auth from "../../../../services/authServices";

import funnel from "../../../../assets/images/Dashbord_funnel.png";
import {
  deleteMIntentApply,
  getUserMIntentApply,
  getIntentMIntentApply,
  statusMIntentApply,
} from "../../../../services/mintentApplyService";
const Panel = () => {
  const user = useSelector((state) => state.userReducer);

  const [items, setItems] = useState([]);

  const location = useLocation();
  const fetchData = async () => {
    try {
      let id;
      let response;
      if (user.department === "Superadmin") {
        response = await getIntentMIntentApply(location.state.intent_id);
      } else {
        if (!location.state) {
          response = await getUserMIntentApply(user._id);
        } else {
          response = await getIntentMIntentApply(location.state.intent_id);
        }
      }

      setItems(response.data.intent_list);
    } catch (error) {
      console.error("Error fetching intent list:", error);
    }
  };

  useEffect(() => {
    if (location) fetchData();
  }, [location]);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const currentUser = await auth.getCurrentUser();
      dispatch(setUser(currentUser));
    };
    fetchData();
  }, []);

  // if (user && !user.isObjectiveSet)
  //   return <Navigate to={`/myprofessional`} replace />;
  // if (user && !user.isIndustriesRolesSet)
  //   return <Navigate to={`/tellus`} replace />;
  if (user && !user.isProfileSet)
    return <Navigate to={`/profilesummary`} replace />;

  // const sourceData = [
  //   {
  //     label: "Ads",
  //     value: 32,
  //   },
  //   {
  //     label: "Subcriber",
  //     value: 45,
  //   },
  //   {
  //     label: "sponsers",
  //     value: 23,
  //   },
  // ];

  return (
    <>
      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        {/* main div */}
        <div style={{ display: "flex", flexDirection: "column", width: "80%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 20px",
              color: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#F7A600",
                borderRadius: "10px",
                padding: "20px",
                flex: 1,
                margin: "0 10px",
              }}
            >
              <div>End Date:</div>
              <h6>31 July 2024</h6>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#0A58CA",
                borderRadius: "10px",
                padding: "20px",
                flex: 1,
                margin: "0 10px",
              }}
            >
              <div>Lead Delivered/Lead Goal</div>
              <h6>280 / 500</h6>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#55B56D",
                borderRadius: "10px",
                padding: "20px",
                flex: 1,
                margin: "0 10px",
              }}
            >
              <div>Lead Generated</div>
              <h6>60%</h6>
            </div>
          </div>
          {/* Funnel Image with Text and Lines */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "60px",
              position: "relative",
            }}
          >
            {/* left div */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "350px",
              }}
            >
              <div style={{ position: "relative" }}>
                <h6>Total Lead</h6>
                <div
                  style={{
                    height: "2px",
                    background: "#24F2FF",
                    width: "230%",
                    position: "absolute",
                    top: "50%",
                    zIndex: 1,
                  }}
                ></div>
                <h6 style={{ paddingTop: "2px" }}>LeadXchange</h6>
              </div>
              <div style={{ position: "relative" }}>
                <h6>Current Leads</h6>
                <div
                  style={{
                    height: "2px",
                    background: "#86F000",
                    width: "285%",
                    position: "absolute",
                    top: "100%",
                    zIndex: 1,
                  }}
                ></div>
              </div>
              <div style={{ position: "relative" }}>
                <h6>Targeted Leads</h6>
                <div
                  style={{
                    height: "2px",
                    background: "#F5BB00",
                    width: "330%",
                    position: "absolute",
                    top: "100%",
                    zIndex: 1,
                  }}
                ></div>
              </div>
            </div>
            {/* center div */}
            <div style={{ position: "relative", zIndex: 0 }}>
              <img
                src={funnel}
                alt="Funnel"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            {/* right div */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "350px",
              }}
            >
              <div style={{ position: "relative" }}>
                <h6>55+ Million</h6>
                <div
                  style={{
                    height: "2px",
                    background: "#24F2FF",
                    width: "270%",
                    position: "absolute",
                    top: "100%",
                    right: "0",
                    zIndex: 1,
                  }}
                ></div>
              </div>
              <div style={{ position: "relative" }}>
                <h6>22+ Million</h6>
                <div
                  style={{
                    height: "2px",
                    background: "#86F000",
                    width: "330%",
                    position: "absolute",
                    top: "100%",
                    right: "0",
                    zIndex: 1,
                    color: "#86F000",
                  }}
                ></div>
              </div>
              <div style={{ position: "relative" }}>
                <h6>500</h6>
                <div
                  style={{
                    height: "2.5px",
                    background: "#F5BB00",
                    width: "400%",
                    position: "absolute",
                    top: "100%",
                    right: "0",
                    zIndex: 1,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div
            style={{
              background: "#55B56D",
              display: "flex",
              alignItems: "center",
              width: "20%",
              marginBottom: "10px",
              marginRight: "10px",
              justifyContent: "center",
              padding: "10px",
              borderRadius: "10px",
              flexDirection: "column",
              alignSelf: "flex-end",
              color: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h6>ToFu</h6>
              <div
                style={{
                  height: "2px",
                  background: "#24F2FF",
                  width: "6rem",
                  margin: "0 8px",
                }}
              ></div>
              <h6 style={{ color: "#24F2FF" }}>35%</h6>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h6>MoFu</h6>
              <div
                style={{
                  height: "2px",
                  background: "#86F000",
                  width: "6rem",
                  margin: "0 8px",
                }}
              ></div>
              <h6 style={{ color: "#86F000" }}>25%</h6>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h6>BoFu</h6>
              <div
                style={{
                  height: "2px",
                  background: "#F7A600",
                  width: "6rem",
                  margin: "0 8px",
                }}
              ></div>
              <h6 style={{ color: "#F7A600" }}>30%</h6>
            </div>
          </div>
        </div>

        {/* rightside div */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "20%",
            background: "#F2F2F2",
            padding: "20px",
          }}
        >
          <label
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              defaultChecked
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                background: "#F7A600",
                borderRadius: "4px",
                accentColor: "green",
              }}
            />{" "}
            Assets
          </label>
          <label
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                background: "#F7A600",
                borderRadius: "4px",
                accentColor: "green",
              }}
            />{" "}
            Intent Data
          </label>
          <label
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              defaultChecked
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                background: "#F7A600",
                borderRadius: "4px",
                accentColor: "green",
              }}
            />{" "}
            Industries
          </label>
          <label
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                background: "#F7A600",
                borderRadius: "4px",
                accentColor: "green",
              }}
            />{" "}
            Revenue
          </label>
          <label
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              defaultChecked
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                background: "#F7A600",
                borderRadius: "4px",
                accentColor: "green",
              }}
            />{" "}
            Company Size
          </label>
          <label
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                background: "#F7A600",
                borderRadius: "4px",
                accentColor: "green",
              }}
            />{" "}
            Customer Questions
          </label>
          <label
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              style={{
                marginRight: "10px",
                width: "20px",
                height: "20px",
                background: "#F7A600",
                borderRadius: "4px",
                accentColor: "green",
              }}
            />{" "}
            Install Base
          </label>
          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "block", marginBottom: "10px" }}>
              Targeted Display
            </div>
            <div style={{ display: "block", marginBottom: "10px" }}>
              Email Newsletter Sponsorship
            </div>
            <div style={{ display: "block", marginBottom: "10px" }}>
              LinkedIn, Twitter, and Sponsored Ad
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Panel;
