import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../dashboard/AdminDashboard/pages/UserCampign/UserCampign.css";
import { toast } from "react-toastify";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";
import { FaCirclePlay } from "react-icons/fa6";
import { AiOutlineDownload } from "react-icons/ai";
import { FaCommentDots } from "react-icons/fa";
import { Chart, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

Chart.register(ArcElement);
const Csv = () => {
  const location = useLocation();
  const campaignId = location.pathname.split("/").pop(); // Extract campaign ID
  const [CsvData, setCsvData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const campaignsPerPage = 10; // You can adjust this value as per your requirement
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const totalLength = CsvData.length; // Total length of data

  // Count the number of approved and rejected items
  const approvedCount = CsvData.filter(
    (item) => item.status === "approved"
  ).length;
  const rejectedCount = CsvData.filter(
    (item) => item.status === "rejected"
  ).length;
  const pendingCount = CsvData.filter(
    (item) => item.status === "in review"
  ).length;

  const [showMore, setShowMore] = useState([]);

  const contentMaxLength = 10;

  // Function to return CSS class based on campaign status
  const getStatusColorClass = (status) => {
    switch (status) {
      case "complete":
        return { color: "#55b56d" };
      case "live":
        return { color: "#0A58CA" };
      case "pending":
        return { color: "#F7A600" };
      default:
        return {};
    }
  };

  const [hoverIndex, setHoverIndex] = useState(null); // Add hoverIndex state

  // Update renderParagraph function
  const renderParagraph = (content, index) => {
    if (
      typeof content !== "string" ||
      content === null ||
      content === undefined
    ) {
      return ""; // or handle the case when content is not a string
    }

    return (
      <div
        style={{ position: "relative", display: "inline-block" }}
        onMouseEnter={() => {
          console.log(`Hovered on comment index: ${index}`);
          setHoverIndex(index);
        }}
        onMouseLeave={() => {
          console.log(`Mouse left comment index: ${index}`);
          setHoverIndex(null);
        }}
      >
        <FaCommentDots
          size={20}
          style={{ cursor: "pointer", color: "#418af6" }}
        />
        {hoverIndex === index && (
          <span
            style={{
              visibility: "visible",
              width: "200px",
              backgroundColor: "#f9f9f9",
              color: "#000",
              textAlign: "center",
              borderRadius: "6px",
              padding: "5px 10px",
              position: "absolute",
              zIndex: 1,
              bottom: "125%", // Adjust as needed
              left: "50%",
              marginLeft: "-100px", // Adjust as needed
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            }}
          >
            {content}
          </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(`/csv/getSinglecsv/${campaignId}`);

        console.log("response", response);

        setCsvData(response.data[0]?.data || []); // Check if response.data[0]?.data is undefined
        setShowMore(new Array(response.data[0]?.data?.length || 0).fill(false)); // Initialize with false values
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError("Campaign not found");
        } else {
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignData();
  }, [campaignId]);

  const changePage = (increment) => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = prevPageNumber + increment;
      return newPageNumber >= 0 && newPageNumber < pageCount
        ? newPageNumber
        : prevPageNumber;
    });
  };

  const pageCount = Math.ceil(CsvData?.length / campaignsPerPage);

  const handleDownload = () => {
    axios
      .post("/csv/download", { campaignId }, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading data:", error);
      });
  };

  const handleDownloadAudio = async (filename) => {
    try {
      const response = await axios.get(`/api/audio/${filename}/download`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading audio:", error);
    }
  };

  function togglePlay(audioId) {
    const audio = document.getElementById(audioId);
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  }

  const handleChangeStatus = async (_id, status, rejectionReason) => {
    try {
      await axios.post("/csv/changeDataStatus", {
        _id,
        status,
        rejectionReason,
        campaignId,
      });
      toast.success("Data status updated successfully");
      // Refresh data after status update
      const response = await axios.get(`/csv/getSinglecsv/${campaignId}`);
      setCsvData(response.data[0]?.data || []);
    } catch (error) {
      toast.error("Error updating data status");
      console.error(error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedCsvId, setSelectedCsvId] = useState(null); // Track the ID of the selected CSV data

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // Reset selected reason and CSV ID
    setSelectedReason("");
    setSelectedCsvId(null);
  };

  const handleRejectWithModal = (_id) => {
    openModal();
    // Set the ID of the selected CSV data
    setSelectedCsvId(_id);
  };

  // Define state variable to track status change
  const [statusChanged, setStatusChanged] = useState(false);

  const handleStatusChangeWithReason = (_id, newStatus) => {
    if (newStatus === "rejected") {
      if (!selectedReason) {
        alert("Please select a rejection reason");
        return;
      }
      handleChangeStatus(_id, newStatus, selectedReason);
    } else {
      handleChangeStatus(_id, newStatus);
    }
    // Update statusChanged state
    setStatusChanged(true);
    closeModal(); // Close the modal after status change
  };

  const handleStatusChange = (_id, newStatus) => {
    if (newStatus === "rejected") {
      const reason = prompt("Enter rejection reason:");
      handleChangeStatus(_id, newStatus, reason);
    } else {
      handleChangeStatus(_id, newStatus);
    }
    setStatusChanged(true); // Set statusChanged to true after status change
  };

  // Dummy data for the donut chart
  const data = {
    datasets: [
      {
        data: [totalLength, pendingCount, approvedCount, rejectedCount],
        backgroundColor: ["#0A58CA", "#55B56D", "#F7A600", "#FE0000"],
      },
    ],
  };

  return (
    <>
      <div className="table-container">
        <h4 style={{ paddingBottom: "10px" }}>Lead Details</h4>
      </div>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            {/* Updated condition to check for empty array */}
            {CsvData.length === 0 ? (
              <p>No Data found </p>
            ) : (
              <>
                <div
                  style={{ display: "flex", width: "100%", height: "290px" }}
                >
                  <div
                    style={{
                      flex: "0 0 40%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "20px",
                      borderRadius: "20px",
                      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                      background: "#fff",
                    }}
                  >
                    <Doughnut data={data} />
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#0A58CA",
                            marginRight: "10px",
                            borderRadius: "4px",
                          }}
                        ></div>
                        Total {totalLength}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#55B56D",
                            marginRight: "10px",
                            borderRadius: "4px",
                          }}
                        ></div>
                        Pending {pendingCount}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#F7A600",
                            marginRight: "10px",
                            borderRadius: "4px",
                          }}
                        ></div>
                        Completed {approvedCount}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#FE0000",
                            marginRight: "10px",
                            borderRadius: "4px",
                          }}
                        ></div>
                        Rejected {rejectedCount}
                      </div>
                    </div>
                  </div>

                  <div
                    className="container"
                    style={{
                      paddingLeft: "20px",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridGap: "20px",
                      color: "white",
                      textTransform: "capitalize",
                    }}
                  >
                    <div
                      style={{
                        background: "#0A58CA",
                        padding: "20px",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      <h2>{totalLength}</h2>
                      <h6>Total leads</h6>
                    </div>
                    <div
                      style={{
                        background: "#FE0000",
                        padding: "20px",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      <h2>{rejectedCount}</h2>
                      <h6>Rejected</h6>
                    </div>
                    <div
                      style={{
                        background: "#F7A600",
                        padding: "20px",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      <h2>{approvedCount}</h2>
                      <h6>Approved</h6>
                    </div>
                    <div
                      style={{
                        background: "#55B56D",
                        padding: "20px",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "center",
                      }}
                    >
                      <h2>{pendingCount} </h2>
                      <h6>Pending</h6>
                    </div>
                  </div>
                </div>

                {/* CSV table start */}
                <div
                  className="table-container-csv"
                  style={{
                    borderRadius: "20px",
                    padding: "20px",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                    marginBottom: "40px",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingBottom: "20px",
                    }}
                  >
                    <button
                      onClick={() => handleDownload(CsvData._id)}
                      style={{
                        backgroundColor: "#55b56d", // Change color as needed
                        color: "white", // Change color as needed
                        border: "none",
                        padding: "8px 16px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Download
                    </button>
                  </div>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      borderRadius: "10px",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          ID
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Company Name
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Customer
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Designation
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Email
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Mobile
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          City
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Country
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Comments
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Play
                        </th>
                        <th
                          style={{
                            fontSize: "13px",
                            padding: "20px 10px",
                            background: "#F5F7FB",
                            color: "#989898",
                          }}
                        >
                          Audio
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {CsvData.slice(
                        pageNumber * campaignsPerPage,
                        (pageNumber + 1) * campaignsPerPage
                      ).map((csv, index) => (
                        <tr key={csv._id}>
                          <td>{pageNumber * campaignsPerPage + index + 1}</td>
                          <td
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",

                              alignItems: "start",
                            }}
                          >
                            {csv.companyName}
                          </td>
                          <td>{csv.customerName}</td>
                          <td>{csv.designation}</td>
                          <td>{csv.email}</td>
                          <td>{csv.mobileNumber}</td>
                          <td>{csv.city}</td>
                          <td>{csv.state}</td>
                          <td>{renderParagraph(csv.leadComments, index)}</td>

                          <td style={{ textAlign: "center" }}>
                            {csv.status === "in review" ? (
                              <select
                                value={csv.status}
                                onChange={(e) => {
                                  if (e.target.value === "rejected") {
                                    handleRejectWithModal(csv._id);
                                  } else {
                                    handleStatusChange(csv._id, e.target.value);
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  padding: "5px",
                                  backgroundColor: "#f7f7f7",
                                  color: "#333",
                                  border: "1px solid #ccc",
                                  borderRadius: "3px",
                                }}
                              >
                                <option value="in review">In Review</option>
                                <option
                                  value="approved"
                                  style={{ color: "green" }}
                                >
                                  Approved
                                </option>
                                <option
                                  value="rejected"
                                  style={{ color: "red" }}
                                >
                                  Reject
                                </option>
                              </select>
                            ) : (
                              <div
                                style={{
                                  color:
                                    csv.status === "rejected" ? "red" : "green",
                                  cursor:
                                    csv.status === "rejected"
                                      ? "pointer"
                                      : "default",
                                  textDecoration:
                                    csv.status === "rejected"
                                      ? "underline"
                                      : "none",
                                  textTransform: "capitalize",
                                }}
                                title={
                                  csv.status === "rejected"
                                    ? `Rejection Reason: ${csv.rejectionReason}`
                                    : ""
                                }
                              >
                                {" "}
                                <div style={getStatusColorClass(csv.status)}>
                                  <span
                                    style={{
                                      display: "flex",
                                      width: "75px", // Adjust the width as per your design needs
                                      background: "#F6F6F6",
                                      padding: "8px 8px",
                                      borderRadius: "8px",
                                      fontSize: "12px", // Adding consistent font size
                                      textTransform: "capitalize", // Assuming you want to capitalize the text
                                      textAlign: "center",
                                      justifyContent: "center", // Center aligning the text within the span
                                    }}
                                  >
                                    {csv.status}
                                  </span>
                                </div>
                              </div>
                            )}
                            {csv.status === "in review" &&
                              isModalOpen &&
                              selectedCsvId === csv._id && (
                                <div
                                  style={{
                                    position: "fixed",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: "1000",
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#fff",
                                      padding: "20px",
                                      borderRadius: "3px",
                                      boxShadow: "0 0 10px rgba(0,0,0,0.3)",
                                    }}
                                  >
                                    <h2>Select Rejection Reason</h2>
                                    <select
                                      value={selectedReason}
                                      onChange={(e) =>
                                        setSelectedReason(e.target.value)
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #ccc",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      <option value="">Select Reason</option>
                                      <option value="No need">No need</option>
                                      <option value="No timeline (beyond 1-6 months)">
                                        No timeline (beyond 1-6 months)
                                      </option>
                                      <option value="No budget">
                                        No budget
                                      </option>
                                      <option value="No authority">
                                        No authority
                                      </option>
                                      <option value="Invalid contact form">
                                        Invalid contact form
                                      </option>
                                    </select>
                                    <button
                                      onClick={() =>
                                        handleStatusChangeWithReason(
                                          csv._id,
                                          "rejected"
                                        )
                                      }
                                      disabled={!selectedReason}
                                      style={{
                                        padding: "10px 20px",
                                        backgroundColor: selectedReason
                                          ? "#ff0000"
                                          : "#ccc",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: selectedReason
                                          ? "pointer"
                                          : "not-allowed",
                                      }}
                                    >
                                      Reject
                                    </button>
                                    <button
                                      onClick={closeModal}
                                      style={{
                                        marginLeft: "10px",
                                        padding: "10px 20px",
                                        backgroundColor: "#ccc",
                                        color: "#000",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              )}
                          </td>

                          <td>
                            {csv.audio &&
                            csv.audio.audio &&
                            csv.audio.audio.filePath ? (
                              <li key={csv._id} style={{ color: "blue" }}>
                                <audio
                                  id={`audio-${csv._id}`}
                                  src={`/api/audio/${csv.audio.audio.filename}.gz`}
                                  type="audio/mpeg"
                                ></audio>
                                <div
                                  onClick={() => togglePlay(`audio-${csv._id}`)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <FaCirclePlay
                                    size={25}
                                    style={{ color: "#F7A600" }}
                                  />
                                </div>
                              </li>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                <MdErrorOutline
                                  size={20}
                                  style={{ color: "red" }}
                                />
                                Audio
                              </div>
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {csv.audio ? (
                              <div
                                style={{ border: "none" }}
                                onClick={() =>
                                  handleDownloadAudio(csv.audio.audio.filename)
                                }
                              >
                                <AiOutlineDownload
                                  size={20}
                                  style={{
                                    color: "#28a745",
                                    margin: "8px",
                                    border: "none",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                <MdErrorOutline
                                  size={20}
                                  style={{ color: "red" }}
                                />
                                Audio
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      <button
                        onClick={() => changePage(-1)}
                        disabled={pageNumber === 0}
                        style={{
                          color: "black",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          border: "F2F2F2",
                          marginRight: "10px",
                        }}
                      >
                        <span>
                          {" "}
                          <IoIosArrowRoundBack size={25} />
                        </span>
                        Previous
                      </button>
                      <button
                        onClick={() => changePage(1)}
                        disabled={pageNumber === pageCount - 1}
                        style={{
                          color: "black",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          border: "F2F2F2",
                        }}
                      >
                        {" "}
                        Next
                        <span>
                          <IoIosArrowRoundForward size={25} />
                        </span>
                      </button>
                    </div>
                    <div>
                      <span style={{ color: "#0A58CA" }}>
                        Page {pageNumber + 1} of {pageCount}
                      </span>
                    </div>
                  </div>
                </div>
                {/* CSV table end */}

                {/* csv table end */}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Csv;
