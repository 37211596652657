import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(`${process.env.REACT_APP_PULISHABLE_KEY}`)
const PaymentForm = () => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [enumTactics, setEnumTactics] = useState([])

  const enumValues = {
    reportFrequency: [
      'Daily',
      'Twice a week',
      'Weekly',
      'Fortnightly',
      'Monthly',
      'Others'
    ],
    industries: [
      'Cross Industry',
      'BFSI',
      'Manufacturing',
      'Retail',
      'Entertainment',
      'Health & Pharma',
      'ITES',
      'Education',
      'Media and news',
      'Construction',
      'Hospitality',
      'Other'
    ],
    funnel: ['Tofu', 'Mofu', 'Bofu', 'All'],
    objectives: [
      'Brand Awareness',
      'Generate Sales',
      'Lead Capture and Nurturing',
      'Increase Website Traffic'
    ],
    tactics: {
      default: [
        'Content Syndication',
        'Email marketing',
        'Newsletter',
        'Webinar',
        '1-on-1',
        'Digital Chat series',
        'Digital Board room',
        'Inner Board room',
        'Social Media'
      ],
      auto: ['Auto']
    }
  }

  const [campaignData, setCampaignData] = useState({
    funnel: [],
    objective: '',
    abm: true,
    jobTitle: {
      title: '',
      otherTitle: ''
    },
    tal: true,
    location: '',
    industries: [],
    organizationSize: '',
    tactics: {
      type: 'Auto',
      suggest: true
    },
    assetsFile: null,
    talListFile: null,
    additionalFilterEnabled: false,
    filters: [],
    filterimage: null,
    purchaseOrderFile: null,
    additionalFilters: '',
    campaignTimeline: '',
    reportFrequency: '',
    leadCategory: '',
    quantity: 1,
    region: '',
    tal2: '',
    talCheck: false,
    totalAmount: 0,
    subtotal: 1000,
    tax: 0
  })

  useEffect(() => {
    // Check if user data exists in sessionStorage
    const userDataString = sessionStorage.getItem('user')

    if (!userDataString) {
      // Navigate user to sign-in page if no user data is found
      navigate('/signin')
      return
    }
  }, [navigate])

  const handleChange = e => {
    const { name, value, type, checked, files } = e.target

    if (type === 'checkbox') {
      if (name === 'funnel' || name === 'objective' || name === 'industries') {
        const updatedValues = checked
          ? [...campaignData[name], value]
          : campaignData[name].filter(item => item !== value)
        setCampaignData({ ...campaignData, [name]: updatedValues })
      } else {
        setCampaignData({ ...campaignData, [name]: checked })
      }
    } else if (name.startsWith('jobTitle')) {
      // Handle jobTitle changes
      const updatedJobTitle = {
        ...campaignData.jobTitle,
        [name.split('.')[1]]: value
      }
      setCampaignData({ ...campaignData, jobTitle: updatedJobTitle })
    } else if (name.startsWith('tactics')) {
      // Handle tactics changes
      const updatedTactics = {
        ...campaignData.tactics,
        [name.split('.')[1]]: value
      }
      setCampaignData({ ...campaignData, tactics: updatedTactics })
      // Update tactics enum based on selection of "Auto"
      if (value === 'Auto') {
        setEnumTactics(enumValues.tactics.auto)
      } else {
        setEnumTactics(enumValues.tactics.default)
      }
    } else if (type === 'file') {
      // Handle file input changes
      console.log('Files:', files)
      setCampaignData({ ...campaignData, [name]: files[0] })
    } else {
      setCampaignData({ ...campaignData, [name]: value })
    }
  }

  const handleIndustriesChange = e => {
    const { value, checked } = e.target
    const updatedIndustries = [...campaignData.industries]

    if (checked && !updatedIndustries.includes(value)) {
      updatedIndustries.push(value)
    } else if (!checked && updatedIndustries.includes(value)) {
      const index = updatedIndustries.indexOf(value)
      updatedIndustries.splice(index, 1)
    }

    setCampaignData({ ...campaignData, industries: updatedIndustries })
  }

  const handleAdditionalFilterChange = e => {
    const { name, value } = e.target
    setCampaignData({ ...campaignData, [name]: value === 'true' })
  }

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    setCampaignData(prevState => ({
      ...prevState,
      [name]: checked,
      totalAmount: calculateTotalAmount(prevState, checked)
    }))
  }

  const calculateTotalAmount = (data, isExtra) => {
    const subtotal = data.subtotal
    const tax = 5
    let total = subtotal + tax

    if (isExtra) {
      total *= 1.25
    }
    total = total * data.quantity

    return total.toFixed(2)
  }

  const handleFiltersChange = (index, value) => {
    const updatedFilters = [...campaignData.filters]
    updatedFilters[index] = value
    setCampaignData({ ...campaignData, filters: updatedFilters })
  }

  const addFilterInput = () => {
    setCampaignData({
      ...campaignData,
      filters: [...campaignData.filters, '']
    })
  }

  const removeFilterInput = index => {
    const updatedFilters = [...campaignData.filters]
    updatedFilters.splice(index, 1)
    setCampaignData({ ...campaignData, filters: updatedFilters })
  }

  const renderFileInput = (name, label) => (
    <div className='form-group'>
      {campaignData[name] && (
        <img
          src={URL.createObjectURL(campaignData[name])}
          alt='Preview'
          className='mt-2 mb-2'
          style={{
            width: '400px',
            height: '200px',
            objectFit: 'fit',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
          onClick={e => {
            e.preventDefault()
          }}
        />
      )}
    </div>
  )

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const requiredFields = [
        'funnel',
        'objective',
        'location',
        'organizationSize'
      ]

      // Validate required fields
      const isValid = requiredFields.every(field => {
        const value = campaignData[field]
        if (field === 'funnel' || field === 'industries') {
          return Array.isArray(value) && value.length > 0
        } else {
          return typeof value === 'string' && value.trim() !== ''
        }
      })

      if (!isValid) {
        throw new Error('Please fill out all required fields.')
      }

      // Validate reportFrequency
      if (!enumValues.reportFrequency.includes(campaignData.reportFrequency)) {
        throw new Error('Invalid report frequency selected.')
      }

      // Validate industries
      if (
        !campaignData.industries.every(industry =>
          enumValues.industries.includes(industry)
        )
      ) {
        throw new Error('Invalid industry selected.')
      }

      const userDataString = sessionStorage.getItem('user')
      if (!userDataString) {
        throw new Error('User data not found in Local Storage')
      }
      const userData = JSON.parse(userDataString)
      const userId = userData._id

      const formData = new FormData()
      formData.append('user', userId)

      Object.entries(campaignData).forEach(([key, value]) => {
        if (key === 'jobTitle') {
          formData.append('jobTitle[title]', value.title)
          formData.append('jobTitle[otherTitle]', value.otherTitle)
        } else if (key === 'tactics') {
          formData.append('tactics[type]', value.type)
          formData.append('tactics[suggest]', value.suggest.toString())
        } else if (value !== null && value !== 'null' && value !== '') {
          if (key === 'industries' || key === 'funnel') {
            value.forEach(item => formData.append(key, item))
          } else {
            formData.append(key, value)
          }
        }
      })

      const response = await axios.post(
        'http://localhost:5000/api/payment/create-checkout-session',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      const { sessionId } = response.data
      const stripe = await stripePromise
      const { error } = await stripe.redirectToCheckout({ sessionId })

      if (error) {
        console.error('Stripe checkout error:', error)
        setErrorMessage(error.message)
      }
    } catch (error) {
      console.error('Submission error:', error)
      setErrorMessage(error.message)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Lead Category Dropdown */}
      <div className='form-group'>
        <label>Lead Category:</label>
        <select
          className='form-control'
          name='leadCategory'
          value={campaignData.leadCategory}
          onChange={handleChange}
        >
          <option value=''>Select Lead Category</option>
          <option value='Form Fills'>Form Fills</option>
          <option value='BANT'>BANT</option>
          <option value='Appointment Setting'>Appointment Setting</option>
          <option value='Triangular call'>Triangular call</option>
        </select>
      </div>

      {/* Quantity Dropdown */}
      <div className='form-group'>
        <label>Quantity:</label>
        <select
          className='form-control'
          name='quantity'
          value={campaignData.quantity}
          onChange={handleChange}
        >
          <option value=''>Select Quantity</option>
          {[...Array(10).keys()].map(i => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>

      {/* Region Dropdown */}
      <div className='form-group'>
        <label>Region:</label>
        <select
          className='form-control'
          name='region'
          value={campaignData.region}
          onChange={handleChange}
        >
          <option value=''>Select Region</option>
          <option value='Singapore'>Singapore</option>
          <option value='India'>India</option>
          <option value='Malaysia/ Indonesia / Philippines/ Vietnam / Thailand'>
            Malaysia/ Indonesia / Philippines/ Vietnam / Thailand
          </option>
          <option value='USA'>USA</option>
          <option value='EMEA'>EMEA</option>
          <option value='ANZ'>ANZ</option>
          <option value='Japan'>Japan</option>
          <option value='South Korea'>South Korea</option>
        </select>
      </div>

      {/* TAL Dropdown */}
      <div className='form-group'>
        <label>TAL:</label>
        <select
          className='form-control'
          name='tal2'
          value={campaignData.tal2}
          onChange={handleChange}
        >
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </select>
      </div>

      {/* TAL Check Box */}
      <div className='form-group'>
        <input
          type='checkbox'
          name='talCheck'
          checked={campaignData.talCheck}
          onChange={handleCheckboxChange}
        />
        <label>In case of Yes the system should calculate 25% extra</label>
      </div>

      {/* Total Amount Calculation */}
      <div className='form-group'>
        <div className='d-flex justify-content-between'>
          <span>Subtotal:</span>
          <span>
            {campaignData.region === 'India' ? '₹ ' : '$ '}
            {campaignData.subtotal}
          </span>
        </div>
        <div className='d-flex justify-content-between'>
          <span>Tax:</span>
          <span>
            {campaignData.region === 'India' ? '₹ ' : '$ '}
            {campaignData.tax}
          </span>
        </div>
        <div className='d-flex justify-content-between'>
          <span>Total Amount:</span>
          <span
            style={{ fontSize: '18px', fontWeight: 'bold', color: '#0A58CA' }}
          >
            {campaignData.region === 'India' ? '₹ ' : '$ '}
            {campaignData.totalAmount}
          </span>
        </div>
      </div>

      {/* Error Message */}
      {errorMessage && (
        <div className='alert alert-danger' role='alert'>
          {errorMessage}
        </div>
      )}

      {/* Submit Button */}
      <button type='submit' className='btn btn-primary'>
        Pay Now
      </button>
    </form>
  )
}

export default PaymentForm
