import React, { useState, useEffect } from 'react'
import Joi from 'joi-browser'
import { useSelector } from 'react-redux'
import Form from '../../common/form'
import { toast } from 'react-toastify'
import { setUserProfile } from '../../services/userService'
import jobProfile from '../../assets/images/images/jop-profile.png'
import { RiPencilFill } from 'react-icons/ri'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
// import Freecurrencyapi from '@everapi/freecurrencyapi-js'

const pricing = {
  Singapore: {
    'Form Fills': 60,
    BANT: 250,
    'Appointment Setting': 350,
    'Triangular call': 450
  },
  'Malaysia/ Indonesia / Philippines/ Vietnam / Thailand': {
    'Form Fills': 50,
    BANT: 250,
    'Appointment Setting': 350,
    'Triangular call': 450
  },
  US: {
    'Form Fills': 70,
    BANT: 350,
    'Appointment Setting': 600,
    'Triangular call': 750
  },
  EMEA: {
    'Form Fills': 70,
    BANT: 250,
    'Appointment Setting': 350,
    'Triangular call': 450
  },
  ANZ: {
    'Form Fills': 60,
    BANT: 250,
    'Appointment Setting': 350,
    'Triangular call': 450
  },
  Japan: {
    'Form Fills': 70,
    BANT: 250,
    'Appointment Setting': 350,
    'Triangular call': 450
  },
  'South Korea': {
    'Form Fills': 70,
    BANT: 250,
    'Appointment Setting': 350,
    'Triangular call': 450
  },
  India: {
    'Form Fills': 47.65,
    BANT: 101.25,
    'Appointment Setting': 297.78,
    'Triangular call': 357.34
  }
}

const leadCategoryOptions = [
  { value: '', label: 'Select Lead Category' },
  { value: 'Form Fills', label: 'Form Fills' },
  { value: 'BANT', label: 'BANT' },
  { value: 'Appointment Setting', label: 'Appointment Setting' },
  { value: 'Triangular call', label: 'Triangular call' }
]
const quantityOptions = [
  { value: '', label: 'Select Quantity' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' }
]
const regionOptions = [
  { value: '', label: 'Select Region' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'India', label: 'India' },
  {
    value: 'Malaysia/ Indonesia / Philippines/ Vietnam / Thailand',
    label: 'Malaysia/ Indonesia / Philippines/ Vietnam / Thailand'
  },
  { value: 'US', label: 'USA' },
  { value: 'EMEA', label: 'EMEA' },
  { value: 'ANZ', label: 'ANZ' },
  { value: 'Japan', label: 'Japan' },
  { value: 'South Korea', label: 'South Korea' }
]
const talOptions = [
  { value: '', label: 'Select Yes / No' },
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
]

const UserProfileForm = () => {
  // const freecurrencyapi = new Freecurrencyapi(
  //   'fca_live_Zvlfl1XUTh1Kb66dCQD0V6T5DJT76YINHNmoRRyQ'
  // )
  // freecurrencyapi
  //   .latest({
  //     base_currency: 'USD',
  //     currencies: 'INR'
  //   })
  //   .then(response => {
  //     console.log(response)
  //   })

  const stripePromise = loadStripe(process.env.REACT_APP_PULISHABLE_KEY)
  const user = useSelector(state => state.userReducer)
  const initialData = {
    _id: user?._id ?? '',
    img1: user?.img1 ?? '',
    full_name: user?.full_name ?? '',
    last_name: user?.last_name ?? '',
    email: user?.email ?? '',
    designation: user?.designation ?? '',
    company_name: user?.company_name ?? '',
    mobile: user?.mobile ?? '',
    city: user?.city ?? '',
    state: user?.state ?? '',
    country: user?.country ?? '',
    about: user?.about ?? '',
    skills: user?.skills ?? '',
    linkedin_profile: user?.linkedin_profile ?? '',
    leadCategory: '',
    quantity: 1,
    region: 'India',
    tal: 'no',
    subTotal: 1,
    total: 1,
    tax: 18,
    currencySymbol: '$', // Default to USD
    conversionRate: 1 // Default conversion rate
  }

  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const [base64Image, setBase64Image] = useState('')
  // const navigate = useNavigate()
  // const location = useLocation()

  // useEffect(() => {
  //   const fetchConversionRate = async () => {
  //     try {
  //       if (data?.region === 'India') {
  //         const response = await freecurrencyapi.latest({
  //           base_currency: 'USD',
  //           currencies: 'INR'
  //         })

  //         console.log('API Response:', response) // Log the entire response

  //         // Check if the INR key exists in the response data
  //         if (response.data && response.data.INR) {
  //           const conversionRate = response.data.INR
  //           setData(prevState => ({
  //             ...prevState,
  //             currencySymbol: '₹',
  //             conversionRate
  //           }))
  //         } else {
  //           console.error('INR currency data not found in API response')
  //           // Set a default conversion rate or handle as needed
  //           setData(prevState => ({
  //             ...prevState,
  //             currencySymbol: '₹',
  //             conversionRate: 1 // Fallback to 1 if not found
  //           }))
  //         }
  //       } else {
  //         setData(prevState => ({
  //           ...prevState,
  //           currencySymbol: '$',
  //           conversionRate: 1 // Reset to USD
  //         }))
  //       }
  //     } catch (error) {
  //       console.error('Error fetching conversion rate:', error)
  //       // Handle error and maybe set a default conversion rate
  //       setData(prevState => ({
  //         ...prevState,
  //         currencySymbol: '₹',
  //         conversionRate: 1 // Fallback conversion rate
  //       }))
  //     }
  //   }

  //   fetchConversionRate()
  // }, [data.region])

  useEffect(() => {
    const calculateTotals = () => {
      const unitPrice = pricing[data.region]?.[data.leadCategory] || 0

      let subTotal = unitPrice * data.quantity * data.conversionRate
      subTotal = Math.round(subTotal)

      let total = subTotal

      if (data.tal === 'yes') {
        total *= 1.25
      }

      // Calculate 18% tax on the total amount
      const taxAmount = total * 0.18
      total += taxAmount

      total = Math.round(total)

      setData(prevState => ({
        ...prevState,
        subTotal,
        total,
        tax: Math.round(taxAmount) // Save the calculated tax amount if needed
      }))
    }

    calculateTotals()
  }, [
    data.quantity,
    data.tal,
    data.conversionRate,
    data.region,
    data.leadCategory,
    data.tax // You can remove this if tax is now calculated inside
  ])

  const getCurrencySymbol = () => {
    return data.region === 'India' ? '₹' : '$'
  }

  const doSubmit = async () => {
    // Perform validation
    const options = { abortEarly: false }
    const {
      subTotal,
      total,
      tax,
      conversionRate,
      currencySymbol,
      ...validatedData
    } = data
    const { error } = Joi.validate(validatedData, schema, options)

    if (error) {
      const validationErrors = {}
      error.details.forEach(detail => {
        validationErrors[detail.path[0]] = detail.message
        console.log(
          `Validation error on field ${detail.path[0]}: ${detail.message}`
        ) // Log specific error
      })
      setErrors(validationErrors)
      toast.error('Please correct the errors in the form.')
      return
    }

    // No errors, continue with payment
    console.log('Validation passed, proceeding with payment...')

    try {
      const stripe = await stripePromise

      // Send a request to your server to create a checkout session
      const paymentResponse = await axios.post('/payment', data, {
        headers: {
          Authorization: `Bearer sk_test_51PcjSaKqIA5zCeSxe4dQ1uUq6DNdjtA3pCLq6aqQoJ9EURfqrtyE7JGgga5RTiPvTax1xP3jUmln7peJQ1EI1fdq00svcITThB`
        }
      })

      const sessionId = paymentResponse.data.id
      const profileResponse = await setUserProfile(data._id, data)
      const { data: responseData } = profileResponse
      toast.success(responseData.message)

      // Set user details in session storage
      sessionStorage.setItem('user', JSON.stringify(responseData.user))
      // alert('1')
      // Redirect to Checkout
      const result = await stripe.redirectToCheckout({ sessionId })
      if (result.error) {
        console.error(result.error)
        alert('Payment failed: ' + result.error.message)
        return // Exit if payment fails
      }
      // Payment was successful, now update the profile

      // Redirect user
      // let redirectUrl = process.env.REACT_APP_liveURL
      // if (process.env.NODE_ENV === 'development') {
      //   redirectUrl = process.env.REACT_APP_localURL
      // }
      // const path = location?.state
      //   ? `${redirectUrl}${location?.state?.history}`
      //   : `${redirectUrl}/user_dashboard`

      // window.location.href = path
    } catch (error) {
      console.error('Payment or profile update error:', error)
      alert('An error occurred during payment processing or profile update.')

      // Handle error related to payment or profile update
      if (error.response && error.response.status >= 400) {
        toast.error(error.response.data.message)
      }
    }
  }

  const schema = {
    _id: Joi.optional().allow(''),
    img1: Joi.optional().allow(''),
    full_name: Joi.string().required().label('Full Name'),
    last_name: Joi.string().required().label('Last Name'),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    designation: Joi.string().required().label('Designation'),
    company_name: Joi.string().required().label('Company Name'),
    mobile: Joi.string().required().label('Mobile Phone'),
    city: Joi.string().required().label('City Name'),
    state: Joi.string().required().label('State Name'),
    country: Joi.string().required().label('Country Name'),
    about: Joi.optional().allow(''),
    skills: Joi.optional().allow(''),
    linkedin_profile: Joi.optional().allow(''),
    leadCategory: Joi.string()
      .valid('Form Fills', 'BANT', 'Appointment Setting', 'Triangular call')
      .required()
      .label('Lead Category'),
    quantity: Joi.number()
      .integer()
      .min(1)
      .max(11)
      .required()
      .label('Quantity'),
    region: Joi.string()
      .valid(
        'Singapore',
        'India',
        'Malaysia/ Indonesia / Philippines/ Vietnam / Thailand',
        'US',
        'EMEA',
        'ANZ',
        'Japan',
        'South Korea'
      )
      .required()
      .label('Region'),
    tal: Joi.string().valid('yes', 'no').required().label('TAL')
  }

  const {
    renderInput,
    renderFile,
    renderSelect,
    renderInputArea,
    handleSubmit
  } = Form({
    data,
    setData,
    base64Image,
    setBase64Image,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit
  })

  useEffect(() => {
    const buttonElement = document.querySelector('.forms-sample .btn')
    if (buttonElement) {
      buttonElement.classList.add('btn-gradient-primary')
    }
  }, [])

  return (
    <>
      <form className='profiless-img' onSubmit={handleSubmit}>
        <div className='row'>
          <h6>Update Your Profile</h6>
          <div
            className='col-md-3'
            style={{ width: '160px', margin: '0 auto 35px' }}
          >
            <div className='prfl-frm-left' style={{ position: 'relative' }}>
              {renderFile('img1', '', 'file')}
              <img
                id='imagePreview'
                src={data.img1 === '' ? jobProfile : data.img1}
                alt='Preview'
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  display: 'block',
                  margin: '0 auto'
                }}
              />
              <RiPencilFill
                size={30}
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  backgroundColor: '#0A58CA',
                  borderRadius: '50%',
                  padding: '5px',
                  color: '#fff',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                }}
              />
            </div>
          </div>
          <div className='col-md-12'>
            <div className='prfl-frm'>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('full_name', 'Full Name')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('last_name', 'Last Name')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('email', 'Email')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('mobile', 'Mobile', 'tel')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('company_name', 'Company Name')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('designation', 'Designation')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('city', 'City')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('state', 'State')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('country', 'Country')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderInput('linkedin_profile', 'Linkedin Profile Link')}
              </div>
              <div className='prfl-frm-mns mb-4'>
                {renderInputArea('about', 'About me ( Min 250 words )')}
              </div>
              <div className='prfl-frm-mns mb-4'>
                {renderInputArea('skills', 'Mention your Skills')}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderSelect(
                  'leadCategory',
                  'Lead Category',
                  leadCategoryOptions
                )}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderSelect('quantity', 'Quantity', quantityOptions)}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderSelect('region', 'Region', regionOptions)}
              </div>
              <div className='prfl-frm-mn mb-4'>
                {renderSelect('tal', 'TAL', talOptions)}
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <div style={{ flex: '1' }}></div>
            <button
              type='submit'
              // onClick={doSubmit}
              name='submit'
              value='continue'
              style={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '10px',
                color: '#fff',
                fontWeight: '600',
                border: 'none'
              }}
            >
              Save & Continue
            </button>
          </div>
          <div className='col-md-12 p-3 d-flex justify-content-end'>
            <div
              className='p-3'
              style={{
                backgroundColor: '#F8F8F8',
                width: '25%',
                borderRadius: 15
              }}
            >
              <div className='d-flex justify-content-between mb-3'>
                <span className='font-weight-bold text-secondary'>
                  Subtotal:
                </span>
                <span>
                  {getCurrencySymbol()}
                  {data.subTotal}
                </span>
              </div>
              <div className='d-flex justify-content-between mb-3'>
                <span className='font-weight-bold text-secondary'>Tax:</span>
                <span>
                  {getCurrencySymbol()}
                  {data.tax}
                </span>
              </div>
              <div className='d-flex justify-content-between'>
                <span className='font-weight-bold text-secondary'>
                  Total Amount:
                </span>
                <span className='font-weight-bold text-primary'>
                  {getCurrencySymbol()}
                  {data.total}
                </span>
              </div>
            </div>
          </div>
          <div>
            <p>
              Maximum Period to deliver the campaign ranges from 15 days to 90
              days depending on the volume and region.
            </p>
            <p>
              LX Lead Management platform will allow you to manage your leads.
            </p>
            <p>
              TAL (Total Account List/Wishlist) based activity will attract a
              25% premium.
            </p>
            <p>
              Lead Rejection Policy:{' '}
              <a href='https://www.leadxchange.ai/leadrejection'>
                https://www.leadxchange.ai/leadrejection
              </a>
            </p>
            <p>
              Creation of a landing page will attract $1000 extra with $100
              monthly maintenance cost.
            </p>
          </div>
        </div>
      </form>
    </>
  )
}

export default UserProfileForm
