import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import '../dashboard/AdminDashboard/pages/UserCampign/UserCampign.css'
import { FaFileCsv } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { FaPlay, FaPause } from 'react-icons/fa'
import ReactPlayer from 'react-player'
import { ImFolderUpload } from 'react-icons/im'
import { FaTrash } from 'react-icons/fa' // Added trash icon
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io'
import { AiOutlineDownload } from 'react-icons/ai'
import { FaCirclePlay } from 'react-icons/fa6'
import { MdErrorOutline } from 'react-icons/md'
import { FaCommentDots } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

let headers = [
  'ID',
  'Company Name',
  'Customer Name',
  'Designation',
  'Email',
  'Mobile Number',
  'City',
  'Country',
  'Lead Comments',
  'Play',
  'Action',
  'D/L Audio',
  'Status',
  'Date'
]
let headers2 = [
  'ID',
  'Company Name',
  'Customer Name',
  'Designation',
  'Email',
  'Mobile Number',
  'City',
  'Country',
  'Lead Comments',
  'Audio',
  'Play',
  'Action',
  'D/L Audio',
  'Status',
  'Delete',
  'Date'
]

const AdminCsv = () => {
  const location = useLocation()
  const campaignId = location.pathname.split('/').pop() // Extract campaign ID
  const [CsvData, setCsvData] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const campaignsPerPage = 10 // You can adjust this value as per your requirement
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [showMore, setShowMore] = useState([])

  const user = useSelector(state => state.userReducer)

  const contentMaxLength = 50
  console.log(CsvData)
  const handleAudioUpload = async (dataId, file) => {
    try {
      const formData = new FormData()
      formData.append('audio', file)
      formData.append('dataId', dataId) // Updated to include dataId
      await axios.post('/api/audio/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      toast.success('Audio uploaded successfully')
      window.location.reload()
    } catch (error) {
      toast.error('Error uploading audio')
      console.error(error)
    }
  }

  const [hoverIndex, setHoverIndex] = useState(null) // Add hoverIndex state

  // Update renderParagraph function
  const renderParagraph = (content, index) => {
    if (
      typeof content !== 'string' ||
      content === null ||
      content === undefined
    ) {
      return '' // or handle the case when content is not a string
    }

    return (
      <div
        style={{ position: 'relative', display: 'inline-block' }}
        onMouseEnter={() => {
          console.log(`Hovered on comment index: ${index}`)
          setHoverIndex(index)
        }}
        onMouseLeave={() => {
          console.log(`Mouse left comment index: ${index}`)
          setHoverIndex(null)
        }}
      >
        <FaCommentDots
          size={20}
          style={{ cursor: 'pointer', color: '#418af6' }}
        />
        {hoverIndex === index && (
          <span
            style={{
              visibility: 'visible',
              width: '200px',
              backgroundColor: '#f9f9f9',
              color: '#000',
              textAlign: 'center',
              borderRadius: '6px',
              padding: '5px 10px',
              position: 'absolute',
              zIndex: 1,
              bottom: '125%', // Adjust as needed
              left: '50%',
              marginLeft: '-100px', // Adjust as needed
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
            }}
          >
            {content}
          </span>
        )}
      </div>
    )
  }

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(`/csv/getSinglecsv/${campaignId}`)
        console.log(response.data, 'admincsv')
        setCsvData(response.data[0]?.data || []) // Check if response.data[0]?.data is undefined
        setShowMore(new Array(response.data[0]?.data?.length || 0).fill(false)) // Initialize with false values
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('Campaign not found')
        } else {
          setError(error.message)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchCampaignData()
  }, [campaignId])

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const pageCount = Math.ceil(CsvData?.length / campaignsPerPage)

  const handleDownload = () => {
    axios
      .post('/csv/download', { campaignId }, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'data.csv')
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        console.error('Error downloading data:', error)
      })
  }

  const handleDownloadAudio = async filename => {
    try {
      const response = await axios.get(`/api/audio/${filename}/download`, {
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error downloading audio:', error)
    }
  }

  function togglePlay (audioId) {
    const audio = document.getElementById(audioId)
    if (audio.paused) {
      audio.play()
    } else {
      audio.pause()
    }
  }
  const handleDeleteCsvData = async dataId => {
    try {
      await axios.delete(`/csv/deleteCSVData/${campaignId}/data/${dataId}`)
      toast.success('Data deleted successfully')
      // Refresh CSV data after deletion
      const response = await axios.get(`/csv/getSinglecsv/${campaignId}`)
      setCsvData(response.data[0]?.data || [])
    } catch (error) {
      toast.error('Error deleting data')
      console.error(error)
    }
  }

  // Function to handle deletion of audio
  const handleDelete = async listingId => {
    try {
      await axios.delete(`/api/audio/delete/${listingId}`)
      toast.success('Audio deleted successfully')
      // Refresh listings after deletion
      const response = await axios.get(`/csv/getSinglecsv/${campaignId}`)
      setCsvData(response.data[0]?.data || [])
    } catch (error) {
      toast.error('Error deleting audio')
      console.error(error)
    }
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          {/* Updated condition to check for empty array */}
          {CsvData.length === 0 ? (
            <p>No Data found </p>
          ) : (
            <>
              <div
                className='table-container'
                style={{
                  borderRadius: '20px',
                  padding: '20px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                  marginBottom: '40px',
                  marginTop: '30px'
                }}
              >
                <h3>Lead Details</h3>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingBottom: '20px'
                  }}
                >
                  <button
                    onClick={() => handleDownload(CsvData._id)}
                    style={{
                      backgroundColor: '#55b56d', // Change color as needed
                      color: 'white', // Change color as needed
                      border: 'none',
                      padding: '8px 16px',
                      borderRadius: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    Download
                  </button>
                </div>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    width: '100%'
                  }}
                >
                  <thead>
                    <tr>
                      {(user.department === 'Superadmin'
                        ? headers2
                        : headers
                      ).map(header => (
                        <th
                          key={header}
                          style={{
                            fontSize: '13px',
                            padding: '20px 10px',
                            background: '#F5F7FB',
                            color: '#989898'
                          }}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {CsvData.slice(
                      pageNumber * campaignsPerPage,
                      (pageNumber + 1) * campaignsPerPage
                    ).map((csv, index) => (
                      <tr key={csv._id}>
                        <td>{pageNumber * campaignsPerPage + index + 1}</td>
                        <td>{csv.companyName}</td>
                        <td>{csv.customerName}</td>
                        <td>{csv.designation}</td>
                        <td>{csv.email}</td>
                        <td>{csv.mobileNumber}</td>
                        <td>{csv.city}</td>
                        <td>{csv.state}</td>
                        <td>{renderParagraph(csv.leadComments, index)}</td>
                        {user.department === 'Superadmin' && (
                          <td style={{ textAlign: 'center', padding: '0' }}>
                            <label
                              htmlFor={`audio-upload-${csv._id}`}
                              className='upload-btn'
                            >
                              <ImFolderUpload
                                size={20}
                                style={{ color: '#007bff', margin: '20px' }}
                              />
                            </label>
                            <input
                              type='file'
                              id={`audio-upload-${csv._id}`}
                              onChange={e =>
                                handleAudioUpload(csv._id, e.target.files[0])
                              }
                              style={{ display: 'none' }}
                              accept='audio/*'
                            />
                          </td>
                        )}
                        <td>
                          {csv.audio &&
                          csv.audio.audio &&
                          csv.audio.audio.filePath ? (
                            <li key={csv._id} style={{ color: 'blue' }}>
                              <audio
                                id={`audio-${csv._id}`}
                                src={`/api/audio/${csv.audio.audio.filename}.gz`}
                                type='audio/mpeg'
                              ></audio>
                              <div
                                onClick={() => togglePlay(`audio-${csv._id}`)}
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <FaCirclePlay
                                  size={25}
                                  style={{ color: '#F7A600' }}
                                />
                              </div>
                            </li>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                fontSize: '12px'
                              }}
                            >
                              <MdErrorOutline
                                size={20}
                                style={{ color: 'red' }}
                              />
                              Audio
                            </div>
                          )}
                        </td>
                        <td style={{ textAlign: 'center', padding: '0' }}>
                          {user.department === 'Superadmin' && csv.audio ? (
                            <button
                              style={{ border: 'none' }}
                              onClick={() => handleDelete(csv.audio._id)}
                            >
                              {
                                <FaTrash
                                  size={15}
                                  style={{
                                    color: 'red',
                                    margin: '8px',
                                    border: 'none'
                                  }}
                                />
                              }
                            </button>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                fontSize: '12px'
                              }}
                            >
                              <MdErrorOutline
                                size={20}
                                style={{ color: 'red' }}
                              />
                              Audio
                            </div>
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {csv.audio ? (
                            <button
                              style={{ border: 'none' }}
                              onClick={() =>
                                handleDownloadAudio(csv.audio.audio.filename)
                              }
                            >
                              <AiOutlineDownload
                                size={15}
                                style={{
                                  color: '#28a745',
                                  margin: '8px',
                                  border: 'none'
                                }}
                              />
                            </button>
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                fontSize: '12px'
                              }}
                            >
                              <MdErrorOutline
                                size={20}
                                style={{ color: 'red' }}
                              />
                              Audio
                            </div>
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              color:
                                csv.status === 'rejected' ? 'red' : 'green',
                              cursor:
                                csv.status === 'rejected'
                                  ? 'pointer'
                                  : 'default',
                              textDecoration:
                                csv.status === 'rejected'
                                  ? 'underline'
                                  : 'none',
                              textTransform: 'capitalize'
                            }}
                            title={
                              csv.status === 'rejected'
                                ? `Rejection Reason: ${csv.rejectionReason}`
                                : ''
                            }
                          >
                            {csv.status}
                          </div>
                        </td>
                        {user.department === 'Superadmin' && (
                          <td style={{ textAlign: 'center' }}>
                            <button
                              onClick={() => handleDeleteCsvData(csv._id)}
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer'
                              }}
                            >
                              <FaTrash color='red' size={20} />
                            </button>
                          </td>
                        )}
                        <td>{new Date(csv.createdAt).toLocaleDateString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '10px'
                  }}
                >
                  <div>
                    <button
                      onClick={() => changePage({ selected: pageNumber - 1 })}
                      disabled={pageNumber === 0}
                      style={{
                        color: 'black',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        border: 'none',
                        marginRight: '10px'
                      }}
                    >
                      <span>
                        <IoIosArrowRoundBack size={25} />
                      </span>
                      Previous
                    </button>
                    <button
                      onClick={() => changePage({ selected: pageNumber + 1 })}
                      disabled={pageNumber === pageCount - 1}
                      style={{
                        color: 'black',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        border: 'none'
                      }}
                    >
                      Next
                      <span>
                        <IoIosArrowRoundForward size={25} />
                      </span>
                    </button>
                  </div>
                  <div>
                    <span style={{ color: '#0A58CA' }}>
                      Page {pageNumber + 1} of {pageCount}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default AdminCsv
