import { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'

// Styled Paper component
const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  ...theme.typography.subtitle1,
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const maskData = data => {
  // Mask all characters except the first one
  return data?.length > 1 ? data[0] + '*'.repeat(data?.length - 1) : data
}

const IntentBodyUser = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_PULISHABLE_KEY)

  const [datas, setData] = useState([])
  const [paymentCheckUser, setPayementCheckUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [revealedIndex, setRevealedIndex] = useState(null)
  const user = useSelector(state => state.userReducer)

  useEffect(() => {
    fetchData()
    fetchCheckFirstPayment()
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get('/intent')
      setData(response.data?.intent_list)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const payment = async () => {
    try {
      const stripe = await stripePromise
      // Proceed with Stripe payment after successful data submission
      const paymentResponse = await axios.post(
        '/payment',
        {
          campaignData: paymentCheckUser,
          userId: paymentCheckUser._id,
          from: 'bantLead'
        },
        {
          headers: {
            Authorization: `Bearer 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`
          }
        }
      )
      const sessionId = paymentResponse.data.id
      const result = await stripe.redirectToCheckout({ sessionId })
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCheckFirstPayment = async () => {
    try {
      const response = await axios.get(`/users/${user._id}`)
      setPayementCheckUser(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleBuyLead = index => {
    if (paymentCheckUser.isFirstCampaignPaid) {
      setRevealedIndex(index)
    } else {
      payment()
    }
  }

  if (loading) return <CircularProgress />

  return (
    <Stack direction='column' spacing={3} style={{ boxSizing: 'border-box' }}>
      {datas.map((data, index) => (
        <DemoPaper key={data?.id || index} variant='elevation'>
          <Stack
            direction='row'
            spacing={2}
            style={{ width: '100%', alignItems: 'center' }}
          >
            <Stack
              spacing={1}
              alignItems='center'
              style={{ flexShrink: 0, width: '15%' }}
            >
              <img
                src='/logo192.png'
                alt='Company Logo'
                style={{ width: 60, height: 60, marginBottom: '20px' }}
              />
              <Typography variant='h6'>
                {data?.companyName || 'No Company Name'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              spacing={1}
              textAlign='left'
              justifyContent='center'
              style={{ flexShrink: 0, width: '25%', paddingLeft: 10 }}
            >
              <Typography variant='subtitle1'>
                Customer:{' '}
                {revealedIndex === index
                  ? data?.customer
                  : maskData(data?.customer) || 'Customer Name'}
              </Typography>
              <Typography variant='subtitle1'>
                Designation:{' '}
                {revealedIndex === index
                  ? data?.designation
                  : maskData(data?.designation) || 'No Designation'}
              </Typography>
              <Typography variant='subtitle1'>
                Mobile:{' '}
                {revealedIndex === index
                  ? data?.mobileNumber
                  : maskData(data?.mobileNumber) || 'No Mobile'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              spacing={1}
              alignItems='flex-start'
              justifyContent='center'
              style={{ flexShrink: 0, width: '25%', paddingLeft: 10 }}
            >
              <Typography variant='subtitle1'>
                Designation:{' '}
                {revealedIndex === index
                  ? data?.designation
                  : maskData(data?.designation) || 'No Designation'}
              </Typography>
              <Typography variant='subtitle1'>
                City:{' '}
                {revealedIndex === index
                  ? data?.city
                  : maskData(data?.city) || 'No City'}
              </Typography>
              <Typography variant='subtitle1'>
                State:{' '}
                {revealedIndex === index
                  ? data?.state
                  : maskData(data?.state) || 'No State'}
              </Typography>
              <Typography variant='subtitle1'>
                Email:{' '}
                {revealedIndex === index
                  ? data?.email
                  : maskData(data?.email) || 'No Email'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              direction='column'
              spacing={1}
              alignItems='center'
              width='100%'
            >
              <Button
                sx={{
                  width: '30%',
                  fontSize: '13px',
                  backgroundColor: '#55B56D',
                  borderRadius: '8px',
                  textTransform: 'capitalize'
                }}
                variant='contained'
                onClick={() => handleBuyLead(index)}
              >
                Buy Lead
              </Button>
              <Button
                sx={{
                  width: '30%',
                  fontSize: '13px',
                  borderRadius: '8px',
                  backgroundColor: '#0A58CA',
                  textTransform: 'capitalize'
                }}
                variant='contained'
              >
                Add to cart
              </Button>
              <Button
                sx={{
                  width: '30%',
                  textTransform: 'capitalize',
                  fontSize: '13px',
                  borderRadius: '8px',
                  color: 'black',
                  backgroundColor: '#CAE8D2'
                }}
                variant='contained'
              >
                More
              </Button>
            </Stack>
          </Stack>
        </DemoPaper>
      ))}
    </Stack>
  )
}

export default IntentBodyUser
