import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import total from '../../../../assets/images/total-campaign.png'
import complete from '../../../../assets/images/completed.png'
import pending from '../../../../assets/images/pending.png'
import hand from '../../../../assets/images/hand-wave.jpeg'
import { IoEyeSharp } from 'react-icons/io5'
import logo from '../../../../assets/images/no-campaign.jpg'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { FaHandSparkles } from 'react-icons/fa'

const MyCampaigns = () => {
  const user = useSelector(state => state.userReducer)

  const [campaigns, setCampaigns] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const [showToast, setShowToast] = useState(false)
  const campaignsPerPage = 10

  useEffect(() => {
    const fetchUserCampaigns = async () => {
      try {
        const response = await axios.get(
          `/compaign/getUserCampaigns/${user._id}`
        )
        setCampaigns(response.data)
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchUserCampaigns()
  }, [user._id])

  const handleApplyCampaign = async () => {
    try {
      const response = await axios.post('/compaign/applyforCampaign', {
        userId: user._id
      })
      setShowToast(true) // Show toast message
      toast.success('Applied for campaign successfully!')
      // Log response from the server
      // You can add further handling based on the response if needed
    } catch (error) {
      console.error('Error applying for campaign:', error.message)
      // Handle error appropriately
    }
  }

  const pageCount = Math.ceil(campaigns.length / campaignsPerPage)

  const changePage = increment => {
    setPageNumber(prevPageNumber => {
      const newPageNumber = prevPageNumber + increment
      return newPageNumber >= 0 && newPageNumber < pageCount
        ? newPageNumber
        : prevPageNumber
    })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  // Function to return CSS class based on campaign status
  const getStatusColorClass = status => {
    switch (status) {
      case 'complete':
        return { color: '#55b56d' }
      case 'live':
        return { color: '#0A58CA' }
      case 'pending':
        return { color: '#F7A600' }
      default:
        return {}
    }
  }

  // Calculate pending, completed, and total listings
  const pendingCampaigns = campaigns.filter(
    campaign => campaign.status === 'pending'
  )
  const completedCampaigns = campaigns.filter(
    campaign => campaign.status === 'complete'
  )

  const totalCampaigns = campaigns.length

  return (
    <>
      <div className='row total-campaging' style={{ padding: '20px' }}>
        <h5 style={{ paddingBottom: '5px' }}>
          Hi, {user.full_name.split(' ')[0]}
          <span style={{ paddingLeft: '10px' }}>
            <img
              src={hand}
              alt='Hand Sparkles'
              style={{ width: '1em', height: '1em', verticalAlign: 'middle' }}
            />
          </span>
        </h5>
        <div className='col-md-4'>
          <div className='total-box-top'>
            <div
              className='total-box-camp'
              style={{ backgroundColor: '#F7A600', height: '12rem' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <h3>{totalCampaigns === 0 ? '00' : totalCampaigns}</h3>
                <div>
                  <img src={total} alt='' style={{ width: '40px' }} />
                </div>
              </div>
              <h6 style={{ marginTop: '3rem' }}>Total Campaign</h6>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='total-box-top'>
            <div
              className='total-box-camp total-box-camp1'
              style={{ backgroundColor: '#0A58CA', height: '12rem' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <h3>
                  {completedCampaigns.length === 0
                    ? '00'
                    : completedCampaigns.length}
                </h3>
                <div>
                  <img src={complete} alt='' style={{ width: '40px' }} />
                </div>
              </div>
              <h6 style={{ marginTop: '3rem' }}>Completed</h6>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='total-box-top'>
            <div
              className='total-box-camp total-box-camp1'
              style={{ backgroundColor: '#55B56D', height: '12rem' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <h3>
                  {pendingCampaigns.length === 0
                    ? '00'
                    : pendingCampaigns.length}
                </h3>
                <div>
                  <img src={pending} alt='' style={{ width: '40px' }} />
                </div>
              </div>
              <h6 style={{ marginTop: '3rem' }}>Pending</h6>
            </div>
          </div>
        </div>
      </div>

      {campaigns.length === 0 ? (
        <>
          <div
            className=''
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: '1rem'
            }}
          >
            <h3>Campaigns</h3>
            {!showToast && user.appyForcampaigs === false ? (
              <button
                onClick={handleApplyCampaign}
                style={{
                  backgroundColor: '#55B56D',
                  color: 'white',
                  padding: '12px',
                  borderRadius: '10px',
                  border: 'none'
                }}
              >
                Apply for Campaign
              </button>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <img
              src={logo}
              alt=''
              style={{ width: '200px', padding: '10px 0' }}
            />
            <div
              style={{ color: '#C68500', fontWeight: '400', fontSize: '16px' }}
            >
              No campaigns found please Apply for Campaign
            </div>
          </div>
        </>
      ) : (
        <>
          {/* campaign table start */}
          <div
            className='table-container'
            style={{
              borderRadius: '20px',
              padding: '20px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
              marginBottom: '40px',
              marginTop: '30px',
              overflowX: 'auto' // Enable horizontal scrolling
            }}
          >
            <h4 style={{ paddingBottom: '10px' }}>Campaigns</h4>
            <table
              style={{
                borderCollapse: 'collapse',
                borderRadius: '10px',
                overflow: 'hidden',
                width: '100%'
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Campaign Name
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Marketer
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Media Partner
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Submitted
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Approved
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Rejected
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Pending
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Target
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    Start Date
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 10px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    End Date
                  </th>
                  <th
                    style={{
                      fontSize: '13px',
                      padding: '20px 0px',
                      paddingRight: '5px',
                      background: '#F5F7FB',
                      color: '#989898'
                    }}
                  >
                    See Listing
                  </th>
                </tr>
              </thead>
              <tbody>
                {campaigns
                  .slice(
                    pageNumber * campaignsPerPage,
                    (pageNumber + 1) * campaignsPerPage
                  )
                  .map((campaign, index) => (
                    <tr key={campaign._id}>
                      <td>{pageNumber * campaignsPerPage + index + 1}</td>
                      <td
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: 'flex',
                          alignItems: 'start'
                        }}
                      >
                        {/* <Link to={campaign._id}> */}
                        <Link to={`/user_dashboard/csv/${campaign._id}`}>
                          {campaign.campaignName}
                        </Link>
                      </td>
                      <td style={getStatusColorClass(campaign.status)}>
                        <span
                          style={{
                            display: 'flex',
                            width: '75px',
                            background: '#F6F6F6',
                            padding: '8px 8px',
                            borderRadius: '8px',
                            fontSize: '12px',
                            textTransform: 'capitalize',
                            textAlign: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {campaign.status}
                        </span>
                      </td>
                      <td>{campaign.marketer}</td>
                      <td>{campaign.mediaPartner}</td>
                      <td>
                        {/* <Link to={`/user_dashboard/csv/${campaign._id}`}> */}
                        {campaign?.csv[0]?.data?.length ?? 0}
                        {/* </Link> */}
                      </td>
                      <td>
                        {campaign?.csv[0]?.data?.filter(
                          item => item.status === 'approved'
                        ).length ?? 0}
                      </td>
                      <td>
                        {campaign?.csv[0]?.data?.filter(
                          item => item.status === 'rejected'
                        ).length ?? 0}
                      </td>
                      <td>
                        {campaign?.csv[0]?.data?.filter(
                          item => item.status === 'in review'
                        ).length ?? 0}
                      </td>
                      <td>{campaign.submitted ?? 0}</td>
                      <td
                        style={{ padding: '10px', textTransform: 'capitalize' }}
                      >
                        {new Date(campaign.startDate)
                          .toLocaleDateString()
                          .replace(/\//g, '-')}
                      </td>
                      <td
                        style={{ padding: '10px', textTransform: 'capitalize' }}
                      >
                        {new Date(campaign.endDate)
                          .toLocaleDateString()
                          .replace(/\//g, '-')}
                      </td>
                      <td
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Link to={`/user_dashboard/mylisting/${campaign._id}`}>
                          <IoEyeSharp size={25} style={{ color: '#0A58CA' }} />
                        </Link>
                        <Link to={campaign._id}>
                          <IoIosInformationCircleOutline
                            size={25}
                            style={{ color: '#0A58CA' }}
                          />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <div>
                <button
                  onClick={() => changePage(-1)}
                  disabled={pageNumber === 0}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'F2F2F2',
                    marginRight: '10px'
                  }}
                >
                  <span>
                    {' '}
                    <IoIosArrowRoundBack size={25} />
                  </span>
                  Previous
                </button>
                <button
                  onClick={() => changePage(1)}
                  disabled={pageNumber === pageCount - 1}
                  style={{
                    color: 'black',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    border: 'F2F2F2'
                  }}
                >
                  {' '}
                  Next
                  <span>
                    <IoIosArrowRoundForward size={25} />
                  </span>
                </button>
              </div>
              <div>
                <span style={{ color: '#0A58CA' }}>
                  Page {pageNumber + 1} of {pageCount}
                </span>
              </div>
            </div>
          </div>
          {/* campaign table end */}
        </>
      )}
    </>
  )
}

export default MyCampaigns
