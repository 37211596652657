import React, { useEffect } from "react";
import "./UserDashboard.css";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useUpdateUser } from "../../utils/useUpdateUser";

const UserDashboard = ({ funcNav, funcFooter }) => {
  const handleUserUpdate = useUpdateUser();

  useEffect(() => {
    // This effect runs after the initial render
    funcNav(true);
    funcFooter(true);
    handleUserUpdate();
  }, [funcNav, funcFooter, handleUserUpdate]);

  return (
    <>
      <section className="profile">
        <div className="container-fluid">
          <div className="row" >
            <div className="col-md-12"style={{padding:"0"}} >
              <header className="d-flex">
                {/* Sidebar  */}
                <Sidebar />
                {/* Right Side  */}
                <div className="rightside" >
                  <Outlet />
                </div>
              </header>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDashboard;
