import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import './UserCampign.css'
import { toast } from 'react-toastify'

import { AiOutlineDownload } from 'react-icons/ai'
import { TbFileTypeCsv } from 'react-icons/tb'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { IoIosArrowRoundBack } from 'react-icons/io'
import Header from './Header'
import { MdDelete } from 'react-icons/md'
import { SlPencil } from 'react-icons/sl'
import { FaPlus } from 'react-icons/fa6'
import { IoEyeSharp } from 'react-icons/io5'
const UserCampign = () => {
  const location = useLocation()
  const userId = location.pathname.split('/').pop()
  const [showPopup, setShowPopup] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const campaignsPerPage = 10

  const handleFileChange = (event, campaignId) => {
    const selectedFile = event.target.files[0]
    // Check if selected file exists and is of type CSV
    if (selectedFile && selectedFile.type === 'text/csv') {
      handleUpload(selectedFile, campaignId)
      // Reset input value after successful upload
      event.target.value = null
      toast.info('File selected: ' + selectedFile.name)
    } else {
      // Reset input value if file type is incorrect
      event.target.value = null
      toast.error('Please select a CSV file.')
    }
  }

  const handleUpload = (file, campaignId) => {
    const formData = new FormData()
    formData.append('csv', file)
    formData.append('campaignId', campaignId) // Include campaignId in FormData

    axios
      .post('/csv/upload', formData)
      .then(response => {
        // Handle successful upload
        console.log(response.data)
        toast.success('File uploaded successfully')
      })
      .catch(error => {
        // Handle upload error
        console.error(error)
        toast.error('Error in uploaded file')
      })
  }
  const handleDownload = campaignId => {
    axios
      .post('/csv/download', { campaignId }, { responseType: 'blob' }) // Include campaignId in request body
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'data.csv')
        document.body.appendChild(link)
        link.click()
        toast.success('File downloaded successfully')
      })
      .catch(error => {
        console.error('Error downloading data:', error)
        toast.info('404 CSV file not found')
        toast.error('Error downloading file')
      })
  }

  useEffect(() => {
    const fetchUserCampaigns = async () => {
      try {
        const response = await axios.get(`/compaign/getUserCampaigns/${userId}`)
        setCampaigns(response.data)
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchUserCampaigns()
  }, [userId])

  const urlParams = new URLSearchParams(window.location.search)
  const currentPage = urlParams.get('currentPage') || 1 // Default to page 1 if currentPage parameter is not present

  const handleDelete = async campaignId => {
    console.log('campaignId', campaignId)
    try {
      await axios.delete(`/compaign/deleteCampaign/${campaignId}`)
      // Display a success toast notification
      toast.success('Campaign deleted successfully')
      // Close the pop-up after successful deletion
      setShowPopup(false)
      // Reload the page with the current page information
      window.location.reload()
      // window.location.href = `/current-page?currentPage=${currentPage}`;
    } catch (error) {
      console.error('Error deleting campaign:', error)
      // Handle error appropriately
    }
  }

  const pageCount = Math.ceil(campaigns.length / campaignsPerPage)

  const handleSearch = query => {
    setSearchQuery(query)
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  // Function to return CSS class based on campaign status
  const getStatusColorClass = status => {
    switch (status) {
      case 'complete':
        return { color: '#55b56d' }
      case 'live':
        return { color: '#0A58CA' }
      case 'pending':
        return { color: '#F7A600' }
      default:
        return {}
    }
  }

  const changePage = increment => {
    setPageNumber(prevPageNumber => {
      const newPageNumber = prevPageNumber + increment
      return newPageNumber >= 0 && newPageNumber < pageCount
        ? newPageNumber
        : prevPageNumber
    })
  }

  return (
    <>
      <div class='card'>
        <div className='table-container'>
          {campaigns.length === 0 ? (
            <>
              <Header />
              <h4>No campaigns found for this user.</h4>
              <div className='row pt-3'>
                <div className='col-md-12'>
                  <div className='source-box'>
                    <div className='source-top d-flex'>
                      <h3>Add Campaigns</h3>
                      <Link
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '10px',
                          borderRadius: '10px',
                          background: '#0A58CA',
                          color: '#fff'
                        }}
                        to={'new'}
                      >
                        <FaPlus size={20} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className='table-container'
                style={{
                  borderRadius: '20px',
                  padding: '20px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                  marginBottom: '40px',
                  marginTop: '30px'
                }}
              >
                <Header />

                <div className='row pt-3'>
                  <div className='col-md-12'>
                    <div className='source-box'>
                      <div className='source-top d-flex'>
                        <h3>Add Campaigns</h3>
                        <Link
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px',
                            borderRadius: '10px',
                            background: '#0A58CA',
                            color: '#fff'
                          }}
                          to={'new'}
                        >
                          <FaPlus size={20} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <table
                  style={{
                    borderCollapse: 'collapse',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    width: '100%'
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Campaign Name
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Marketer
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Media Partner
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Submitted
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Approved
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Rejected
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Pending
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Target
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Start Date
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        End Date
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        Action
                      </th>

                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 0px',
                          paddingRight: '5px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        See Listing
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        csv file
                      </th>
                      <th
                        style={{
                          fontSize: '13px',
                          padding: '20px 10px',
                          background: '#F5F7FB',
                          color: '#989898'
                        }}
                      >
                        D/L
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns
                      .slice(
                        pageNumber * campaignsPerPage,
                        (pageNumber + 1) * campaignsPerPage
                      )
                      .map((campaign, index) => (
                        <tr key={campaign._id}>
                          <td>{pageNumber * campaignsPerPage + index + 1}</td>
                          <td>
                            <Link
                              to={`/admin_panel/userCampaign/${campaign._id}`}
                            >
                              {campaign.campaignName}
                            </Link>
                          </td>
                          <td style={getStatusColorClass(campaign.status)}>
                            <span
                              style={{
                                display: 'flex',
                                width: '75px',
                                background: '#F6F6F6',
                                padding: '8px 8px',
                                borderRadius: '8px',
                                fontSize: '12px',
                                textTransform: 'capitalize',
                                textAlign: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              {campaign.status}
                            </span>
                          </td>
                          <td>{campaign.marketer}</td>
                          <td>{campaign.mediaPartner}</td>
                          <td>
                            <Link to={`/admin_panel/adminCsv/${campaign._id}`}>
                              {campaign?.csv[0]?.data?.length ?? 0}
                            </Link>
                          </td>
                          <td>
                            {campaign?.csv[0]?.data?.filter(
                              item => item.status === 'approved'
                            ).length ?? 0}
                          </td>
                          <td>
                            {campaign?.csv[0]?.data?.filter(
                              item => item.status === 'rejected'
                            ).length ?? 0}
                          </td>
                          <td>
                            {campaign?.csv[0]?.data?.filter(
                              item => item.status === 'in review'
                            ).length ?? 0}
                          </td>
                          <td>{campaign.submitted ?? 0}</td>
                          <td>
                            {new Date(campaign.startDate)
                              .toLocaleDateString()
                              .replace(/\//g, '-')}
                          </td>
                          <td>
                            {new Date(campaign.endDate)
                              .toLocaleDateString()
                              .replace(/\//g, '-')}
                          </td>
                          <td className='button-container'>
                            <div>
                              <Link to={campaign._id}>
                                <SlPencil
                                  style={{ color: '#0059E3' }}
                                  size={20}
                                />
                              </Link>
                            </div>
                            <div className='button-space'></div>{' '}
                            <div onClick={() => setShowPopup(campaign._id)}>
                              <MdDelete
                                style={{ color: '#EE0007', cursor: 'pointer' }}
                                size={25}
                              />
                            </div>
                            {showPopup === campaign._id && (
                              <div className='custom-popup'>
                                <h2>Confirm Delete</h2>
                                <p>
                                  Are you sure you want to delete this campaign?
                                </p>
                                <div className='custom-popup-buttons'>
                                  <button
                                    onClick={() => setShowPopup(false)}
                                    className='cancel-btn'
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => handleDelete(campaign._id)}
                                    className='delete-btn'
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                          </td>
                          <td>
                            <Link to={`/admin_panel/listing/${campaign._id}`}>
                              <IoEyeSharp
                                size={25}
                                style={{ color: '#0A58CA' }}
                              />
                            </Link>
                          </td>
                          <td>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <label
                                htmlFor={`fileInput_${campaign._id}`}
                                style={{ cursor: 'pointer' }}
                              >
                                <TbFileTypeCsv
                                  style={{
                                    fontSize: '20px',
                                    color: '#4CAF50'
                                  }}
                                />
                              </label>
                              <input
                                id={`fileInput_${campaign._id}`}
                                type='file'
                                onChange={event =>
                                  handleFileChange(event, campaign._id)
                                }
                                style={{ display: 'none' }}
                              />
                            </div>
                          </td>
                          <td>
                            <AiOutlineDownload
                              style={{
                                fontSize: '20px',
                                color: '#007bff',
                                cursor: 'pointer'
                              }}
                              onClick={() => handleDownload(campaign._id)}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '10px'
                  }}
                >
                  <div>
                    <button
                      onClick={() => changePage(-1)}
                      disabled={pageNumber === 0}
                      style={{
                        color: 'black',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        border: 'F2F2F2',
                        marginRight: '10px'
                      }}
                    >
                      <span>
                        {' '}
                        <IoIosArrowRoundBack size={25} />
                      </span>
                      Previous
                    </button>
                    <button
                      onClick={() => changePage(1)}
                      disabled={pageNumber === pageCount - 1}
                      style={{
                        color: 'black',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        border: 'F2F2F2'
                      }}
                    >
                      {' '}
                      Next
                      <span>
                        <IoIosArrowRoundForward size={25} />
                      </span>
                    </button>
                  </div>
                  <div>
                    <span style={{ color: '#0A58CA' }}>
                      Page {pageNumber + 1} of {pageCount}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default UserCampign
