import React from 'react'
import { Link } from 'react-router-dom'
import Table from '../../common/table'
import Delete from '../../common/delete'
import Status from './../../common/status'

const UserTable = ({ items, onDelete, onStatus }) => {
  const columns = [
    {
      key: 'sno', // Set a unique key value
      label: 'S.No',
      content: item => <p>{items.indexOf(item) + 1}</p>
    },
    {
      path: 'full_name',
      label: 'Name'
    },
    {
      path: 'company_name',
      label: 'Company Name'
    },
    {
      path: 'email',
      label: 'Email'
    },
    {
      path: 'mobile',
      label: 'Mobile'
    },
    {
      path: 'status',
      label: 'Mark as Paid',
      content: item => (
        <>
          <Link
            to='#'
            className='btn btn-sm btn-primary'
            onClick={() => onStatus({ _id: item._id, status: 'Paid' })}
          >
            {item.status}
          </Link>
        </>
      )
    },
    {
      path: 'created_date',
      label: 'Created Date',
      content: item => <>{new Date(item.created_date).toDateString()}</>
    },
    {
      key: 'Action',
      label: 'Action',
      content: item => (
        <>
          {/* <Link className='btn-veiw' to={item._id}>
            <i className='fa fa-pencil' aria-hidden='true'></i>
          </Link> */}
          <Delete onClick={() => onDelete(item)} />
        </>
      )
    }
  ]

  return <Table data={items} columns={columns} />
}

export default UserTable
