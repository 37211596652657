export { default as Admin } from './Admin/Admin'
export { default as Error } from './Error/Error'
export { default as Home } from './Home/Home'
export { default as News } from './News/News'
export { default as Signin } from './Signin/Signin'
export { default as Signup } from './Signup/Signup'
export { default as Welcome } from './Welcome/Welcome'
export { default as ProfileSummary } from './ProfileSummary/ProfileSummary'
export { default as ProfileSummaryUpdate } from './ProfileSummary/ProfileSummaryUpdate'
export { default as AboutUs } from './AboutUs/AboutUs'
export { default as TermsCondition } from './TermsCondition/TermsCondition'
export { default as Privacy } from './Privacy/Privacy'
export { default as Blog } from './Blog/Blog'
export { default as ForgotPassword } from './ForgotPassword/ForgotPassword'
export { default as ResetPassword } from './ResetPassword/ResetPassword'
export { default as ContactUs } from './ContactUs/ContactUs'
export { default as LeadRejectionPolicy } from './LeadRejectionPolicy/LeadRejectionPolicy'
export { default as WhoAreWe } from './WhoAreWe/WhoAreWe'
