import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { setUser } from '../../../../actions'
import auth from '../../../../services/authServices'
import './Panel.css'
import {
  deleteMIntentApply,
  getUserMIntentApply,
  getIntentMIntentApply,
  statusMIntentApply
} from '../../../../services/mintentApplyService'
import FunnelGraph from 'funnel-graph-js'

const Panel = () => {
  const user = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const location = useLocation()
  const [items, setItems] = useState([])
  const graphRef = useRef(null)

  const fetchData = async () => {
    try {
      let response
      if (user.department === 'Superadmin') {
        response = await getIntentMIntentApply(location.state.intent_id)
      } else {
        response = location.state
          ? await getIntentMIntentApply(location.state.intent_id)
          : await getUserMIntentApply(user._id)
      }
      setItems(response.data.intent_list)
    } catch (error) {
      console.error('Error fetching intent list:', error)
    }
  }

  useEffect(() => {
    if (location) fetchData()
  }, [location])

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const currentUser = await auth.getCurrentUser()
      dispatch(setUser(currentUser))
    }
    fetchCurrentUser()
  }, [dispatch])
  useEffect(() => {
    if (graphRef.current) {
      // Set dimensions and styling for the container
      graphRef.current.style.width = '60%' // Full width
      graphRef.current.style.height = '500px' // Adjust height for better visibility
      graphRef.current.style.padding = '20px' // Add padding around the graph
      graphRef.current.style.boxSizing = 'border-box' // Ensure padding is included in width/height
      graphRef.current.style.marginBottom = '40px' // Ensure padding is included in width/height

      // Define the data for the graph
      const data = {
        labels: ['Controlled', 'Treated', 'Aware', 'Screened', 'Hypertension'],
        // subLabels: ['Female', 'Male'],
        colors: [
          ['red', 'red'],
          ['green', 'green'],
          ['red', 'red']
        ],
        values: [
          [5000, 5000], // Wide bottom
          [3000, 3000], // Widening stage
          [1000, 1000], // Narrowest point
          [3000, 3000], // Narrowing stage
          [5000, 5000] // Wide top
        ]
      }

      // Initialize the FunnelGraph with the updated data and styling
      const graph = new FunnelGraph({
        container: '.funnel',
        gradientDirection: 'vertical', // For vertical orientation
        data: data,
        displayPercent: true,
        direction: 'vertical', // Set direction to vertical
        width: graphRef.current.offsetWidth, // Use container width
        height: graphRef.current.offsetHeight, // Use container height
        subLabelValue: 'values',
        label: false, // Hide labels for a cleaner look
        padding: 10, // Add padding between funnel stages
        // borderWidth: 1, // Add border width
        borderColor: '#ddd' // Light border color for separation
      })

      // Draw the graph
      graph.draw()
    }
  }, [items])

  if (user && !user.isProfileSet)
    return <Navigate to={`/profilesummary`} replace />

  const SummaryInfo = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 20px',
        color: '#fff'
      }}
    >
      {[
        { title: 'End Date:', value: '31 July 2024', bgColor: '#F7A600' },
        {
          title: 'Lead Delivered/Lead Goal',
          value: '280 / 500',
          bgColor: '#0A58CA'
        },
        { title: 'Lead Generated', value: '60%', bgColor: '#55B56D' }
      ].map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: item.bgColor,
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>{item.title}</div>
          <h6>{item.value}</h6>
        </div>
      ))}
    </div>
  )

  const FunnelVisualization = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
        position: 'relative'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          height: '350px'
        }}
      >
        <div style={{ position: 'relative' }}>
          <h6>Total Lead</h6>
          <div
            style={{
              height: '2px',
              background: '#24F2FF',
              width: '230%',
              position: 'absolute',
              top: '50%',
              zIndex: 1
            }}
          ></div>
          <h6 style={{ paddingTop: '2px' }}>LeadXchange</h6>
        </div>
        <div style={{ position: 'relative' }}>
          <h6>Current Leads</h6>
          <div
            style={{
              height: '2px',
              background: '#86F000',
              width: '285%',
              position: 'absolute',
              top: '100%',
              zIndex: 1
            }}
          ></div>
        </div>
        <div style={{ position: 'relative' }}>
          <h6>Targeted Leads</h6>
          <div
            style={{
              height: '2px',
              background: '#F5BB00',
              width: '330%',
              position: 'absolute',
              top: '100%',
              zIndex: 1
            }}
          ></div>
        </div>
      </div>
      <div className='funnel'>
        <img src='hourglass.png' />
      </div>
    </div>
  )

  const FunnelMetrics = () => (
    <div
      style={{
        background: '#55B56D',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        marginBottom: '100px',
        marginRight: '10px',
        justifyContent: 'center',
        borderRadius: '10px',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        color: '#fff'
      }}
    >
      {[
        { label: 'ToFu', value: '35%', color: '#24F2FF' },
        { label: 'MoFu', value: '25%', color: '#86F000' },
        { label: 'BoFu', value: '30%', color: '#F7A600' }
      ].map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>{item.label}</h6>
          <div
            style={{
              height: '2px',
              background: item.color,
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: item.color }}>{item.value}</h6>
        </div>
      ))}
    </div>
  )

  const FilterOptions = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '20%',
        background:
          'linear-gradient(to bottom,rgb(0, 57, 89), rgb(5, 88, 135))',
        padding: '20px',
        color: 'white'
      }}
    >
      {[
        { label: 'Assets', defaultChecked: true },
        { label: 'Intent Data' },
        { label: 'Industries', defaultChecked: true },
        { label: 'Revenue' },
        { label: 'Company Size', defaultChecked: true },
        { label: 'Customer Questions' },
        { label: 'Install Base' }
      ].map((option, index) => (
        <label
          key={index}
          style={{
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <input
            type='checkbox'
            defaultChecked={option.defaultChecked}
            style={{
              marginRight: '10px',
              width: '20px',
              height: '20px',
              background: '#F7A600',
              borderRadius: '4px',
              accentColor: 'green'
            }}
          />{' '}
          {option.label}
        </label>
      ))}
      <div style={{ marginTop: '20px' }}>
        {[
          'Targeted Display',
          'Email Newsletter Sponsorship',
          'LinkedIn, Twitter, and Sponsored Ad'
        ].map((text, index) => (
          <div key={index} style={{ display: 'block', marginBottom: '10px' }}>
            {text}
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          background:
            'linear-gradient(to bottom, rgb(5, 88, 135), rgb(0, 57, 89))'
        }}
      >
        <SummaryInfo />
        <FunnelVisualization />
        <FunnelMetrics />
      </div>
      <FilterOptions />
    </div>
  )
}

export default Panel
