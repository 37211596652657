import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getMenuItemBasedOnRole } from './MenuItem'
import { useSelector } from 'react-redux'

function BottomNavbar ({ showNavbar, setShowNavbar }) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [computedMenuItems, setComputedMenuItems] = useState([])
  const location = useLocation()
  const user = useSelector(state => state.userReducer)
  const extractLastPart = inputString => {
    const parts = inputString.split('/')
    return parts[parts.length - 1]
  }

  useEffect(() => {
    if (location) {
      let extracted = extractLastPart(location.pathname)
      const currentPageIndex = computedMenuItems.findIndex(
        item => item.to === extracted
      )
      if (currentPageIndex !== -1) {
        setSelectedIndex(currentPageIndex)
      }
    }
  }, [location])

  useEffect(() => {
    const menuItemsRole = getMenuItemBasedOnRole(user.department) // change the role here guys
    setComputedMenuItems(menuItemsRole)
  }, [user])

  return (
    <>
      <div className={`l-navbar ${showNavbar ? `show` : ''} `}>
        <div
          className='header_toggle'
          onClick={() => setShowNavbar(!showNavbar)}
        >
          {' '}
          {!showNavbar ? (
            <i class='bx bx-menu' id='header-toggle'></i>
          ) : (
            <i className='fa fa-close' id='header-toggle'></i>
          )}{' '}
        </div>
        <nav className='nav'>
          <div>
            <div className='nav_list'>
              {computedMenuItems.map((link, index) => (
                <Link
                  to={link.to}
                  className={`nav_link ${
                    selectedIndex === index ? `active` : ''
                  } `}
                  key={index}
                  onClick={() => setSelectedIndex(index)}
                >
                  <i className={link.icon}></i>
                  <span className='nav_name'>{link.name}</span>
                </Link>
              ))}{' '}
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default BottomNavbar
