import React, { useState, useEffect } from 'react'
import Joi, { process } from 'joi-browser'
import Form from '../../common/form'
import auth from '../../services/authServices'
import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom'
import logo from '../../assets/images/singuplogo.png'
import { useSelector, useDispatch } from 'react-redux'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { toast } from 'react-toastify'
import { setUser } from '../../actions/userActions.jsx'
import { GoogleLogin } from '@react-oauth/google'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

function SigninForm () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialData = { email: '', password: '' }
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const [value, setValue] = useState('one') // Default to 'one' for Buyer login

  const location = useLocation()
  const user = useSelector(state => state.userReducer)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const doSubmit = async () => {
    try {
      const endpoint =
        value === 'one'
          ? '/auth/user_auth_signin' // Endpoint for Buyer login
          : '/auth/seller_auth_signin' // Endpoint for Seller login

      await auth.login(endpoint, data, 'email', 'password')
      let redirectUrl = process.env.REACT_APP_HOMEPAGE
      let path = location?.state
        ? `${redirectUrl}${location?.state?.history}`
        : `${redirectUrl}/user_dashboard`

      window.location.href = path
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const newErrors = { ...errors }
        newErrors.username = ex.response.data
        setErrors(newErrors)
      }
    }
  }

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    password: Joi.string().required().min(5).label('Password')
  }

  const { renderInput, renderButton, handleSubmit } = Form({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit
  })

  useEffect(() => {
    const buttonElement = document.querySelector('.sinfrnw .btn')
    if (buttonElement) {
      buttonElement.classList.add('btn-primary')
      buttonElement.classList.add('btre')
    }
  }, [])

  const handleGoogleLogin = async credentialResponse => {
    try {
      const decodedToken = jwtDecode(credentialResponse.credential)
      console.log('Decoded Google Token:', decodedToken)

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/userroles/createGoogleUser`,
        {
          name: decodedToken.name,
          email: decodedToken.email,
          picture: decodedToken.picture
        }
      )

      if (response.status === 200) {
        dispatch(setUser(response.data.user))
        sessionStorage.setItem('user', JSON.stringify(response.data.user))
        navigate('/user_dashboard')
      } else {
        throw new Error('Failed to create or fetch user')
      }
    } catch (error) {
      console.error('Error during Google login process:', error)
      toast.error('An error occurred during Google login.')
    }
  }

  const handleGoogleError = () => {
    console.log('Google Login Failed')
  }

  if (user && user.department === 'User')
    return <Navigate to={`/user_dashboard`} replace />
  if (user && user.department === 'SuperAdmin')
    return <Navigate to={`/user_dashboard`} replace />

  return (
    <>
      <form
        className='sinfrnw'
        onSubmit={handleSubmit}
        style={{
          borderRadius: '16px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
          padding: '20px'
        }}
      >
        <div>
          <Link
            to='/'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={logo} style={{ width: '30%' }} />
          </Link>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          // textColor='secondary'
          // indicatorColor='secondary'
          aria-label='secondary tabs example'
        >
          <Tab value='one' label='Buyer Login' />
          <Tab value='two' label='Seller Login' />
        </Tabs>

        {/* Render form inputs */}
        {renderInput('email', 'Email', 'text', 'example@example.com')}
        {renderInput('password', 'Password', 'password', 'Enter your password')}
        <Link className='sign-frw' to='/forgotpassword'>
          Forgot your password?
        </Link>

        {renderButton('login')}

        {/* Additional elements */}
        <div style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
          <span style={{ margin: '0 10px', whiteSpace: 'nowrap' }}>or</span>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
        </div>

        {/* Google login */}
        <div style={{ width: '100%' }}>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={handleGoogleError}
          />
        </div>

        <h6 style={{ margin: '15px 0' }}>
          Don't have a LeadXchange account?{' '}
          <Link to='/signup' style={{ fontWeight: '600' }}>
            Signup
          </Link>
        </h6>

        <span className='prvtrm'>
          <Link to='/termscondition'>Terms & Condition</Link>
          &nbsp;&amp;&nbsp;
          <Link to='/privacy'>Privacy Policy</Link>
        </span>
      </form>
    </>
  )
}

export default SigninForm
