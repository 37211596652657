import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth from "../../services/authServices";
import { setUser } from "../../actions/";

const SENDOTP_API = `${process.env.REACT_APP_API_URL}/auth/sendotp`;
const SIGNUP_API = `${process.env.REACT_APP_API_URL}/auth/user_auth_signup`;

export const sendOtp = async (email, navigate) => {
  try {
    // Show loading toast
    toast.info("Sending OTP...");

    const response = await axios.post(SENDOTP_API, {
      email,
      checkUserPresent: true,
    });

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    // Remove loading toast upon success
    toast.dismiss(); // Dismiss loading toast
    toast.success("OTP Sent Successfully");

    // Set OTP in sessionStorage instead of sessionStorage
    sessionStorage.setItem("otp", response.data.otp);

    navigate("/verify-email");

    return response.data; // Return the response data
  } catch (error) {
    console.log("SENDOTP API ERROR:", error);

    // Remove loading toast upon error
    toast.dismiss(); // Dismiss loading toast
    toast.error("Could Not Send OTP");
    toast.info("User is Already Registered");

    throw error; // Re-throw the error
  }
};

export const signUp = async (email, password, otp, navigate, dispatch) => {
  const toastId = toast.loading("Loading...");

  try {
    const response = await axios.post(SIGNUP_API, {
      email,
      password,
      otp,
    });

    sessionStorage.setItem("user", JSON.stringify(response.data.user));
    auth.loginWithJwt(response.data.usertoken);
    dispatch(setUser(response.data.user));

    if (!response.data.success) {
      throw new Error(response.data.message);
    }

    toast.success("Signup Successful");
    window.location.href = "/profilesummary";
  } catch (error) {
    console.log("SIGNUP API ERROR:", error);
    toast.error("Signup Failed");
    navigate("/signup");
  }

  toast.dismiss(toastId);
};
