import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import { PopupButton } from 'react-calendly'
import logo from '../../assets/images/logo.png'
import { useSelector } from 'react-redux'
const Navbar = () => {
  const user = useSelector(state => state.userReducer)
  const Style = {
    padding: '20px'
  }
  return (
    <>
      <header className='maine-header' id='sticky-top'>
        <nav className='navbar navbar-expand-lg lead-menu'>
          <div className='container-fluid'>
            <Link to='/' className='navbar-brand'>
              <img
                src={logo}
                style={{ width: '400px' }}
                class='img-fluid'
                alt=''
              />
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav me-auto mb-2 mb-lg-0 justify-content-center'>
                <li className='nav-item'>
                  <Link to='/' className='nav-link active' aria-current='page'>
                    Home
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <div
                    className='nav-link dropdown-toggle'
                    id='navbarDropdown'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    What we do
                  </div>
                  <ul
                    className='dropdown-menu'
                    aria-labelledby='navbarDropdown'
                  >
                    <li>
                      <Link to='/abm-services' className='dropdown-item'>
                        ABM Services
                      </Link>
                    </li>
                    <li>
                      <Link to='/content-marketing' className='dropdown-item'>
                        Content Marketing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/database-and-analytics'
                        className='dropdown-item'
                      >
                        Database And Analytics
                      </Link>
                    </li>
                    <li>
                      <Link to='/demand-program' className='dropdown-item'>
                        Demand Program
                      </Link>
                    </li>
                    <li>
                      <Link to='/salesEnablement' className='dropdown-item'>
                        Sales Enablement
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className='nav-item'>
                  <Link to='/who-are-we' className='nav-link'>
                    Who are we
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/signin' className='nav-link'>
                    Customers{' '}
                  </Link>
                </li>
                <li className='nav-item'>
                  <a
                    href={'https://cxotv.techplusmedia.com/'}
                    className='nav-link'
                  >
                    News
                  </a>
                </li>
                <li className='nav-item'>
                  <Link to='/contactus' className='nav-link'>
                    Contact
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/pricing' className='nav-link'>
                    Pricing
                  </Link>
                </li>
                <li className='nav-item'>
                  <PopupButton
                    url='https://calendly.com/leadxchangeai'
                    rootElement={document.getElementById('root')}
                    text='Book a demo &nbsp; &nbsp; &gt;'
                    className='btn-demo'
                  />
                </li>
              </ul>

              <div className='d-flex' role='search'>
                {!user && (
                  <>
                    <Link to='/signin'>
                      <button className='btn btn-sign' type='submit'>
                        Login
                      </button>
                    </Link>
                  </>
                )}

                {user && (
                  <>
                    <Link className='btn btn-sign' to='/logout'>
                      Logout
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Navbar
