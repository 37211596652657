import React from 'react'
import { MenuItems } from './MenuItem'
import { NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import jobProfile from '../../assets/images/images/jop-profile.png'
import { IoLogOutOutline } from 'react-icons/io5'
import { MdHelpOutline } from 'react-icons/md'

const Sidebar = () => {
  const user = useSelector(state => state.userReducer)

  const location = useLocation()

  return (
    <div
      style={{
        width: '20%',
        background: '#0A58CA',
        color: 'white',
        padding: '40px 10px'
      }}
    >
      <div>
        {MenuItems.map((menuItem, index) => (
          <div key={index} style={{ padding: '2px 1px' }}>
            <NavLink
              to={menuItem.key}
              style={({ isActive }) => ({
                background: isActive ? '#3B79D5' : 'transparent',
                color: 'white',
                padding: '16px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none'
              })}
            >
              <span className='me-2'>{menuItem.icon}</span>
              <span>{menuItem.text}</span>
            </NavLink>
          </div>
        ))}
      </div>
      <div className='mt-4'>
        <div>
          <p style={{ fontSize: '12px' }}>Account</p>
        </div>

        <div style={{ padding: '2px 0' }}>
          <NavLink
            to='/contactus'
            style={({ isActive }) => ({
              background: isActive ? '#3B79D5' : 'transparent',
              color: 'white',
              padding: '10px',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none'
            })}
          >
            <span className='me-2' style={{ fontSize: '24px' }}>
              <MdHelpOutline />
            </span>
            <span>Help center</span>
          </NavLink>
        </div>
        <div style={{ padding: '2px 0' }}>
          <NavLink
            to='/logout'
            style={({ isActive }) => ({
              background: isActive ? '#3B79D5' : 'transparent',
              color: 'white',
              padding: '10px',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none'
            })}
          >
            <span className='me-2' style={{ fontSize: '24px' }}>
              <IoLogOutOutline />
            </span>
            <span>Signout</span>
          </NavLink>
        </div>
        <div style={{ padding: '2px 0' }}>
          <NavLink
            to='/profilesummaryUpdate'
            style={({ isActive }) => ({
              background: isActive ? 'transparent' : 'white',
              color: 'black',
              padding: '10px',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            })}
          >
            <span className='me-2' style={{ fontSize: '24px' }}>
              <img
                className='rounded-circle'
                style={{ width: '30px', height: '30px' }}
                src={user.img1 === '' ? jobProfile : user.img1}
                alt='user-img'
              />
            </span>
            <span
              style={{
                fontSize: '16px',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {user.full_name}
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
