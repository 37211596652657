import React, { useEffect } from 'react'
import './Signin.css'
import { SigninForm } from './../../forms/'
import logo from '../../assets/images/logo.png'
import images from '../../assets/images/180000.svg'
import userImage from '../../assets/images/users-icns.svg'
import review from '../../assets/images/reviewicns.svg'
import { Link } from 'react-router-dom'
import { Navbar } from '../../components'
import face1 from '../../assets/images/faces/face1.jpg'
import face2 from '../../assets/images/faces/face2.jpg'
import face3 from '../../assets/images/faces/face3.jpg'
import face4 from '../../assets/images/faces/face4.jpg'
function Signin ({ funcNav, funcFooter }) {
  useEffect(() => {
    funcNav(false)
    funcFooter(false)
  }, [funcNav, funcFooter])

  return (
    <>
      <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
        <Navbar />

        {/* Gradient background */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '350px',
            height: '350px',

            background:
              'radial-gradient(circle at top right, #a9c3df 0%, #0A58CA00 70%)',
            zIndex: -10
          }}
        ></div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem',
            height: 'calc(100vh - 64px)'
          }}
        >
          {/* Right section */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0rem 6rem',
              height: '100%',
              paddingLeft: '16rem'
            }}
          >
            <SigninForm />
          </div>

          {/* Left section */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              padding: '2rem'
            }}
          >
            <div>
              <div
                style={{
                  fontSize: '48px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '600'
                }}
              >
                Discover limitless <br /> opportunities
              </div>
              <div>Excited to have you as one of our</div>
              <div>Thanks for being a part of our community!</div>
              <div
                className='images-container'
                style={{
                  position: 'relative',
                  height: '50px',
                  marginTop: '20px'
                }}
              >
                <img
                  src={face1}
                  alt='face1'
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    position: 'absolute',
                    left: '0',
                    zIndex: '1'
                  }}
                />
                <img
                  src={face2}
                  alt='face2'
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    position: 'absolute',
                    left: '40px',
                    zIndex: '2'
                  }}
                />
                <img
                  src={face3}
                  alt='face3'
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    position: 'absolute',
                    left: '80px',
                    zIndex: '3'
                  }}
                />
                <img
                  src={face4}
                  alt='face4'
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    position: 'absolute',
                    left: '110px',
                    zIndex: '4'
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: '180px',
                    top: '15px',
                    fontSize: '14px',
                    fontFamily: 'Arial, sans-serif',
                    color: ' #0A58CA'
                  }}
                >
                  <h6>Over 10k happy users</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signin
