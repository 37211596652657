import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, Navigate } from 'react-router-dom'
import { setUser } from '../../../../actions'
import auth from '../../../../services/authServices'
import funnel from '../../../../assets/images/Dashbord_funnel.png'
import './Panel.css'
import {
  deleteMIntentApply,
  getUserMIntentApply,
  getIntentMIntentApply,
  statusMIntentApply
} from '../../../../services/mintentApplyService'
import FunnelGraph from 'funnel-graph-js'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { styled } from '@mui/system'

const Panel = () => {
  const user = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  const location = useLocation()
  const [items, setItems] = useState([])
  const [submitted, setSubmitted] = useState(0)
  const [total, setTotal] = useState(0)

  const getCampaignData = async () => {
    try {
      const currentUser = await auth.getCurrentUser()
      if (currentUser) {
        const campaignIds = currentUser.campaigns // Array of campaign IDs
        let totalSubmitted = 0
        let totalCampaigns = 0
        const data = {} // To store the campaign details

        // Loop through each campaign ID and fetch data via API
        for (const campaignId of campaignIds) {
          // Example API call, modify it based on your API structure
          const response = await fetch(`compaign/getCampaignById/${campaignId}`) // Assuming you're using fetch API
          const campaignData = await response.json()

          // Store the campaign data using the campaign ID as the key
          data[campaignId] = campaignData

          // Accumulate the submitted and total fields directly from each campaign
          totalSubmitted += campaignData.submitted || 0 // Ensure it's a number
          totalCampaigns += campaignData.accepted || 0
        }

        // Update state with accumulated values
        setSubmitted(totalSubmitted)
        setTotal(totalCampaigns)

        console.log('Fetched campaign data:', data) // Check fetched data
      }
    } catch (error) {
      console.error('Error fetching campaign data:', error)
    }
  }

  const graphRef = useRef(null)

  const fetchData = async () => {
    try {
      let response
      if (user.department === 'Superadmin') {
        response = await getIntentMIntentApply(location.state.intent_id)
      } else {
        response = location.state
          ? await getIntentMIntentApply(location.state.intent_id)
          : await getUserMIntentApply(user._id)
      }
      setItems(response.data.intent_list)
    } catch (error) {
      console.error('Error fetching intent list:', error)
    }
  }

  useEffect(() => {
    if (location) fetchData()
  }, [location])

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const currentUser = await auth.getCurrentUser()
      dispatch(setUser(currentUser))
      getCampaignData()
    }
    fetchCurrentUser()
  }, [dispatch])
  useEffect(() => {
    if (graphRef.current) {
      // Set dimensions and styling for the container
      graphRef.current.style.width = '60%' // Full width
      graphRef.current.style.height = '500px' // Adjust height for better visibility
      graphRef.current.style.padding = '20px' // Add padding around the graph
      graphRef.current.style.boxSizing = 'border-box' // Ensure padding is included in width/height
      graphRef.current.style.marginBottom = '40px' // Ensure padding is included in width/height

      // Define the data for the graph
      const data = {
        labels: ['Controlled', 'Treated', 'Aware', 'Screened', 'Hypertension'],
        // subLabels: ['Female', 'Male'],
        colors: [
          ['red', 'red'],
          ['green', 'green'],
          ['red', 'red']
        ],
        values: [
          [5000, 5000], // Wide bottom
          [3000, 3000], // Widening stage
          [1000, 1000], // Narrowest point
          [3000, 3000], // Narrowing stage
          [5000, 5000] // Wide top
        ]
      }

      // Initialize the FunnelGraph with the updated data and styling
      const graph = new FunnelGraph({
        container: '.funnel',
        gradientDirection: 'vertical', // For vertical orientation
        data: data,
        displayPercent: true,
        direction: 'vertical', // Set direction to vertical
        width: graphRef.current.offsetWidth, // Use container width
        height: graphRef.current.offsetHeight, // Use container height
        subLabelValue: 'values',
        label: false, // Hide labels for a cleaner look
        padding: 10, // Add padding between funnel stages
        // borderWidth: 1, // Add border width
        borderColor: '#ddd' // Light border color for separation
      })

      // Draw the graph
      graph.draw()
    }
  }, [items])

  if (user && !user.isProfileSet)
    return <Navigate to={`/profilesummary`} replace />

  const SummaryInfo = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 20px',
        color: '#fff'
      }}
    >
      {[
        { title: 'End Date:', value: '31 July 2024', bgColor: '#F7A600' },
        {
          title: 'Lead Delivered/Lead Goal',
          value: '280 / 500',
          bgColor: '#0A58CA'
        },
        {
          title: 'Lead Generated',
          value: `${(submitted / total) * 100}`,
          bgColor: '#55B56D'
        }
      ].map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: item.bgColor,
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>{item.title}</div>
          <h6>{item.value}</h6>
        </div>
      ))}
    </div>
  )

  const FunnelVisualization = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '85vH',
        background: 'linear-gradient(to bottom, #1d5a5d, #0e494d)'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '50px 20px',
          color: '#fff'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#F7A600',
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>End Date:</div>
          <h6>
            {new Date().toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          </h6>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#0A58CA',
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>Lead Delivered/Lead Goal</div>
          <h6>
            {submitted} / {total}
          </h6>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#55B56D',
            borderRadius: '10px',
            padding: '20px',
            flex: 1,
            margin: '0 10px'
          }}
        >
          <div>Lead Generated</div>
          <h6>{((submitted / total) * 100).toFixed(2)} %</h6>
        </div>
      </div>
      {/* Funnel Image with Text and Lines */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '60px',
          position: 'relative'
        }}
      >
        {/* left div */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '350px'
          }}
        >
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>Total Lead</h6>
            <div
              style={{
                height: '2px',
                background: '#24F2FF',
                width: '360%',
                position: 'absolute',
                top: '50%',
                zIndex: 1
              }}
            ></div>
            <h6 style={{ paddingTop: '2px', color: 'white' }}>LeadXchange</h6>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>Current Leads</h6>
            <div
              style={{
                height: '2px',
                background: '#86F000',
                width: '420%',
                position: 'absolute',
                top: '100%',
                zIndex: 1
              }}
            ></div>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>Targeted Leads</h6>
            <div
              style={{
                height: '2px',
                background: '#F5BB00',
                width: '470%',
                position: 'absolute',
                top: '100%',
                zIndex: 1
              }}
            ></div>
          </div>
        </div>
        {/* center div */}
        <div style={{ position: 'relative', zIndex: 0 }}>
          <img
            src={funnel}
            alt='Funnel'
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
        {/* right div */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '360px'
          }}
        >
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>{total}+ Million</h6>
            <div
              style={{
                height: '2px',
                background: '#24F2FF',
                width: '420%',
                position: 'absolute',
                top: '100%',
                right: '0',
                zIndex: 1
              }}
            ></div>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>{submitted}+ Million</h6>
            <div
              style={{
                height: '2px',
                background: '#86F000',
                width: '500%',
                position: 'absolute',
                top: '100%',
                right: '0',
                zIndex: 1,
                color: '#86F000'
              }}
            ></div>
          </div>
          <div style={{ position: 'relative' }}>
            <h6 style={{ color: 'white' }}>{total - submitted}+ Million</h6>
            <div
              style={{
                height: '2.5px',
                background: '#F5BB00',
                width: '550%',
                position: 'absolute',
                top: '100%',
                right: '0',
                zIndex: 1
              }}
            ></div>
          </div>
        </div>
      </div>

      <div
        style={{
          background: '#55B56D',
          display: 'flex',
          alignItems: 'center',
          width: '20%',
          marginBottom: '10px',
          marginRight: '10px',
          justifyContent: 'center',
          padding: '10px',
          borderRadius: '10px',
          flexDirection: 'column',
          alignSelf: 'flex-end',
          color: '#fff'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>ToFu</h6>
          <div
            style={{
              height: '2px',
              background: '#24F2FF',
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: '#24F2FF' }}>35%</h6>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>MoFu</h6>
          <div
            style={{
              height: '2px',
              background: '#86F000',
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: '#86F000' }}>25%</h6>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>BoFu</h6>
          <div
            style={{
              height: '2px',
              background: '#F7A600',
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: '#F7A600' }}>30%</h6>
        </div>
      </div>
    </div>
  )

  const FunnelMetrics = () => (
    <div
      style={{
        background: '#55B56D',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        marginBottom: '100px',
        marginRight: '10px',
        justifyContent: 'center',
        borderRadius: '10px',
        flexDirection: 'column',
        alignSelf: 'flex-end',
        color: '#fff'
      }}
    >
      {[
        { label: 'ToFu', value: '35%', color: '#24F2FF' },
        { label: 'MoFu', value: '25%', color: '#86F000' },
        { label: 'BoFu', value: '30%', color: '#F7A600' }
      ].map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h6>{item.label}</h6>
          <div
            style={{
              height: '2px',
              background: item.color,
              width: '6rem',
              margin: '0 8px'
            }}
          ></div>
          <h6 style={{ color: item.color }}>{item.value}</h6>
        </div>
      ))}
    </div>
  )

  const FilterOptions = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '20%',
        background:
          'linear-gradient(to bottom,rgb(0, 57, 89), rgb(5, 88, 135))',
        padding: '20px',
        color: 'white'
      }}
    >
      {[
        { label: 'Assets', defaultChecked: true },
        { label: 'Intent Data' },
        { label: 'Industries', defaultChecked: true },
        { label: 'Revenue' },
        { label: 'Company Size', defaultChecked: true },
        { label: 'Customer Questions' },
        { label: 'Install Base' }
      ].map((option, index) => (
        <label
          key={index}
          style={{
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <input
            type='checkbox'
            defaultChecked={option.defaultChecked}
            style={{
              marginRight: '10px',
              width: '20px',
              height: '20px',
              background: '#F7A600',
              borderRadius: '4px',
              accentColor: 'green'
            }}
          />{' '}
          {option.label}
        </label>
      ))}
      <div style={{ marginTop: '20px' }}>
        {[
          'Targeted Display',
          'Email Newsletter Sponsorship',
          'LinkedIn, Twitter, and Sponsored Ad'
        ].map((text, index) => (
          <div key={index} style={{ display: 'block', marginBottom: '10px' }}>
            {text}
          </div>
        ))}
      </div>
    </div>
  )
  // const Invitation = () => {
  //   const StyledCard = styled(Card)(({ theme }) => ({
  //     margin: 10,
  //     borderRadius: '16px',
  //     boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
  //     transition: 'transform 0.3s, box-shadow 0.3s',
  //     '&:hover': {
  //       transform: 'scale(1.01)',
  //       boxShadow: 'rgba(0, 0, 0, 0.3) 0px 8px 16px'
  //     },
  //     height: '100%',
  //     backgroundColor: '#fbfbfb'
  //   }))
  //   const [email, setEmail] = useState('')
  //   const [checked, setChecked] = useState(false)
  //   const handleSendInvite = () => {
  //     // Call your API to send the email and checkbox state
  //     const payload = {
  //       email,
  //       isChecked: checked
  //     }

  //     // Example API call (replace with your actual API endpoint)
  //     fetch('/users/send-invite', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(payload)
  //     })
  //       .then(response => {
  //         if (response.ok) {
  //           alert('Invite sent successfully!')
  //         } else {
  //           alert('Failed to send invite.')
  //         }
  //       })
  //       .catch(error => {
  //         console.error('Error:', error)
  //         alert('An error occurred while sending the invite.')
  //       })
  //   }
  //   return (
  //     <>
  //       <StyledCard variant='outlined'>
  //         <CardContent sx={{ pt: 5 }}>
  //           <Grid container sx={{ alignItems: 'center' }}>
  //             <Grid
  //               item
  //               md={2}
  //               sx={{ display: 'flex', justifyContent: 'center' }}
  //             >
  //               <img src='/enjoy.png' alt='enjoy' style={{ width: '70%' }} />
  //             </Grid>
  //             <Grid
  //               item
  //               md={4}
  //               pl={2}
  //               sx={{
  //                 display: 'flex',
  //                 flexDirection: 'column',
  //                 justifyContent: 'center'
  //               }}
  //             >
  //               <Typography
  //                 variant='h6'
  //                 sx={{ fontWeight: 600, fontFamily: 'Comic Sans MS' }}
  //                 pb={3}
  //               >
  //                 Invite your teammates
  //               </Typography>
  //               <Typography variant='h8'>
  //                 Let your teammates evaluate our features by adding them for
  //                 your trial.
  //               </Typography>
  //             </Grid>
  //             <Grid
  //               item
  //               md={6}
  //               sx={{
  //                 display: 'flex',
  //                 flexDirection: 'column',
  //                 justifyContent: 'center'
  //               }}
  //             >
  //               <Typography
  //                 variant='h6'
  //                 sx={{ fontWeight: 600, fontFamily: 'Comic Sans MS' }}
  //               >
  //                 Add email address
  //               </Typography>
  //               <Grid container alignItems='center'>
  //                 <Grid item xs={8}>
  //                   <TextField
  //                     type='text'
  //                     sx={{ height: '40px' }} // Adjust the height here
  //                     InputProps={{ style: { height: '40px' } }} // Set the input height
  //                     value={email}
  //                     onChange={e => setEmail(e.target.value)}
  //                     fullWidth
  //                   />
  //                 </Grid>
  //                 <Grid item xs={4} pl={5}>
  //                   <button className='btn btn-sign' onClick={handleSendInvite}>
  //                     Invite user
  //                   </button>
  //                 </Grid>
  //               </Grid>

  //               <FormControlLabel
  //                 control={
  //                   <Checkbox
  //                     checked={checked}
  //                     onChange={e => setChecked(e.target.checked)}
  //                   />
  //                 }
  //                 label='Make Super Admin'
  //               />
  //             </Grid>
  //           </Grid>
  //         </CardContent>
  //       </StyledCard>
  //     </>
  //   )
  // }
  const Invitation = () => {
    const [email, setEmail] = useState('')
    const [checked, setChecked] = useState(false)

    const handleEmailChange = e => {
      setEmail(e.target.value) // Update the email state
    }

    const handleCheckboxChange = e => {
      setChecked(e.target.checked) // Update the checkbox state
    }

    const handleSendInvite = () => {
      // Call your API to send the email and checkbox state
      const payload = {
        email,
        isChecked: checked
      }

      // Example API call (replace with your actual API endpoint)
      fetch('/users/sendInvite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => {
          if (response.ok) {
            alert('Invite sent successfully!')
            setEmail('') // Clear the email input
            setChecked(false) // Reset the checkbox
          } else {
            alert('Failed to send invite.')
          }
        })
        .catch(error => {
          console.error('Error:', error)
          alert('An error occurred while sending the invite.')
        })
    }

    return (
      <div className='styled-card'>
        <div className='card-content'>
          <div className='grid-container'>
            <div className='grid-item image-container'>
              <img src='/enjoy.png' alt='enjoy' className='image' />
            </div>
            <div className='grid-item text-container'>
              <h2>Invite your teammates</h2>
              <p className='description'>
                Let your teammates evaluate our features by adding them for your
                trial.
              </p>
            </div>
            <div className='grid-item input-container'>
              <h4 style={{ color: 'black' }}>Add email address</h4>
              <div className='input-grid'>
                <input
                  type='text'
                  value={email}
                  onChange={handleEmailChange}
                  placeholder='Enter email'
                  className='email-input'
                />
                <button className='btn btn-sign' onClick={handleSendInvite}>
                  Invite user
                </button>
              </div>
              <label className='checkbox-label'>
                <input
                  type='checkbox'
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                Make Super Admin
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, #1d5a5d, #0e494d)'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          borderTop: '1px solid black',
          paddingLeft: '10px',
          paddingRight: '10px'
          // background:
          //   'linear-gradient(to bottom, rgb(0, 128, 0), rgb(85, 181, 109)'
        }}
      >
        {/* <SummaryInfo /> */}
        <FunnelVisualization />
        <Invitation />
        {/* <FunnelMetrics /> */}
      </div>
      <FilterOptions />
    </div>
  )
}

export default Panel
