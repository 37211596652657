import React, { useState, useEffect } from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form'
import { Link } from 'react-router-dom'
import { sendOtp } from './SendOtp.js'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/singuplogo.png'
import { ImLinkedin } from 'react-icons/im'
import { FcGoogle } from 'react-icons/fc'
import { setUser } from '../../actions/userActions.jsx'
import jwtDecode from 'jwt-decode'
import { GoogleLogin } from '@react-oauth/google'
import axios from 'axios'

function SignupForm () {
  const initialData = { email: '', password: '', seller: false } // Initialize seller as false
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    console.log(data)
  }, [data])

  const handleGoogleLogin = async credentialResponse => {
    try {
      const decodedToken = jwtDecode(credentialResponse.credential)
      console.log('Decoded Google Token:', decodedToken)

      // Send the token to the server to create or fetch the user
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/userroles/createGoogleUser`,
        {
          name: decodedToken.name,
          email: decodedToken.email,
          picture: decodedToken.picture
        }
      )

      if (response.status === 200) {
        const user = response.data.user

        // Determine the user's roles based on additional logic or use default 'user' roles
        const roles = data.seller ? 'seller' : 'user'

        // Dispatch to Redux store with the user data and roles
        dispatch(setUser({ ...user, roles })) // Set the user data with roles to Redux

        // Store the user data in sessionStorage
        sessionStorage.setItem('user', JSON.stringify({ ...user, roles }))

        navigate('/profilesummary')
      } else {
        throw new Error('Failed to create or fetch user')
      }
    } catch (error) {
      console.error('Error during Google login process:', error)
      toast.error('An error occurred during Google login.')
    }
  }

  const handleGoogleError = () => {
    console.log('Google Login Failed')
  }

  const doSubmit = async () => {
    try {
      // Send OTP
      await sendOtp(data.email, navigate)

      // Store signup data in session storage
      sessionStorage.setItem('signupData', JSON.stringify(data))

      // Determine the user's roles based on the seller checkbox
      const roles = data.seller ? 'seller' : 'user'

      // Dispatch to Redux store
      dispatch(setUser({ ...data, roles })) // Dispatch the user data with the roles to Redux

      console.log('OTP Sent Successfully and User Roles Set in Redux')
    } catch (otpError) {
      console.error('Error sending OTP:', otpError)
      toast.error('Could Not Send OTP')
    }
  }

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    password: Joi.string().required().min(5).label('Password'),
    seller: Joi.boolean().label('Seller') // Validate as boolean
  }

  const { renderInput, renderButton, handleSubmit, renderCheckbox } = Form({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit
  })

  useEffect(() => {
    const buttonElement = document.querySelector('.sinfrnw .btn')
    if (buttonElement) {
      buttonElement.classList.add('btn-primary')
      buttonElement.classList.add('btre')
    }
  }, [])

  return (
    <>
      <form
        className='sinfrnw'
        onSubmit={handleSubmit}
        style={{
          borderRadius: '16px',
          padding: '10px 20px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
        }}
      >
        <div>
          <Link
            to='/'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={logo} style={{ width: '30%' }} alt='Logo' />
          </Link>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <h4>Create a free account</h4>
          <div>Please enter your details to get started</div>
        </div>
        {renderInput('email', 'Email', 'text', 'example@example.com')}
        {renderInput('password', 'Password', 'password', 'Create a password')}
        {renderCheckbox('seller', ' Are you a seller ?')}
        {renderButton('Get Started')}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
          <span style={{ margin: '0 10px', whiteSpace: 'nowrap' }}>or</span>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
        </div>
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={handleGoogleError}
        />
        <h6 style={{ margin: '15px 0', fontFamily: 'sans-serif' }}>
          Already have an account?{' '}
          <Link to='/signin' style={{ fontWeight: '600' }}>
            Sign in
          </Link>
        </h6>
        <span className='prvtrm'>
          <div className=''>
            <Link to='/termscondition'>Terms & Condition</Link>
            &nbsp;&amp;&nbsp;
            <Link to='/privacy'>Privacy Policy</Link>
          </div>
        </span>
      </form>
    </>
  )
}

export default SignupForm
