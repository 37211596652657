import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from 'react-icons/io'
import { FaPlus } from 'react-icons/fa6'
import './CampaignsDetails.css' // Import CSS file
import Filter from './Filter'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const CampaignsDetails = () => {
  const [data, setData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [pageNumber, setPageNumber] = useState(0)
  const campaignsPerPage = 10
  const pagesVisited = pageNumber * campaignsPerPage
  const navigate = useNavigate()
  const user = useSelector(state => state.userReducer)

  useEffect(() => {
    const fetchUserCampaigns = async () => {
      try {
        const response = await axios.get(
          '/compaign/getUsersAppliedForCampaigns'
        )
        setData(response.data.users.reverse())
      } catch (error) {
        console.error('Error fetching campaigns:', error)
      }
    }

    fetchUserCampaigns()
  }, [])

  const pageCount = Math.ceil(data.length / campaignsPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleSearch = query => {
    setSearchQuery(query)
  }

  return (
    <>
      <div className='card'>
        <div
          className='table-container'
          style={{
            borderRadius: '20px',
            padding: '20px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            marginBottom: '40px',
            marginTop: '30px'
          }}
        >
          <Header />
          <h4 style={{ paddingBottom: '10px' }}>Campaign Requests List</h4>
          {data.length === 0 ? (
            <p>No campaigns found for this user.</p>
          ) : (
            <>
              <div className='table-wrapper'>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    width: '100%'
                  }}
                >
                  <thead>
                    <tr>
                      {[
                        'No.',
                        'Full Name',
                        'Company Name',
                        'Email',
                        'Phone',
                        'Country',
                        'City',
                        'Status',
                        'Add Campaign'
                      ].map((header, index) => (
                        <th
                          key={index}
                          style={{
                            textAlign: 'center',
                            fontSize: '13px',
                            padding: '20px 10px',
                            background: '#F5F7FB',
                            color: '#989898'
                          }}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      .slice(pagesVisited, pagesVisited + campaignsPerPage)
                      .map((campaign, index) => (
                        <tr key={campaign._id}>
                          <td>{index + 1}</td>
                          <td>
                            <Link
                              to={`/admin_panel/userdetails/${campaign._id}`}
                            >
                              {campaign.full_name}
                            </Link>
                          </td>
                          <td>{campaign.company_name}</td>
                          <td>{campaign.email}</td>
                          <td>{campaign.mobile}</td>
                          <td>{campaign.country}</td>
                          <td>{campaign.city}</td>
                          <td>{campaign.status}</td>
                          <td>
                            <Link
                              to={`/admin_panel/campaignsDetails/${campaign._id}`}
                            >
                              <FaPlus size={25} />
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px'
                }}
              >
                <div>
                  <button
                    onClick={() => changePage({ selected: pageNumber - 1 })}
                    disabled={pageNumber === 0}
                    style={{
                      color: 'black',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      border: 'none',
                      marginRight: '10px'
                    }}
                  >
                    <span>
                      <IoIosArrowRoundBack size={25} />
                    </span>
                    Previous
                  </button>
                  <button
                    onClick={() => changePage({ selected: pageNumber + 1 })}
                    disabled={pageNumber === pageCount - 1}
                    style={{
                      color: 'black',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      border: 'none'
                    }}
                  >
                    Next
                    <span>
                      <IoIosArrowRoundForward size={25} />
                    </span>
                  </button>
                </div>
                <div>
                  <span style={{ color: '#0A58CA' }}>
                    Page {pageNumber + 1} of {pageCount}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CampaignsDetails
