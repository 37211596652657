import React, { useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import MultipleValueInput from '../../common/MultipleValueInput'
import upload from '../../assets/images/upload-icon.jpg'
import { useSelector } from 'react-redux'

const CampaignsForm = () => {
  const location = useLocation()
  const userId = location.pathname.split('/').slice(-2, -1)[0]
  const navigate = useNavigate()
  const user = useSelector(state => state.userReducer)
  console.log(user)
  const [formData, setFormData] = useState({
    userId: userId,
    status: '',
    campaignName: '',
    marketer: '',
    mediaPartner: '',
    accepted: '',
    rejected: '',
    submitted: '',
    webinarTotalRegistration: '',
    webinarAttendee: '',
    TopOfFunnel: '',
    MiddleOfFunnel: '',
    BottomOfFunnel: '',
    audienceTargeted: '',
    EngagedAudience: '',
    bannerImpression: '',
    bannerClicks: '',
    edmCtr: '',
    startDate: '',
    endDate: '',
    assets: '',
    leadGeneration: '',
    region: '',
    jobTitle: '',
    installationBase: '',
    industries: [],
    talWishList: [],
    dandList: [],
    companySize: '',
    customQuestion: [],
    productSolution: '',
    contactPerson: '',
    POC: '',
    manager: '',
    campaignLearningAndRecommendation: '',
    image: null,
    campaignLogo: null,
    bannerImage: null,
    edmTargetedEmailsImage: null,
    newsletterImage: null,
    micrositeImage: null,
    socialMediaPromotionImage: null,
    oneToOneChatSeriesImage: null,
    appointmentSettingImage: null,
    webinarImage: null,
    roundTableImage: null,
    manageBy: ''
  })

  const [errors, setErrors] = useState({})

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleFileChange = e => {
    const { name, files } = e.target
    setFormData({ ...formData, [name]: files[0] })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const validationErrors = validateFormData(formData)
    if (validationErrors) {
      setErrors(validationErrors)
      console.log('Validation Errors:', validationErrors)
      return
    }

    const data = new FormData()
    for (let key in formData) {
      if (key === 'metrics') {
        // Metrics is an object, so loop through its keys
        for (let metric in formData[key]) {
          data.append(`metrics[${metric}]`, formData[key][metric])
        }
      } else {
        data.append(key, formData[key])
      }
    }

    try {
      await axios.post(`/compaign/addCampaignForUser/${userId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      // Redirect to the specified URL after successful save
      if (user?.department === 'User') {
        navigate(`/user_dashboard/myCampaigns`)
      } else {
        navigate(`/admin_panel/campaigns/${userId}`)
      }
    } catch (error) {
      console.error(error)
      // Handle error, show error message or handle as needed
    }
  }
  const validateFormData = data => {
    const errors = {}

    // Check for required fields
    const requiredFields = [
      'status',
      'campaignName',
      'marketer',
      'mediaPartner',
      'startDate',
      'endDate',
      'leadGeneration'
    ]

    requiredFields.forEach(field => {
      if (!data[field]) {
        errors[field] = 'This field is required'
      }
    })

    // Additional custom validation rules can be added here

    return Object.keys(errors).length === 0 ? null : errors
  }

  const renderInput = (name, label, type = 'text', readOnly = false) => (
    <div className='form-group'>
      <label style={{ fontSize: '14px' }}>{label}</label>
      {readOnly ? (
        <div className='form-control'>{formData[name].join(' ')}</div>
      ) : (
        <input
          type={type}
          name={name}
          value={formData[name]}
          onChange={handleChange}
          className='form-control'
        />
      )}
      {errors[name] && <div className='alert alert-danger'>{errors[name]}</div>}
    </div>
  )
  const renderFileInput = (name, label) => (
    <div className='form-group'>
      <label>{label}</label>
      <div
        className='file-input-wrapper'
        style={{
          border: '2px dashed #0A58CA',
          padding: '10px',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px' // Adjust margin as needed
        }}
      >
        <div>
          <img
            src={upload}
            alt='Upload'
            style={{ width: '25px', height: '25px' }}
          />
        </div>
        <input
          type='file'
          name={name}
          onChange={handleFileChange}
          className='form-control-file'
          accept='image/*'
          style={{ display: 'none' }} // Hide the actual file input
        />
        <button
          className='btn btn-success ml-auto'
          style={{ marginLeft: 'auto' }}
          onClick={e => {
            e.preventDefault()
            document.querySelector(`input[name="${name}"]`).click()
          }}
        >
          Browse
        </button>
      </div>
      {formData[name] && (
        <img
          src={URL.createObjectURL(formData[name])}
          alt='Preview'
          className='mt-2 mb-2'
          style={{
            maxWidth: '50%',
            maxHeight: '100px',
            borderRadius: '10px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        />
      )}
      {errors[name] && <div className='alert alert-danger'>{errors[name]}</div>}
    </div>
  )

  const renderSelect = (name, label, options) => (
    <div className='form-group'>
      <label>{label}</label>
      <select
        name={name}
        value={formData[name]}
        onChange={handleChange}
        className='form-control'
      >
        <option value=''>Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {errors[name] && <div className='alert alert-danger'>{errors[name]}</div>}
    </div>
  )

  const renderButton = label => (
    <button
      type='submit'
      style={{
        backgroundColor: '#55B56D',
        borderColor: '#007bff',

        borderRadius: '10px',
        color: '#fff',
        fontWeight: '600',
        border: 'none'
      }}
    >
      {label}
    </button>
  )

  return (
    <>
      <div
        className='card'
        style={{
          padding: '20px',
          borderRadius: '10px'
        }}
      >
        <h5>Add Campaign</h5>
        <form onSubmit={handleSubmit} className='forms-sample'>
          <div className='row'>
            <div className='col-12 grid-margin'>
              <div className='row'>
                <div className='col-md-6 mb-3'>
                  {renderInput('campaignName', 'Campaign Name')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('marketer', 'Marketer')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('mediaPartner', 'Media Partner')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('startDate', 'Start Date', 'date')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('endDate', 'End Date', 'date')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderSelect('status', 'Status', [
                    'live',
                    'pending',
                    'complete'
                  ])}
                </div>

                <div className='col-md-6 mb-3'>
                  {renderInput('accepted', 'Target', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('rejected', 'Rejected', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('submitted', 'Target', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput(
                    'webinarTotalRegistration',
                    'Webinar Total Registration',
                    'number'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('webinarAttendee', 'Webinar Attendee', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderSelect('leadGeneration', 'leadGeneration', [
                    'NQL',
                    'BANT',
                    'AppointmentSetting'
                  ])}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('TopOfFunnel', 'Top of Funnel', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('MiddleOfFunnel', 'Middle of Funnel', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('BottomOfFunnel', 'Bottom of Funnel', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput(
                    'audienceTargeted',
                    'Audience Targeted',
                    'number'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('EngagedAudience', 'Engaged Audience', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput(
                    'bannerImpression',
                    'Banner Impression',
                    'number'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('bannerClicks', 'Banner Clicks', 'number')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('edmCtr', 'EDM CTR', 'number')}
                </div>
                {/* Add additional input fields for other properties */}

                <div className='col-md-6 mb-3'>
                  {renderInput('region', 'Region')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('jobTitle', 'Job Title')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('installationBase', 'Installation Base')}
                </div>
                <div className='col-md-6 mb-3'>
                  <label>Industries</label>
                  <MultipleValueInput
                    values={formData.industries}
                    setValues={values =>
                      setFormData({ ...formData, industries: values })
                    }
                  />
                </div>

                <div className='col-md-6 mb-3'>
                  {renderInput('talWishList', 'Tal Wish List', 'text', true)}
                  <MultipleValueInput
                    values={formData.talWishList}
                    setValues={values =>
                      setFormData({ ...formData, talWishList: values })
                    }
                  />
                </div>

                <div className='col-md-6 mb-3'>
                  {renderInput('dandList', 'D and D List', 'text', true)}
                  <MultipleValueInput
                    values={formData.dandList}
                    setValues={values =>
                      setFormData({ ...formData, dandList: values })
                    }
                  />
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('companySize', 'Company Size')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput(
                    'customQuestion',
                    'Custom Question',
                    'text',
                    true
                  )}
                  <MultipleValueInput
                    values={formData.customQuestion}
                    setValues={values =>
                      setFormData({ ...formData, customQuestion: values })
                    }
                  />
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('productSolution', 'Product Solution')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('contactPerson', 'Contact Person')}
                </div>
                <div className='col-md-6 mb-3'>{renderInput('POC', 'POC')}</div>
                <div className='col-md-6 mb-3'>
                  {renderInput('manager', 'Manager')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput(
                    'campaignLearningAndRecommendation',
                    'Campaign Learning and Recommendation'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderInput('manageBy', 'Manage By')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput('image', 'Image')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput('campaignLogo', 'Campaign Logo')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput('bannerImage', 'Banner Image')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput(
                    'edmTargetedEmailsImage',
                    'EDM Targeted Emails Image'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput('newsletterImage', 'Newsletter Image')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput('micrositeImage', 'Microsite Image')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput(
                    'socialMediaPromotionImage',
                    'Social Media Promotion Image'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput(
                    'oneToOneChatSeriesImage',
                    'One to One Chat Series Image'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput(
                    'appointmentSettingImage',
                    'Appointment Setting Image'
                  )}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput('webinarImage', 'Webinar Image')}
                </div>
                <div className='col-md-6 mb-3'>
                  {renderFileInput('roundTableImage', 'Round Table Image')}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between'>
            <div style={{ flex: '1' }}></div>
            <button
              type='submit'
              name='submit'
              value='continue'
              style={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '10px',
                color: '#fff',
                fontWeight: '600',
                border: 'none'
              }}
            >
              {renderButton('Add Campaign')}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default CampaignsForm
