export const MenuItem = [
  {
    to: '/admin_panel',
    icon: 'bx bx-grid-alt nav_icon',
    name: 'Dashboard'
  },
  {
    to: 'users',
    icon: 'bx bx-user nav_icon',
    name: 'Users'
  },

  {
    to: 'campaigns',
    icon: 'fa fa-user-o fa-fw',
    name: 'Campaigns'
  },
  {
    to: 'userRoles',
    icon: 'bx bx-user nav_icon',
    name: 'User Roles'
  },
  {
    to: 'userCampaign2',
    icon: 'fa fa-user-o fa-fw',
    name: 'User Campaign'
  },
  {
    to: 'intent',
    icon: 'bx bx-folder nav_icon',
    name: 'Intent Data'
  },

  {
    to: 'subscription',
    icon: 'bx bx-bar-chart-alt-2 nav_icon',
    name: 'Subscription'
  },
  {
    to: 'cms',
    icon: 'bx bx-grid-alt nav_icon',
    name: 'CMS'
  },

  {
    to: 'members',
    icon: 'bx bx-user nav_icon',
    name: 'Our Team'
  },
  {
    to: 'testimonial',
    icon: 'bx bx-user nav_icon',
    name: 'Testimonials'
  },
  {
    to: 'awards',
    icon: 'bx bx-message-square-detail nav_icon',
    name: 'Awards'
  },
  {
    to: 'objectives',
    icon: 'bx bx-folder nav_icon',
    name: 'Objectives'
  },

  {
    to: 'faq',
    icon: 'bx bx-message-square-detail nav_icon',
    name: 'FAQs'
  },

  {
    to: 'blogs',
    icon: 'bx bx-bookmark nav_icon',
    name: 'Blogs'
  },
  {
    to: 'contact',
    icon: 'bx bx-bar-chart-alt-2 nav_icon',
    name: 'Contact List'
  },
  {
    to: '/logout',
    icon: 'bx bx-log-out nav_icon',
    name: 'Log Out'
  }
]

export function getMenuItemBasedOnRole (role) {
  return [
    {
      to: '/admin_panel',
      icon: 'bx bx-grid-alt nav_icon',
      name: 'Dashboard'
    },
    {
      to: 'users',
      icon: 'bx bx-user nav_icon',
      name: 'Users'
    },

    role === 'Superadmin'
      ? {
          to: 'campaigns',
          icon: 'fa fa-user-o fa-fw',
          name: 'Campaigns'
        }
      : null,
    // role === 'Superadmin'
    //   ? {
    //       to: 'userRoles',
    //       icon: 'bx bx-user nav_icon',
    //       name: 'User Roles'
    //     }
    //   : null,
    role === 'User'
      ? {
          to: 'userCampaign2',
          icon: 'fa fa-user-o fa-fw',
          name: 'User Campaign'
        }
      : null,
    {
      to: 'intent',
      icon: 'bx bx-folder nav_icon',
      name: 'Intent Data'
    },

    {
      to: 'subscription',
      icon: 'bx bx-bar-chart-alt-2 nav_icon',
      name: 'Subscription'
    },
    {
      to: 'cms',
      icon: 'bx bx-grid-alt nav_icon',
      name: 'CMS'
    },

    {
      to: 'members',
      icon: 'bx bx-user nav_icon',
      name: 'Our Team'
    },
    {
      to: 'testimonial',
      icon: 'bx bx-user nav_icon',
      name: 'Testimonials'
    },
    {
      to: 'awards',
      icon: 'bx bx-message-square-detail nav_icon',
      name: 'Awards'
    },
    {
      to: 'objectives',
      icon: 'bx bx-folder nav_icon',
      name: 'Objectives'
    },

    {
      to: 'faq',
      icon: 'bx bx-message-square-detail nav_icon',
      name: 'FAQs'
    },

    {
      to: 'blogs',
      icon: 'bx bx-bookmark nav_icon',
      name: 'Blogs'
    },
    {
      to: 'contact',
      icon: 'bx bx-bar-chart-alt-2 nav_icon',
      name: 'Contact List'
    },
    {
      to: '/logout',
      icon: 'bx bx-log-out nav_icon',
      name: 'Log Out'
    }
  ].filter(Boolean)
}
