export const MenuItems = [
  {
    to: '/admin_panel',
    icon: 'bx bx-grid-alt nav_icon',
    name: 'Dashboard',
    roles: ['Superadmin', 'User'] // Define roles that can access this item
  },
  {
    to: 'users',
    icon: 'bx bx-user nav_icon',
    name: 'Users',
    roles: ['Superadmin']
  },
  {
    to: 'campaigns',
    icon: 'fa fa-user-o fa-fw',
    name: 'Campaigns',
    roles: ['Superadmin']
  },
  {
    to: 'campaignsDetails',
    icon: 'fa fa-user-o fa-fw',
    name: 'Campaigns Details',
    roles: ['Superadmin']
  },
  {
    to: 'userCampaign2',
    icon: 'fa fa-user-o fa-fw',
    name: 'User Campaign',
    roles: ['User']
  },
  {
    to: 'intent',
    icon: 'bx bx-folder nav_icon',
    name: 'Intent Data',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'subscription',
    icon: 'bx bx-bar-chart-alt-2 nav_icon',
    name: 'Subscription',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'cms',
    icon: 'bx bx-grid-alt nav_icon',
    name: 'CMS',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'members',
    icon: 'bx bx-user nav_icon',
    name: 'Our Team',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'testimonial',
    icon: 'bx bx-user nav_icon',
    name: 'Testimonials',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'awards',
    icon: 'bx bx-message-square-detail nav_icon',
    name: 'Awards',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'objectives',
    icon: 'bx bx-folder nav_icon',
    name: 'Objectives',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'faq',
    icon: 'bx bx-message-square-detail nav_icon',
    name: 'FAQs',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'blogs',
    icon: 'bx bx-bookmark nav_icon',
    name: 'Blogs',
    roles: ['Superadmin', 'User']
  },
  {
    to: 'contact',
    icon: 'bx bx-bar-chart-alt-2 nav_icon',
    name: 'Contact List',
    roles: ['Superadmin', 'User']
  },
  {
    to: '/logout',
    icon: 'bx bx-log-out nav_icon',
    name: 'Log Out',
    roles: ['Superadmin', 'User']
  }
]

export function getMenuItemBasedOnRole (role) {
  return MenuItems.filter(menuItem => menuItem.roles.includes(role))
}
