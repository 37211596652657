import React from 'react'
import {
  FaThLarge,
  FaUser,
  FaUsers,
  FaMousePointer,
  FaBullhorn
} from 'react-icons/fa'

export const MenuItems = [
  {
    key: '/user_dashboard',
    icon: <FaThLarge />, // Use react-icons for Dashboard
    text: 'Dashboard'
  },
  {
    key: 'myCampaigns',
    icon: <FaBullhorn />, // Use react-icons for My Campaigns
    text: 'My Campaigns'
  },
  {
    key: 'activeLeads',
    icon: <FaUsers />, // Use react-icons for Active Leads
    text: 'Active BANT Leads'
  },
  {
    key: 'intent',
    icon: <FaMousePointer />, // Use react-icons for Intent Data
    text: 'Intent Leads'
  }
]
export const MenuItemsSeller = [
  {
    key: '/user_dashboard',
    icon: <FaThLarge />, // Use react-icons for Dashboard
    text: 'Dashboard'
  },
  {
    key: 'myCampaigns',
    icon: <FaBullhorn />, // Use react-icons for My Campaigns
    text: 'My Campaigns'
  },
  {
    key: 'billing',
    icon: <FaMousePointer />, // Use react-icons for Intent Data
    text: 'Active Billing'
  }
]
