import { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'

// Styled Paper component
const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  ...theme.typography.subtitle1,
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const IntentBody = () => {
  const [datas, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const response = await axios.get('/intent')
      setData(response.data.intent_list)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <CircularProgress />

  return (
    <Stack direction='column' spacing={3} style={{ boxSizing: 'border-box' }}>
      {datas.map((data, index) => (
        <DemoPaper key={data.id || index} variant='elevation'>
          <Stack
            direction='row'
            spacing={2}
            style={{ width: '100%', alignItems: 'center' }}
          >
            <Stack
              spacing={1}
              alignItems='center'
              style={{ flexShrink: 0, width: '15%' }}
            >
              <img
                src='/logo192.png'
                alt='Company Logo'
                style={{ width: 60, height: 60, marginBottom: '20px' }}
              />
              <Typography variant='h6'>
                {data.companyName || 'No Company Name'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              spacing={1}
              textAlign='left'
              justifyContent='center'
              style={{ flexShrink: 0, width: '25%', paddingLeft: 10 }}
            >
              <Typography variant='subtitle1'>
                Customer: {data.customer || 'Customer Name'}
              </Typography>
              <Typography variant='subtitle1'>
                Designation: {data.designation || 'No Designation'}
              </Typography>
              <Typography variant='subtitle1'>
                Mobile: {data.mobileNumber || 'No Mobile'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              spacing={1}
              alignItems='flex-start'
              justifyContent='center'
              style={{ flexShrink: 0, width: '25%', paddingLeft: 10 }}
            >
              <Typography variant='subtitle1'>
                Designation: {data?.designation || 'No Designation'}
              </Typography>
              <Typography variant='subtitle1'>
                City: {data?.city || 'No City'}
              </Typography>
              <Typography variant='subtitle1'>
                State: {data?.state || 'No State'}
              </Typography>
              <Typography variant='subtitle1'>
                Email: {data?.email || 'No Email'}
              </Typography>
            </Stack>

            <Divider sx={{ borderWidth: 2 }} orientation='vertical' flexItem />

            <Stack
              direction='column'
              spacing={1}
              alignItems='center'
              width='100%'
            >
              <Button
                sx={{
                  width: '30%',
                  fontSize: '13px',
                  backgroundColor: '#55B56D',
                  borderRadius: '8px',
                  textTransform: 'capitalize'
                }}
                variant='contained'
              >
                Buy Lead
              </Button>
              <Button
                sx={{
                  width: '30%',
                  fontSize: '13px',
                  borderRadius: '8px',
                  backgroundColor: '#0A58CA',
                  textTransform: 'capitalize'
                }}
                variant='contained'
              >
                Add to cart
              </Button>
              <Button
                sx={{
                  width: '30%',
                  textTransform: 'capitalize',
                  fontSize: '13px',
                  borderRadius: '8px',
                  color: 'black',
                  backgroundColor: '#CAE8D2'
                }}
                variant='contained'
              >
                More
              </Button>
            </Stack>
          </Stack>
        </DemoPaper>
      ))}
    </Stack>
  )
}

export default IntentBody
