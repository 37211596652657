import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'

const CSVUpload = ({ campaignId }) => {
  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    // Check if selected file exists and is of type CSV
    if (selectedFile && selectedFile.type === 'text/csv') {
      handleUpload(selectedFile, campaignId)
      // Reset input value after successful upload
      event.target.value = null
      toast.info('File selected: ' + selectedFile.name)
    } else {
      // Reset input value if file type is incorrect
      event.target.value = null
      toast.error('Please select a CSV file.')
    }
  }

  const handleUpload = (file, campaignId) => {
    const formData = new FormData()
    formData.append('csv', file)

    axios
      .post('/csv/uploadChartData', formData)
      .then(response => {
        // Handle successful upload
        console.log(response.data)
        toast.success('File uploaded successfully')
      })
      .catch(error => {
        // Handle upload error
        console.error(error)
        toast.error('Error uploading file')
      })
  }

  return (
    <>
      <input
        id='fileInput'
        type='file'
        accept='.csv'
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <button onClick={() => document.getElementById('fileInput').click()}>
        Upload CSV
      </button>
    </>
  )
}

export default CSVUpload
