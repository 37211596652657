import React, { useState, useEffect } from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form'
import auth from '../../services/authServices'
import * as userService from '../../services/userService'
import { Link } from 'react-router-dom'
import googleImage from '../../assets/images/google-imgss.png'
import linkedin from '../../assets/images/linked-icon.png'
import { sendOtp, signUp } from './SendOtp.js'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/singuplogo.png'
import { ImLinkedin } from 'react-icons/im'
import { FcGoogle } from 'react-icons/fc'
import { setUser } from '../../actions/userActions.jsx'
import jwtDecode from 'jwt-decode'
import { GoogleLogin } from '@react-oauth/google'
import axios from "axios"
function SignupForm () {
  const initialData = { email: '', password: '' }
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const signupData = {
    ...data
  }

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      // Decode the JWT token from the credentialResponse
      const decodedToken = jwtDecode(credentialResponse.credential);
      console.log('Decoded Google Token:', decodedToken);

      // Call the API to create or fetch the user
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/userroles/createGoogleUser`, {
        name: decodedToken.name,
        email: decodedToken.email,
        picture: decodedToken.picture,
      });

      // Check if the API call was successful
      if (response.status === 200) {
        // Dispatch the user data to Redux
        dispatch(setUser(response.data.user));
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        // Redirect user to dashboard or desired page
        navigate('/profilesummary');
      } else {
        throw new Error('Failed to create or fetch user');
      }
    } catch (error) {
      console.error('Error during Google login process:', error);
      toast.error('An error occurred during Google login.');
    }
  };
  const handleGoogleError = () => {
    console.log('Google Login Failed')
  }

  const doSubmit = async () => {
    try {
      // Call sendOtp function
      await sendOtp(data.email, navigate)

      // Save signupData to sessionStorage
      sessionStorage.setItem('signupData', JSON.stringify(signupData))

      console.log('OTP Sent Successfully')
    } catch (otpError) {
      console.error('Error sending OTP:', otpError)
      toast.error('Could Not Send OTP')
      return // Stop execution if OTP sending fails
    }
  }

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    password: Joi.string().required().min(5).label('Password')
  }

  const { renderInput, renderButton, handleSubmit } = Form({
    data,
    setData,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit
  })

  useEffect(() => {
    const buttonElement = document.querySelector('.sinfrnw .btn')
    if (buttonElement) {
      buttonElement.classList.add('btn-primary')
      buttonElement.classList.add('btre')
    }
  }, [])
  const loginWithGoogle = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/google/callback`,
      '_self'
    )
  }

  // Send OTP to user for verification

  return (
    <>
      <form
        className='sinfrnw'
        onSubmit={handleSubmit}
        style={{
          borderRadius: '16px',
          padding: '10px 20px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
        }}
      >
        <div>
          <Link
            to='/'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={logo} style={{ width: '30%' }} />
          </Link>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <h4>Create a free account</h4>
          <div>Please enter your details to get started</div>
        </div>
        {renderInput('email', 'Email', 'text', 'example@example.com')}
        {renderInput('password', 'Password', 'password', 'Create a password')}
        {renderButton('Get Started')}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
          <span style={{ margin: '0 10px', whiteSpace: 'nowrap' }}>or</span>
          <div style={{ flex: 1, height: '1px', backgroundColor: '#ccc' }} />
        </div>
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={handleGoogleError}
        />
        <button
          class='ggl-sing'
          style={{ margin: '10px 0' }}
          onClick={() => {
            loginWithGoogle()
          }}
        >
          <span style={{ paddingRight: '10px' }}>
            {' '}
            <FcGoogle size={25} />
          </span>
          Sign in with Google
        </button>{' '}
        <Link class='ggl-sing' to='#'>
          {/* <img src={linkedin} alt="linkedIn" /> */}
          <span style={{ color: '#0A66C2', paddingRight: '10px' }}>
            {' '}
            <ImLinkedin size={20} />
          </span>
          Sign in with LinkedIn
        </Link>
        <h6 style={{ margin: '15px 0', fontFamily: 'sans-serif' }}>
          Already have an account ?{' '}
          <Link to='/signin' style={{ fontWeight: '600' }}>
            Sign in
          </Link>{' '}
        </h6>
        <span class='prvtrm'>
          <div className=''>
            <Link to='/termscondition'>Terms & Condition</Link>
            &nbsp;&amp;&nbsp;
            <Link to='/privacy'>Privacy Policy</Link>
          </div>
        </span>
      </form>
    </>
  )
}

export default SignupForm
