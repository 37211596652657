import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'

const SuccessfulPayment = () => {
  const navigate = useNavigate()
  const user = useSelector(state => state.userReducer) // Get user from Redux store

  useEffect(() => {
    // Function to be run after payment
    const handleAfterPayment = async () => {
      console.log('user', user)

      // Add your post-payment logic here
      try {
        // Send user data to the endpoint
        const response = await axios.post('/payment/changeFirstPayment', {
          user
        })
        console.log('Response from server:', response.data)
      } catch (error) {
        console.error('Error sending user data:', error)
      }
    }

    // Run the function
    handleAfterPayment()

    // Timer for redirection
    const timer = setTimeout(() => {
      navigate('/user_dashboard') // Redirect to desired route
    }, 5000) // 5 seconds

    // Clear timer if component unmounts
    return () => clearTimeout(timer)
  }, [navigate, user]) // Include `user` in dependency array

  return (
    <div style={{ minHeight: '80px' }}>
      <div style={{ height: '80px' }}></div>
      <h1>Payment Successful</h1>
      <p>You will be redirected in 5 seconds...</p>
    </div>
  )
}

export default SuccessfulPayment
