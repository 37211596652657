import React from 'react'

const Checkbox = ({ name, label, checked, onChange, error }) => {
  return (
    <div className='form-group'>
      <label>
        <input
          type='checkbox'
          name={name}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
      {error && <div className='alert alert-danger'>{error}</div>}
    </div>
  )
}

export default Checkbox
