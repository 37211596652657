import React, { useState, useEffect } from 'react'
import Joi from 'joi-browser'
import { useSelector } from 'react-redux'
import Form from '../../common/form'
import { toast } from 'react-toastify'
import { setUserProfile } from '../../services/userService'
import jobProfile from '../../assets/images/images/jop-profile.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { RiPencilFill } from 'react-icons/ri'
import { Button } from '@mui/material'
const UserProfileFormUpdate = () => {
  const user = useSelector(state => state.userReducer)
  const initialData = {
    _id: user?._id ?? '',
    img1: user?.img1 ?? '',
    full_name: user?.full_name ?? '',
    last_name: user?.last_name ?? '',
    email: user?.email ?? '',
    designation: user?.designation ?? '',
    company_name: user?.company_name ?? '',
    mobile: user?.mobile ?? '',
    city: user?.city ?? '',
    state: user?.state ?? '',
    country: user?.country ?? '',
    about: user?.about ?? '',
    skills: user?.skills ?? '',
    linkedin_profile: user?.linkedin_profile ?? ''
  }
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const [base64Image, setBase64Image] = useState('')
  const navigate = useNavigate()
  const location = useLocation()

  const doSubmit = async () => {
    try {
      const response = await setUserProfile(data._id, data)
      const { data: responseData } = response
      toast.success(responseData.message)

      if (responseData.token) {
        sessionStorage.setItem('user', JSON.stringify(responseData.user))
        let redirectUrl = process.env.REACT_APP_liveURL
        if (process.env.NODE_ENV === 'development') {
          redirectUrl = process.env.REACT_APP_localURL
        }
        let path = location?.state
          ? `${redirectUrl}${location?.state?.history}`
          : `${redirectUrl}/user_dashboard`

        window.location.href = path
      } else {
        toast.error('Error setting details. Please try again.')
      }
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message)
      }
    }
  }

  const schema = {
    _id: Joi.optional().allow(''),
    img1: Joi.optional().allow(''),
    full_name: Joi.string().required().label('Full Name'),
    last_name: Joi.string().required().label('Last Name'),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    designation: Joi.string().required().label('Designation'),
    company_name: Joi.string().required().label('Company Name'),
    mobile: Joi.string().required().label('Mobile Phone'),
    city: Joi.string().required().label('City Name'),
    state: Joi.string().required().label('State Name'),
    country: Joi.string().required().label('Country Name'),
    about: Joi.optional().allow(''),
    skills: Joi.optional().allow(''),
    linkedin_profile: Joi.optional().allow('')
  }

  const {
    renderInput,
    renderButton,
    renderFile,
    renderInputArea,
    handleSubmit
  } = Form({
    data,
    setData,
    base64Image,
    setBase64Image,
    errors,
    setErrors,
    schema,
    onSubmit: doSubmit
  })

  useEffect(() => {
    const buttonElement = document.querySelector('.forms-sample .btn')
    if (buttonElement) {
      buttonElement.classList.add('btn-gradient-primary')
    }
  }, [])

  return (
    <>
      <form className='profiless-img' onSubmit={handleSubmit}>
        <div className='row '>
          <h6>Update Your Profile</h6>
          <div
            className='col-md-3'
            style={{ width: '160px', margin: '0 auto 35px' }}
          >
            <div className='prfl-frm-left' style={{ position: 'relative' }}>
              {renderFile('img1', '', 'file')}
              <img
                id='imagePreview'
                src={data.img1 === '' ? jobProfile : data.img1}
                alt='Preview'
                style={{
                  width: '100px', // Decreased size
                  height: '100px', // Ensuring the height matches the width
                  borderRadius: '50%', // Making the image fully round
                  display: 'block',
                  margin: '0 auto'
                }}
              />
              <RiPencilFill
                size={30}
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  backgroundColor: '#0A58CA',
                  borderRadius: '50%',
                  padding: '5px',
                  color: '#fff',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                }}
              />
            </div>
          </div>
          <div className='col-md-12'>
            <div className='prfl-frm '>
              <div className='prfl-frm-mn mb-4 '>
                {renderInput('full_name', 'Full Name')}
              </div>
              <div className='prfl-frm-mn mb-4 '>
                {renderInput('last_name', 'Last Name')}
              </div>

              <div className=' prfl-frm-mn mb-4 '>
                {renderInput('email', 'Email')}
              </div>
              <div className=' prfl-frm-mn mb-4 '>
                {renderInput('mobile', 'Mobile', 'tel')}
              </div>
              <div className=' prfl-frm-mn mb-4 '>
                {renderInput('company_name', 'Company Name')}
              </div>
              <div className=' prfl-frm-mn mb-4 '>
                {renderInput('designation', 'Designation')}
              </div>
              <div className=' prfl-frm-mn mb-4 '>
                {renderInput('city', 'City')}
              </div>
              <div className=' prfl-frm-mn mb-4 '>
                {renderInput('state', 'State')}
              </div>
              <div className=' prfl-frm-mn mb-4 '>
                {renderInput('country', 'Country')}
              </div>
              <div className='prfl-frm-mn mb-4  '>
                {renderInput('linkedin_profile', 'Linkedin Profile Link')}
              </div>

              <div className=' prfl-frm-mns mb-4 '>
                {renderInputArea('about', 'About me ( Min 250 words )')}
              </div>
              <div className=' prfl-frm-mns mb-4 '>
                {renderInputArea('skills', 'Mention your Skills')}
              </div>
            </div>
          </div>{' '}
          <div className='d-flex justify-content-between'>
            <div style={{ flex: '1' }}>
              <Button onClick={() => navigate('/changePassword')}>
                Change Password
              </Button>
            </div>
            <button
              type='submit'
              name='submit'
              value='continue'
              style={{
                backgroundColor: '#55B56D',
                borderColor: '#007bff',
                marginBottom: '10px',
                padding: '10px 24px',
                borderRadius: '10px',
                color: '#fff',
                fontWeight: '600',
                border: 'none'
              }}
            >
              Save & Continue
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default UserProfileFormUpdate
