import React from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import Switch from '@mui/joy/Switch'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import checkCircle from '../../assets/images/check-circle.svg'
import frame1 from '../../assets/images/frame1.png'
import frame2 from '../../assets/images/frame2.png'
import frame3 from '../../assets/images/frame3.png'

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  display: 'flex',
  width: '25%',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 8px 16px'
  }
}))
const StyledCard2 = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  display: 'flex',
  width: '25%',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 8px 16px'
  }
}))

// a11yProps function for accessibility
const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const Pricing = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_PULISHABLE_KEY)

  const [checkedMRR, setCheckedMRR] = React.useState(false)
  const [checkedIntent, setCheckedIntent] = React.useState(false)
  const [value, setValue] = React.useState(0) // Manage the selected tab state
  const [value2, setValue2] = React.useState(0) // Manage the selected tab state

  const navigate = useNavigate()

  const handleChangeMRR = () => {
    setCheckedMRR(!checkedMRR)
  }

  const handleChangeIntent = () => {
    setCheckedIntent(!checkedIntent)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue) // Updates the selected tab
  }
  const handleChange2 = (event, newValue) => {
    setValue2(newValue) // Updates the selected tab
  }

  const handlePayment = async () => {
    try {
      // const paymentResponse = await axios.post('/payment', {
      //   headers: {
      //     Authorization: `Bearer ${process.env.REACT_APP_SECRET_KEY}`
      //   }
      // })
      // const stripe = await stripePromise

      // const sessionId = paymentResponse.data.id
      // const result = await stripe.redirectToCheckout({ sessionId })
      navigate('/signin')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Box sx={{ p: 5 }}>
        <Grid container spacing={10} sx={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography variant='h3' fontWeight={700}>
              Active Leads Plans
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={400}
              pt={2}
              pb={4}
              color={'#878787'}
            >
              Get valid LIVE leads with call recording directly from our site
            </Typography>
            <Box>
              <Tabs
                value={value} // Binds the state to the Tabs component
                onChange={handleChange}
                aria-label='basic tabs example'
                sx={{
                  backgroundColor: '#F2F2F2',
                  borderRadius: '24px',
                  border: 'none'
                }}
                indicatorColor='transparent'
              >
                <Tab
                  label='Monthly'
                  sx={{
                    padding: '10px 16px', // Reduced padding to decrease height
                    textTransform: 'Capitalize',
                    margin: '5px 5px', // Reduced margin to decrease height
                    minHeight: '0', // Ensure minimum height is effectively zero
                    backgroundColor: value === 0 ? '#55B56D' : 'transparent', // Green background when selected
                    borderRadius: '24px',
                    color: value === 0 ? '#FFFFFF' : '#878787', // White text when selected, grey when not
                    '&:hover': {
                      backgroundColor: '#55B56D', // Green background on hover
                      color: '#FFFFFF !important' // Force white text on hover
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#55B56D', // Ensure green background when selected
                      color: '#FFFFFF' // White text when selected
                    }
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  label='Annually'
                  sx={{
                    textTransform: 'Capitalize',
                    padding: '10px 16px', // Reduced padding to decrease height
                    margin: '5px 5px', // Reduced margin to decrease height
                    minHeight: '0', // Ensure minimum height is effectively zero
                    backgroundColor: value === 1 ? '#55B56D' : 'transparent', // Green background when selected
                    borderRadius: '24px',
                    color: value === 1 ? '#FFFFFF' : '#878787', // White text when selected, grey when not
                    '&:hover': {
                      backgroundColor: '#55B56D', // Green background on hover
                      color: '#FFFFFF !important' // Force white text on hover
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#55B56D', // Ensure green background when selected
                      color: '#FFFFFF' // White text when selected
                    }
                  }}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}
      >
        {/* Active Leads Card */}

        <StyledCard variant='outlined'>
          <CardContent
            sx={{
              pt: 5,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ pl: '25px' }}>
              <img src={frame1} style={{ height: '100px', width: '100px' }} />
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#0072DE',
                  fontFamily: 'Poppins',
                  pt: 4,
                  pb: 1
                }}
              >
                Basic
              </Typography>
              <Typography
                sx={{
                  fontSize: '48px',
                  fontWeight: 600,
                  fontFamily: 'Poppins'
                }}
              >
                ${value === 0 ? '100' : '1,200'}
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#4B5563'
                  }}
                >
                  /{value === 0 ? 'month' : 'year'}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#4B5563',
                  width: '100%',
                  py: 2
                }}
              >
                Get essential features at an affordable price, the perfect
                combination.
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '60%'
                    }}
                  >
                    Includes: 10 Leads
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    Includes: 1 User
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ borderRadius: '0.25rem', width: '80%' }}
                className='btn btn-sign'
                variant='contained'
                color='primary'
                onClick={() => handlePayment()}
              >
                Get Started &nbsp; &gt;
              </button>
            </Box>
          </CardContent>
        </StyledCard>

        <StyledCard variant='outlined'>
          <CardContent
            sx={{
              pt: 5,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ pl: '25px' }}>
              <img src={frame2} style={{ height: '100px', width: '100px' }} />
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#0072DE',
                  fontFamily: 'Poppins',
                  pt: 4,
                  pb: 1
                }}
              >
                Pro Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: '48px',
                  fontWeight: 600,
                  fontFamily: 'Poppins'
                }}
              >
                ${value === 0 ? '250' : '3,000'}
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#4B5563'
                  }}
                >
                  /{value === 0 ? 'month' : 'year'}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#4B5563',
                  width: '100%',
                  py: 2
                }}
              >
                Get essential features at an affordable price, the perfect
                combination.
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '60%'
                    }}
                  >
                    Includes: 50 Leads
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    Includes: 3 User
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ borderRadius: '0.25rem', width: '80%' }}
                className='btn btn-sign'
                variant='contained'
                color='primary'
                onClick={() => handlePayment()}
              >
                Get Started &nbsp; &gt;
              </button>
            </Box>
          </CardContent>
        </StyledCard>

        <StyledCard variant='outlined'>
          <CardContent
            sx={{
              pt: 5,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ pl: '25px' }}>
              <img src={frame3} style={{ height: '100px', width: '100px' }} />
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#0072DE',
                  fontFamily: 'Poppins',
                  pt: 4,
                  pb: 1
                }}
              >
                Enterprise Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: '48px',
                  fontWeight: 600,
                  fontFamily: 'Poppins'
                }}
              >
                ${value === 0 ? '500' : '6,000'}
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#4B5563'
                  }}
                >
                  /{value === 0 ? 'month' : 'year'}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#4B5563',
                  width: '100%',
                  py: 2
                }}
              >
                Get essential features at an affordable price, the perfect
                combination.
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '60%'
                    }}
                  >
                    Includes: 50 Leads
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    Includes: Multiusers
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ borderRadius: '0.25rem', width: '80%' }}
                className='btn btn-sign'
                variant='contained'
                color='primary'
                onClick={() => handlePayment()}
              >
                Get Started &nbsp; &gt;
              </button>
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>
      <Box sx={{ p: 5 }}>
        <Grid container spacing={10} sx={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography variant='h3' fontWeight={700}>
              Intent Data Plans
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={400}
              pt={2}
              pb={4}
              color={'#878787'}
            >
              Get valid LIVE leads with call recording directly from our site
            </Typography>
            <Box>
              <Tabs
                value={value2} // Binds the state to the Tabs component
                onChange={handleChange2}
                aria-label='basic tabs example'
                sx={{
                  backgroundColor: '#F2F2F2',
                  borderRadius: '24px',
                  border: 'none'
                }}
                indicatorColor='transparent'
              >
                <Tab
                  label='Monthly'
                  sx={{
                    padding: '10px 16px', // Reduced padding to decrease height
                    textTransform: 'Capitalize',
                    margin: '5px 5px', // Reduced margin to decrease height
                    minHeight: '0', // Ensure minimum height is effectively zero
                    backgroundColor: value2 === 0 ? '#55B56D' : 'transparent', // Green background when selected
                    borderRadius: '24px',
                    color: value2 === 0 ? '#FFFFFF' : '#878787', // White text when selected, grey when not
                    '&:hover': {
                      backgroundColor: '#55B56D', // Green background on hover
                      color: '#FFFFFF !important' // Force white text on hover
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#55B56D', // Ensure green background when selected
                      color: '#FFFFFF' // White text when selected
                    }
                  }}
                  {...a11yProps(0)}
                />
                <Tab
                  label='Annually'
                  sx={{
                    textTransform: 'Capitalize',
                    padding: '10px 16px', // Reduced padding to decrease height
                    margin: '5px 5px', // Reduced margin to decrease height
                    minHeight: '0', // Ensure minimum height is effectively zero
                    backgroundColor: value2 === 1 ? '#55B56D' : 'transparent', // Green background when selected
                    borderRadius: '24px',
                    color: value2 === 1 ? '#FFFFFF' : '#878787', // White text when selected, grey when not
                    '&:hover': {
                      backgroundColor: '#55B56D', // Green background on hover
                      color: '#FFFFFF !important' // Force white text on hover
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#55B56D', // Ensure green background when selected
                      color: '#FFFFFF' // White text when selected
                    }
                  }}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        mb={10}
        sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}
      >
        {/* Active Leads Card */}

        <StyledCard2 variant='outlined'>
          <CardContent
            sx={{
              pt: 5,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ pl: '25px' }}>
              <img src={frame1} style={{ height: '100px', width: '100px' }} />
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#0072DE',
                  fontFamily: 'Poppins',
                  pt: 4,
                  pb: 1
                }}
              >
                Basic
              </Typography>
              <Typography
                sx={{
                  fontSize: '48px',
                  fontWeight: 600,
                  fontFamily: 'Poppins'
                }}
              >
                ${value2 === 0 ? '500' : '5000'}
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#4B5563'
                  }}
                >
                  /{value2 === 0 ? 'month' : 'year'}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#4B5563',
                  width: '100%',
                  py: 2
                }}
              >
                Get essential features at an affordable price, the perfect
                combination.
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '60%'
                    }}
                  >
                    Limited Intent Signals
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    1 user license{' '}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ borderRadius: '0.25rem', width: '80%' }}
                className='btn btn-sign'
                variant='contained'
                color='primary'
                onClick={() => handlePayment()}
              >
                Get Started &nbsp; &gt;
              </button>
            </Box>
          </CardContent>
        </StyledCard2>

        <StyledCard2 variant='outlined'>
          <CardContent
            sx={{
              pt: 5,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ pl: '25px' }}>
              <img src={frame2} style={{ height: '100px', width: '100px' }} />
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#0072DE',
                  fontFamily: 'Poppins',
                  pt: 4,
                  pb: 1
                }}
              >
                Pro Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: '48px',
                  fontWeight: 600,
                  fontFamily: 'Poppins'
                }}
              >
                ${value2 === 0 ? '5000' : '50,000'}
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#4B5563'
                  }}
                >
                  /{value2 === 0 ? 'month' : 'year'}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#4B5563',
                  width: '100%',
                  py: 2
                }}
              >
                Get essential features at an affordable price, the perfect
                combination.
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '60%'
                    }}
                  >
                    5000 accounts tracked
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    5 user licenses
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ borderRadius: '0.25rem', width: '80%' }}
                className='btn btn-sign'
                variant='contained'
                color='primary'
                onClick={() => handlePayment()}
              >
                Get Started &nbsp; &gt;
              </button>
            </Box>
          </CardContent>
        </StyledCard2>

        <StyledCard2 variant='outlined'>
          <CardContent
            sx={{
              pt: 5,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ pl: '25px' }}>
              <img src={frame3} style={{ height: '100px', width: '100px' }} />
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#0072DE',
                  fontFamily: 'Poppins',
                  pt: 4,
                  pb: 1
                }}
              >
                Enterprise Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: '48px',
                  fontWeight: 600,
                  fontFamily: 'Poppins'
                }}
              >
                ${value2 === 0 ? '10,000' : '1,00,000'}
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#4B5563'
                  }}
                >
                  /{value2 === 0 ? 'month' : 'year'}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#4B5563',
                  width: '100%',
                  py: 2
                }}
              >
                Get essential features at an affordable price, the perfect
                combination.
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0,
                  py: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    Unlimited accounts tracked
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    AI-Driven Insights, Advanced Predictive Analytics
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 1
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    Dedicated customer success manager
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 1
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    API access and advanced integrations
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 1
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    Unlimited user licenses
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  py: 1
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src={checkCircle}
                    alt=''
                    style={{
                      height: '20px',
                      width: '30px',
                      filter:
                        'invert(45%) sepia(25%) saturate(300%) hue-rotate(90deg)'
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: '#4B5563',
                      width: '100%'
                    }}
                  >
                    24/7 priority support
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* Button below */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ borderRadius: '0.25rem', width: '80%' }}
                className='btn btn-sign'
                variant='contained'
                color='primary'
                onClick={() => handlePayment()}
              >
                Get Started &nbsp; &gt;
              </button>
            </Box>
          </CardContent>
        </StyledCard2>
      </Grid>
      <Divider />
      <Box my={5}>
        <Grid container spacing={10} sx={{ justifyContent: 'center' }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography variant='h3' fontWeight={700}>
              Custom Lead Generation
            </Typography>
            <Typography fontSize={18} fontWeight={400} pt={2} pb={4}>
              Please fill the ICP form and review your campaign costs:
            </Typography>
            <Button
              variant='text'
              sx={{ color: '#55B56D', fontWeight: 700 }}
              onClick={() => handlePayment()}
            >
              Go to Campaign Setup <span>&nbsp;&nbsp;&nbsp;&gt;</span>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Pricing
