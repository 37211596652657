import React, { useState } from 'react'
import axios from 'axios'
import { Box, TextField, Button, Typography } from '@mui/material'

const ChangePassword = ({ funcNav, funcFooter }) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  let userData = sessionStorage.getItem('user')

  const handleChangePassword = async () => {
    try {
      const response = await axios.post('users/changePassword', {
        oldPassword: oldPassword,
        newPassword: newPassword,
        user: userData
      })

      if (response.data.success) {
        setSuccess('Password changed successfully!')
        setError('')
        setOldPassword('')
        setNewPassword('')
      }
    } catch (err) {
      setError('Failed to change password. Please try again.')
      setSuccess('')
    }
  }

  return (
    <>
      <Box
        className='container'
        sx={{
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography variant='h5' gutterBottom>
          Change Password
        </Typography>
        {error && (
          <Typography color='error' variant='body2'>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color='success' variant='body2'>
            {success}
          </Typography>
        )}
        <TextField
          label='Old Password'
          variant='outlined'
          type='password'
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
          sx={{ marginBottom: 2, width: '300px' }}
        />
        <TextField
          label='New Password'
          variant='outlined'
          type='password'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          sx={{ marginBottom: 2, width: '300px' }}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleChangePassword}
        >
          Change Password
        </Button>
      </Box>
    </>
  )
}

export default ChangePassword
