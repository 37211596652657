import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Billing = () => {
  const [billData, setBillData] = useState([])
  const user = useSelector(state => state.userReducer)

  const getBackgroundStatusColorClass = status => {
    switch (status) {
      case 'paid':
        return '#55b56d'
      case 'unpaid':
        return '#f61d1d'
      default:
        return
    }
  }

  const fetchData = async user => {
    try {
      const response = await axios.get(`/billing/${user._id}`)
      setBillData(response.data) // Store the data in the state
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching Intent Data:', error)
    }
  }

  useEffect(() => {
    if (user && user._id) {
      fetchData(user)
    }
  }, [user])

  return (
    <div
      style={{
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        marginBottom: '40px',
        marginTop: '30px',
        overflowX: 'auto' // Enable horizontal scrolling
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Billing Information</h2>
      {/* Check if billData has content */}
      {billData.length > 0 ? (
        <table
          style={{
            borderCollapse: 'collapse',
            borderRadius: '10px',
            overflow: 'hidden',
            width: '100%'
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Sr No.</th>
              <th style={{ textAlign: 'center' }}>Session ID</th>
              <th style={{ textAlign: 'center' }}>Amount Subtotal (USD)</th>
              <th style={{ textAlign: 'center' }}>Amount Total (USD)</th>
              <th style={{ textAlign: 'center' }}>Currency</th>
              <th style={{ textAlign: 'center' }}>Mode</th>
              <th style={{ textAlign: 'center' }}>Payment Method Type</th>
              <th style={{ textAlign: 'center' }}>Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {billData.map((bill, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                <td style={{ textAlign: 'center' }}>{bill.id}</td>
                <td style={{ textAlign: 'center' }}>{bill.amount_subtotal}</td>
                <td style={{ textAlign: 'center' }}>{bill.amount_total}</td>
                <td style={{ textAlign: 'center' }}>
                  {bill.currency.toUpperCase()}
                </td>
                <td style={{ textAlign: 'center' }}>{bill.mode}</td>
                <td style={{ textAlign: 'center' }}>
                  {bill.payment_method_types[0]}
                </td>
                <td
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <span
                    style={{
                      display: 'flex',
                      width: '75px',
                      background: getBackgroundStatusColorClass(
                        bill.payment_status
                      ),
                      padding: '8px 8px',
                      borderRadius: '8px',
                      fontSize: '12px',
                      textTransform: 'capitalize',
                      textAlign: 'center',
                      justifyContent: 'center',
                      color: 'white'
                    }}
                  >
                    {bill.payment_status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={{ textAlign: 'center' }}>No billing data available.</p>
      )}
    </div>
  )
}

export default Billing
