import React from 'react'

const LeadRejectionPolicy = ({ funcNav, funcFooter }) => {
  funcNav(true)
  funcFooter(true)
  return (
    <div>
      <section class='blog-banner'>
        <div class='container-fluid'></div>
        <div class='col-md-12'></div>
      </section>
      <section class='privacy-main'>
        <div class='container'>
          <h3>Lead Rejection Policy</h3>
          <p>
            In order to ensure fair treatment of both Merchants and publishers
            we have adopted a consistent Lead Rejection Policy for all activity.
          </p>
          <div class='row'>
            <div class='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xll-12 '>
              <div class='privacy-inner'>
                <div class='table-responsive'>
                  <table class='table table-bordere'>
                    <tbody>
                      <tr>
                        <th colspan='2'>Acceptable Rejection Criteria:</th>
                      </tr>
                      <tr>
                        <td>Invalid Contact Details:</td>
                        <td>
                          The Customer Lead contains contact details that do not
                          work, i.e. the telephone numbers supplied either do
                          not ring, do not allow voice conversation (e.g. fax
                          machine), have an automated number not in service.
                        </td>
                      </tr>
                      <tr>
                        <td>Duplicate Lead:</td>
                        <td>
                          a Customer completes the same form twice and Optimise
                          receives a duplicate Customer Lead regarding that
                          Customer, in which case the duplicate Customer Lead
                          shall not be valid
                        </td>
                      </tr>
                      <tr>
                        <td>Hoax Lead:</td>
                        <td>
                          The Customer Lead contains contact details that are
                          clearly bogus e.g. fictional names or random
                          characters
                        </td>
                      </tr>
                      <tr>
                        <td>Fraudulent Lead:</td>
                        <td>
                          A lead that Optimise determines was not generated in
                          good faith by a customer with a genuine interest in
                          the Product/Solutions/services
                        </td>
                      </tr>
                      <tr>
                        <td>Non-Territory Lead:</td>
                        <td>
                          A lead generated by an individual who is not based in
                          a Non assigned territory
                        </td>
                      </tr>
                      <tr>
                        <td>Form fills:</td>
                        <td>
                          Form-fill is auto-accepted, unless there is Red flag
                          raised with respect to: wrong no. / wrong email ID /
                          left organization
                        </td>
                      </tr>
                      <tr>
                        <td>Replacement as per standard rules like:</td>
                        <td>
                          Right prospect not provided, Job Title
                          /TAL mismatch, etc
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>
                  {' '}
                  The Company must notify that a lead is rejected and the reason
                  for the rejection within 3 working days of receipt of the
                  lead.{' '}
                </p>

                <div class='table-responsive'>
                  <table class='table table-bordere'>
                    <tbody>
                      <tr>
                        <th colspan='2'>
                          For the avoidance of doubt, the following (but not
                          limited to the following) will not be acceptable
                          reasons for the rejection of Leads:
                        </th>
                      </tr>
                      <tr>
                        <td>Purchased Elsewhere:</td>
                        <td>
                          Advertiser will not invalidate Customer Leads for
                          customers who have sourced their advice elsewhere
                          following submission of their enquiry. Advertiser will
                          follow up on a Customer Lead as quickly as possible to
                          ensure it remains as fresh as when it was delivered.
                        </td>
                      </tr>
                      <tr>
                        <td>Changed Mind:</td>
                        <td>
                          Advertiser will not invalidate Customer Leads for
                          customers who have changed their mind about receiving
                          advice, or whose circumstances have changed, following
                          submission of their enquiry.
                        </td>
                      </tr>
                      <tr>
                        <td>No Contact:</td>
                        <td>
                          Advertiser will not invalidate Customer Leads where
                          Advertiser has been unable to contact the customer via
                          any of the contact details in the Customer Lead,
                          except where the telephone numbers do not work.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>
                  In case of any interruption or Pause in Campaign , the Invoice
                  will be raised on total leads submitted before the Pause
                  Instruction Techplus will replace rejected lead only if the
                  same qualify the above criteria and is intimated within 3
                  working days from the report submission date and subsequent
                  final rejection on replaced leads must again qualify above
                  criteria and should intimated within 3 working days with
                  rejection reason.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LeadRejectionPolicy
