import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Define the form component
const LeadForm = ({ handleSubmit }) => {
    const [campaignData, setCampaignData] = useState({
        leadCategory: '',
        quantity: '',
        region: '',
        tal2: 'yes',
        talCheck: false,
        subtotal: 1000,
        tax: 0, // Tax amount in the selected currency
        totalAmount: 0
    });

    const [formattedTotal, setFormattedTotal] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const stripePromise = loadStripe('pk_test_51PcjSaKqIA5zCeSxVZnBhXEMk8XoOdXH97Y55JAHFrq2aJZLAxhlZ0gASJAZnMAbIpx7QPpWC6SwzDkPE09xd0kg00FTOdn9h8');

    useEffect(() => {
        const calculateTotalAmount = () => {
            let subtotalAmount = campaignData.quantity * campaignData.subtotal;
            let total = subtotalAmount;
            if (campaignData.talCheck && campaignData.tal2 === 'yes') {
                total *= 1.25; // Add 25% extra if TAL is checked
            }
            total += campaignData.tax; // Add tax to the total
            setCampaignData(prevState => ({ ...prevState, totalAmount: total }));
            return total;
        };

        setFormattedTotal(formatCurrency(calculateTotalAmount()));
    }, [campaignData.quantity, campaignData.subtotal, campaignData.talCheck, campaignData.tal2, campaignData.tax, campaignData.region]);

    const formatCurrency = (amount) => {
        const currencySymbol = campaignData.region === 'India' ? '₹ ' : '$ ';
        return `${currencySymbol}${(amount / 100).toFixed(2)}`;
    };

    const handlePayment = async (e) => {
        e.preventDefault();
        try {
            const stripe = await stripePromise;
            const { totalAmount, region } = campaignData;
            const currency = region === 'India' ? 'inr' : 'usd';
            const data = { amount: totalAmount, currency };

            // Send a request to your server to create a checkout session
            const response = await axios.post('/payment', data, {
                headers: {
                    Authorization: `Bearer sk_test_51PcjSaKqIA5zCeSxe4dQ1uUq6DNdjtA3pCLq6aqQoJ9EURfqrtyE7JGgga5RTiPvTax1xP3jUmln7peJQ1EI1fdq00svcITThB`
                }
            });

            const sessionId = response.data.id;

            // Redirect to Checkout
            const result = await stripe.redirectToCheckout({ sessionId });

            if (result.error) {
                console.error(result.error);
                alert('Payment failed: ' + result.error.message);
            }
        } catch (error) {
            console.error('Payment error:', error);
            alert('An error occurred during payment processing.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCampaignData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        setCampaignData(prevState => ({ ...prevState, talCheck: checked }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='row mb-4'>
                <div className='col-md-12'>
                    <h4>GET FREE LEADS</h4>
                </div>
                <div className='col-md-6 mb-3'>
                    <label htmlFor='leadCategory'>Lead Category:</label>
                    <select
                        id='leadCategory'
                        className='form-control'
                        name='leadCategory'
                        value={campaignData.leadCategory}
                        onChange={handleChange}
                    >
                        <option value=''>Select Lead Category</option>
                        <option value='Form Fills'>Form Fills</option>
                        <option value='BANT'>BANT</option>
                        <option value='Appointment Setting'>Appointment Setting</option>
                        <option value='Triangular call'>Triangular call</option>
                    </select>
                </div>
                <div className='col-md-6 mb-3'>
                    <label htmlFor='quantity'>Quantity:</label>
                    <select
                        id='quantity'
                        className='form-control'
                        name='quantity'
                        value={campaignData.quantity}
                        onChange={handleChange}
                    >
                        <option value=''>Select Quantity</option>
                        {[...Array(10).keys()].map(num => (
                            <option key={num + 1} value={num + 1}>{num + 1}</option>
                        ))}
                    </select>
                </div>
                <div className='col-md-6 mb-3'>
                    <label htmlFor='region'>Region:</label>
                    <select
                        id='region'
                        className='form-control'
                        name='region'
                        value={campaignData.region}
                        onChange={handleChange}
                    >
                        <option value=''>Select Region</option>
                        <option value='Singapore'>Singapore</option>
                        <option value='India'>India</option>
                        <option value='Malaysia/ Indonesia / Philippines/ Vietnam / Thailand'>
                            Malaysia/ Indonesia / Philippines/ Vietnam / Thailand
                        </option>
                        <option value='USA'>USA</option>
                        <option value='EMEA'>EMEA</option>
                        <option value='ANZ'>ANZ</option>
                        <option value='Japan'>Japan</option>
                        <option value='South Korea'>South Korea</option>
                    </select>
                </div>
                <div className='col-md-6 mb-3'>
                    <label htmlFor='tal2'>TAL:</label>
                    <select
                        id='tal2'
                        className='form-control'
                        name='tal2'
                        value={campaignData.tal2}
                        onChange={handleChange}
                    >
                        <option value='yes'>Yes</option>
                        <option value='no'>No</option>
                    </select>
                </div>
                <div className='col-md-6 mb-3'>
                    <input
                        type='checkbox'
                        id='talCheck'
                        name='talCheck'
                        checked={campaignData.talCheck}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor='talCheck'>
                        In case of Yes, the system should calculate 25% extra
                    </label>
                </div>
                <div className='col-md-6 p-3 d-flex justify-content-end'>
                    <div className='p-3' style={{ backgroundColor: '#F8F8F8', width: '40%', borderRadius: 15 }}>
                        <div className='d-flex justify-content-between mb-3'>
                            <span className='font-weight-bold text-secondary'>Subtotal:</span>
                            <span>{formatCurrency(campaignData.quantity * campaignData.subtotal)}</span>
                        </div>
                        <div className='d-flex justify-content-between mb-3'>
                            <span className='font-weight-bold text-secondary'>Tax:</span>
                            <span>{formatCurrency(campaignData.tax)}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <span className='font-weight-bold text-secondary'>Total Amount:</span>
                            <span className='font-weight-bold text-primary'>{formattedTotal}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-between'>
                <div style={{ flex: 1 }}></div>
                <button
                    type='button'
                    onClick={handlePayment}
                    className='btn btn-success'
                >
                    Pay Now
                </button>
            </div>
        </form>
    );
};

export default LeadForm;
